import React from 'react'

type Props = {
    user_guid: string | null
    pos_user_guid: string
    role: string
    stage: string
    status: string
    approve: boolean
    actions: Actions
    isSendPlan: boolean
    isAnnulate: boolean
    // status: string
    // multiSelect: boolean
}

type Actions = {
    save: () => void
    copy: () => void
    copy_draft: () => void
    delete: () => void
    close_save: () => void
    close: () => void
    close_approve: () => void
    change: () => void
    annulate: () => void
}

type ButtonConfig = {
    id: string
    label: string
    onClick: () => void
    visibleCondition: boolean // Функция, проверяющая видимость
    className?: string // Стили для кнопки
    iconPathD: string
    iconFill: string
    disabled: boolean
    isLoading: boolean
}

const TableFooterRightToolbarButtons: React.FC<Props> = ({
    user_guid,
    pos_user_guid,
    role,
    stage,
    status,
    approve,
    actions,
    isSendPlan,
    isAnnulate,
    // status,
    // multiSelect,
}) => {
    const isCreator = user_guid === pos_user_guid
    const isAdmin = role === 'admin'
    const isSpecialist = role === 'specialist'
    const isCurator = role === 'curator'
    const isInitiator = role === 'initiator'

    const isEditStage = stage === 'Редактирование'
    const isRaedyStage = stage === 'Подготовка к размещению'
    const isSentToEisStage = stage === 'Отправка в ЕИС'
    const isPurchasePlanStage = stage === 'План закупки в ЕИС'

    const isEditStatus = status === 'Черновик'
    const isReadyStatus = status === 'Готово'
    const isStandStatus = status === 'Подготовка'
    const isReadyToEisStatus = status === 'Готово к отправке'
    const isSentToEisStatus = status === 'Отправлено в ЕИС'
    const isPublishedStatus = status === 'Размещена'

    const buttons: ButtonConfig[] = [
        {
            id: 'save',
            label: 'Сохранить',
            onClick: actions.save,
            visibleCondition:
                !approve &&
                ((isEditStage &&
                    isEditStatus &&
                    (isCreator || isSpecialist || isAdmin)) ||
                    (isRaedyStage &&
                        isStandStatus &&
                        (isSpecialist || isAdmin))),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'copy',
            label: 'Копировать',
            onClick: actions.copy,
            visibleCondition: !approve && isPurchasePlanStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'copy_draft',
            label: 'Копировать',
            onClick: actions.copy_draft,
            visibleCondition: !approve && !isPurchasePlanStage,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'delete',
            label: 'Удалить',
            onClick: actions.delete,
            visibleCondition:
                !approve &&
                isEditStage &&
                isEditStatus &&
                (isCreator || isSpecialist || isAdmin),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#701F1F] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'change',
            label: 'Изменить',
            onClick: actions.change,
            visibleCondition:
                !approve &&
                isPurchasePlanStage &&
                isPublishedStatus &&
                !isAnnulate &&
                (isCreator || isCurator || isSpecialist || isAdmin),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'annulate',
            label: 'Аннулировать',
            onClick: actions.annulate,
            visibleCondition:
                !approve &&
                isPurchasePlanStage &&
                isPublishedStatus &&
                !isAnnulate &&
                (isSpecialist || isAdmin),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#701F1F] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'close_save',
            label: 'Закрыть',
            onClick: actions.close_save,
            visibleCondition: false,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'close',
            label: 'Закрыть',
            onClick: actions.close,
            visibleCondition: !approve,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'close_approve',
            label: 'Закрыть',
            onClick: actions.close_approve,
            visibleCondition: approve,
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z',
            disabled: false,
            isLoading: false,
        },
    ]

    return (
        <>
            {buttons
                .filter((button) => button.visibleCondition) // Оставляем только видимые кнопки
                .map((button, index) => (
                    <button
                        key={index}
                        className={button.className}
                        onClick={button.onClick}
                        title={button.label}
                        disabled={button.disabled}
                    >
                        {button.isLoading ? (
                            <svg
                                className="animate-spin h-4 w-4 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        ) : (
                            ''
                            // <svg
                            //     className="w-3 h-3"
                            //     xmlns="http://www.w3.org/2000/svg"
                            //     viewBox="0 0 22 22"
                            //     id={button.id}
                            // >
                            //     <path
                            //         fill={button.iconFill}
                            //         d={button.iconPathD}
                            //     ></path>
                            // </svg>
                        )}
                        {button.label}
                    </button>
                ))}
        </>
    )
}

export default TableFooterRightToolbarButtons
