import React, { useState, useEffect } from 'react'
import { PlanPosition } from '../../types'

interface PositionsStageCountProps {
    planPosition: PlanPosition
}

const STAGE = [
    'Редактирование',
    'Подготовка к размещению',
    'Отправка в ЕИС',
    'План закупки в ЕИС',
]

const STAGE_COLOR = new Map([
    ['Редактирование', '#3C69FE'],
    ['Подготовка к размещению', '#563AF5'],
    ['Отправка в ЕИС', '#3C18BC'],
    ['План закупки в ЕИС', '#272593'],
])

const STATUS_COLOR = new Map([
    ['Черновик', '#4E9AFF'],
    ['Готово', '#049664'],
    ['Подготовка', '#3C69FE'],
    ['Готово к отправке', '#563AF5'],
    ['Отправлено в ЕИС', '#049664'],
    ['Размещена', '#3C18BC'],
    ['Аннулирована', '#333033'],
])

const PositionsStage: React.FC<PositionsStageCountProps> = ({
    planPosition,
}) => {
    let complete_stage = true
    return (
        <>
            <div className="relative flex flex-row w-[1710px] h-full z-0 bg-[#000000]">
                <svg className="flex absolute w-full h-full">
                    <line
                        x1="214"
                        y1="45"
                        x2="1503"
                        y2="45"
                        stroke="#464447"
                        strokeWidth="1"
                        strokeDasharray="5 5"
                    />
                </svg>
                <div className="flex w-full h-full z-20 absolute">
                    {STAGE.map((stage, index) => (
                        <div
                            key={index}
                            className="flex flex-col flex-1 justify-center items-center"
                        >
                            <div
                                className={`flex w-full h-full justify-center items-end ${complete_stage ? (stage == planPosition.position_stage ? 'text-[12.8px] text-white ' : 'text-[10px] text-white ') : ' text-[10px] text-[#8A8A8A]'}`}
                            >
                                {stage}
                            </div>
                            <div className="flex w-full h-full justify-center items-center">
                                <svg width="24" height="24">
                                    {complete_stage ? (
                                        <>
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="6"
                                                fill={`${STAGE_COLOR.get(String(stage))}`}
                                            />
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="8"
                                                fill="none"
                                                stroke={`${STAGE_COLOR.get(String(stage))}55`}
                                                strokeWidth="5"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="6"
                                                fill="#464447"
                                            />
                                        </>
                                    )}
                                </svg>
                            </div>
                            <div
                                className={`flex w-full h-full justify-center items-start ${complete_stage ? 'text-[12.8px] text-white ' : ' text-[10px] text-[#8A8A8A]'}`}
                            >
                                {stage == planPosition.position_stage
                                    ? planPosition.position_status
                                    : ''}
                            </div>
                            {stage == planPosition.position_stage
                                ? (complete_stage = false)
                                : ''}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default PositionsStage
