import React, { useState } from 'react'
import { useNavigate, useLoaderData } from 'react-router-dom'
import { ListEp } from '../types'

export const loadListEp = async (): Promise<ListEp> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/list_single_supplier_purchase',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getCurrentDate = () => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // Добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

const OrgListEpPage: React.FC = () => {
    const navigate = useNavigate()
    const listEpLoad = useLoaderData() as ListEp[]
    const [listEpData, setListEpData] = useState<ListEp[]>(listEpLoad)
    const [listEpUpdate, setListEpUpdate] = useState<ListEp | null>(null)

    const [selectedListEpGuid, setSelectedListEpGuid] = useState('')

    const [popupAddListEp, setPopupAddListEp] = useState(false)
    const [popupDeleteListEp, setPopupDeleteListEp] = useState(false)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [isFiltering, setIsFiltering] = useState(false)
    const [filters, setFilters] = useState({
        purchaseParagraph: '',
        purchaseName: '',
        purchaseApproveDate: '',
    })

    const handlerFilter = () => {
        setIsFiltering(!isFiltering)

        setFilters({
            purchaseParagraph: '',
            purchaseName: '',
            purchaseApproveDate: '',
        })
    }

    const handlerFilterClear = () => {
        setFilters({
            purchaseParagraph: '',
            purchaseName: '',
            purchaseApproveDate: '',
        })
    }

    // Функция фильтрации данных
    const getFilteredData = () => {
        return listEpData.filter((list) => {
            const paragraphMatches = list.single_supplier_purchase_paragraph
                .toLowerCase()
                .includes(filters.purchaseParagraph.toLowerCase())
            const nameMatches = list.single_supplier_purchase_name
                .toLowerCase()
                .includes(filters.purchaseName.toLowerCase())
            const dateMatches = filters.purchaseApproveDate
                ? list.approve_date === filters.purchaseApproveDate
                : true
            return paragraphMatches && nameMatches && dateMatches
        })
    }

    const handleInputChange = (
        fieldName: keyof ListEp,
        value: string | null
    ) => {
        value ? value : ''
        setListEpUpdate(
            (prev) =>
                ({
                    ...prev,
                    [fieldName]: value,
                }) as ListEp
        )
    }

    const handlePopupAddListEp = (list_guid: string) => {
        setPopupAddListEp(!popupAddListEp)
        if (list_guid != '') {
            setSelectedListEpGuid(list_guid)
            const selectList = listEpData.find((l) => l.guid === list_guid)
            setListEpUpdate(selectList || null)
        } else {
            setSelectedListEpGuid('')
            setListEpUpdate(null)
            setListEpUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['approve_date']: getCurrentDate(),
                    }) as unknown as ListEp
            )
            setListEpUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['single_supplier_purchase_paragraph']: '',
                    }) as unknown as ListEp
            )
            setListEpUpdate(
                (prev) =>
                    ({
                        ...prev,
                        ['single_supplier_purchase_name']: '',
                    }) as unknown as ListEp
            )
        }
    }

    const handleAddListEp = async () => {
        setIsAdding(true) // Включаем анимацию загрузки
        if (selectedListEpGuid !== '') {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/list_single_supplier_purchase/${selectedListEpGuid}`,
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(listEpUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при изменении пункта перечня')
                }

                console.log('Пункт перечня успешно изменен')
                setPopupAddListEp(false)
            } catch (error) {
                console.error('Ошибка при изменении пункта перечня:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        } else {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/list_single_supplier_purchase`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(listEpUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при добавлении пункта перечня')
                }

                console.log('Пункт перечня успешно добавлен')
                setPopupAddListEp(false)
            } catch (error) {
                console.error('Ошибка при добавлении пункта перечня:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        }
        const data = (await loadListEp()) as unknown as ListEp[]
        setListEpData(data)
    }

    const handlePopupDeleteListEp = (list_guid: string) => {
        setPopupDeleteListEp(!popupDeleteListEp)
        setSelectedListEpGuid(list_guid)
    }

    const handleDeleteListEp = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки

        if (!selectedListEpGuid) return

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/list_single_supplier_purchase/${selectedListEpGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении пункта перечня')
            }
            setPopupDeleteListEp(false)
            setListEpData((prevStruct) =>
                prevStruct.filter((list) => list.guid !== selectedListEpGuid)
            )
            setSelectedListEpGuid('')
            console.log('Пункт перечня успешно удален')
        } catch (error) {
            console.error('Ошибка удаления пункта перечня:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    return (
        <div className="flex flex-col text-white min-h-screen">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-full h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Основания закупки у единственного поставщика
                            (исполнителя, подрядчика)
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center h-[calc(100vh_-_120px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_160px)]">
                        {/* table */}
                        <div className="flex flex-col h-full w-full">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg items-center h-10">
                                {/* Add */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={() => handlePopupAddListEp('')}
                                    title="Добавить пункт"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>
                                </button>

                                {/* Filter */}
                                <button
                                    className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                    onClick={handlerFilter}
                                    title={
                                        isFiltering
                                            ? 'Убрать фильтр'
                                            : 'Показать фильтр'
                                    }
                                >
                                    {isFiltering ? (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            data-name="Layer 1"
                                            viewBox="0 0 24 24"
                                            id="filter-slash"
                                        >
                                            <path
                                                id="svg_1"
                                                d="m19,2l-14,0a3,3 0 0 0 -3,3l0,1.17a3,3 0 0 0 0.25,1.2l0,0.06a2.81,2.81 0 0 0 0.59,0.86l6.16,6.12l0,6.59a1,1 0 0 0 0.47,0.85a1,1 0 0 0 0.53,0.15a1,1 0 0 0 0.45,-0.11l4,-2a1,1 0 0 0 0.55,-0.89l0,-4.59l6.12,-6.12a2.81,2.81 0 0 0 0.59,-0.86l0,-0.06a3,3 0 0 0 0.29,-1.2l0,-1.17a3,3 0 0 0 -3,-3zm-5.71,11.29a1,1 0 0 0 -0.29,0.71l0,4.38l-2,1l0,-5.38a1,1 0 0 0 -0.29,-0.71l-5.3,-5.29l13.18,0l-5.3,5.29zm6.71,-7.29l-16,0l0,-1a1,1 0 0 1 1,-1l14,0a1,1 0 0 1 1,1l0,1z"
                                                fill="#FFFFFF"
                                            />
                                            <path
                                                id="svg_2"
                                                d="m10.35653,-2.20952c0,-0.54338 0.45662,-1 1,-1l0.14286,0c0.54338,0 1,0.45662 1,1l0,25.07412c0,0.54338 -0.45662,1 -1,1l-0.14286,0c-0.54338,0 -1,-0.45662 -1,-1l0,-25.07412z"
                                                transform="rotate(-43.639, 11.428, 10.3275)"
                                                opacity="undefined"
                                                fill="#ff0000"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="filter"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                            ></path>
                                        </svg>
                                    )}
                                </button>
                                <div className="flex flex-1 text-[10px] text-white place-items-end text-center "></div>
                                <div className="flex flex-col w-48 text-[10px] text-[#8A8A8A] place-items-center text-center">
                                    <span>Позиций</span>
                                    <span>{`${listEpData.length}`}</span>
                                </div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#262427] text-[#8A8A8A] text-[10px] px-1 py-2 h-10">
                                <div className="flex w-10 justify-center items-center text-center">
                                    №
                                </div>
                                <div className="flex flex-col w-36 justify-center items-center text-center">
                                    Пункт положения о закупке
                                </div>
                                <div className="flex flex-1 justify-center items-center text-center">
                                    Наименование основания закупки у
                                    единственного поставщика (исполнителя,
                                    подрядчика)
                                </div>
                                <div className="flex w-28 items-center justify-center px-2">
                                    Дата изменения
                                </div>
                                <div className="flex w-10 justify-center items-center text-center"></div>
                            </div>

                            {/* filter */}
                            {isFiltering ? (
                                <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] px-1">
                                    <div className="flex w-10 items-center justify-center text-center"></div>
                                    <div className="flex flex-col w-28 justify-center items-center text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.purchaseParagraph}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    purchaseParagraph:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-1 justify-center items-center text-center">
                                        <input
                                            className="struct-input-placeholder w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                            placeholder="Поиск"
                                            value={filters.purchaseName}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    purchaseName:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-col w-28 justify-center items-center text-center">
                                        <input
                                            type="date"
                                            className={`w-full px-2 py-1 rounded-md border-0 text-[11px] bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${filters.purchaseApproveDate ? 'text-white' : 'text-[#8A8A8A]'}`}
                                            placeholder="дд.мм.гггг"
                                            value={filters.purchaseApproveDate}
                                            onChange={(e) =>
                                                setFilters({
                                                    ...filters,
                                                    purchaseApproveDate:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="flex w-10 h-8 items-center justify-center text-center">
                                        <button
                                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                            onClick={handlerFilterClear}
                                            title="Очистить фильтр"
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                data-name="Layer 1"
                                                viewBox="0 0 24 24"
                                                id="x"
                                            >
                                                <path
                                                    d="m13.26379,18.9678l7.4302,-7.4302c0.57155,-0.57155 0.57155,-1.14311 0,-1.71466c-2.28622,-2.28622 -3.7151,-3.7151 -6.00132,-6.00132c-0.57155,-0.57155 -1.14311,-0.57155 -1.71466,0c-3.7151,3.7151 -6.85865,6.85865 -10.85953,10.85953c-0.57155,0.57155 -0.28578,1.14311 0,1.42889c1.14311,1.14311 2.85777,3.14355 4.00088,4.00088c0.57155,0.28578 0.57155,0.57155 1.14311,0.57155l14.00307,0c0.57155,0 0.85733,-0.28578 0.85733,-0.85733c0,-0.57155 -0.28578,-0.85733 -0.85733,-0.85733l-8.00176,0zm-3.42932,0c-0.57155,0 -1.42889,0 -2.00044,0c-0.85733,0 -1.14311,-0.28578 -1.42889,-0.85733c-0.85733,-0.85733 -1.14311,-1.14311 -2.00044,-2.00044c-0.28578,-0.28578 -0.28578,-0.85733 0.28578,-1.42889c1.14311,-1.14311 1.71466,-1.71466 3.14355,-3.14355c0.57155,-0.57155 0.85733,-0.57155 1.42889,-0.28578c1.42889,1.42889 2.28622,2.57199 4.00088,4.00088c0.57155,0.57155 0.57155,1.14311 0,1.71466c-0.57155,0.57155 -1.14311,1.14311 -1.71466,1.71466c-0.28578,0.28578 -0.57155,0.57155 -1.14311,0.57155l-0.57155,-0.28578z"
                                                    fill="#ffffff"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 h-full bg-[#1D1C1E]">
                                {/* Вывод ТРУ */}
                                {getFilteredData().map((list, index) => (
                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        key={index}
                                    >
                                        <div
                                            className="flex flex-row w-full gap-1 py-2"
                                            onClick={() =>
                                                handlePopupAddListEp(list.guid)
                                            }
                                        >
                                            <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                                {index + 1}
                                            </div>
                                            <div className="flex w-36 items-center justify-center px-2">
                                                {list.single_supplier_purchase_paragraph
                                                    ? list.single_supplier_purchase_paragraph
                                                    : '-'}
                                            </div>
                                            <div className="flex flex-1 items-center justify-left text-left px-2">
                                                {list.single_supplier_purchase_name
                                                    ? list.single_supplier_purchase_name
                                                    : '-'}
                                            </div>
                                            <div className="flex w-28 items-center justify-center px-2 text-[#8A8A8A]">
                                                {list.approve_date
                                                    ?.slice(0, 10)
                                                    .split('-')
                                                    .reverse()
                                                    .join('-')
                                                    .replace(/[\-]/g, '.')}
                                            </div>
                                        </div>
                                        <div className="flex w-10 items-center justify-center text-center">
                                            <button
                                                className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                onClick={() =>
                                                    handlePopupDeleteListEp(
                                                        list.guid
                                                    )
                                                }
                                                title="Удалить"
                                            >
                                                <svg
                                                    className="w-3 h-3"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 24 24"
                                                    id="trash-alt"
                                                >
                                                    <path
                                                        fill="#FFFFFF"
                                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                    ></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row text-white p-2 bg-[#333033] rounded-b-lg h-10">
                                <button
                                    className="flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#494849] hover:bg-[#656365]"
                                    onClick={() => handlePopupAddListEp('')}
                                    title="Добавить пункт"
                                >
                                    <svg
                                        className="plus-icon h-4 w-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFF"
                                            d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                        ></path>
                                    </svg>{' '}
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] justify-end p-2 space-x-5 items-center h-10">
                <div className="flex items-center space-x-5">
                    <button
                        className="flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#494849] hover:bg-[#656365]"
                        onClick={() => navigate('/hub/org/lists')}
                    >
                        Закрыть
                    </button>
                </div>
            </div>

            {popupAddListEp && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handlePopupAddListEp('')}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2>
                            {selectedListEpGuid ? 'Изменение' : 'Добавление'}{' '}
                            основания
                        </h2>
                        <div className="flex flex-row items-center justify-start gap-5">
                            <div className="flex flex-col mt-2 space-y-1">
                                <label className="text-[12.8px] text-[#8A8A8A]">
                                    Пункт положения о закупке
                                </label>
                                <input
                                    type="text"
                                    className="w-[200px] h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                    value={
                                        listEpUpdate?.single_supplier_purchase_paragraph ??
                                        ''
                                    }
                                    onChange={(e) =>
                                        handleInputChange(
                                            'single_supplier_purchase_paragraph',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                            <div className="flex flex-col mt-2 space-y-1">
                                <label className="text-[12.8px] text-[#8A8A8A]">
                                    Дата изменения
                                </label>
                                <input
                                    type="date"
                                    className={`w-[200px] px-2 py-1 rounded-md border-0 text-sm bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${listEpUpdate?.approve_date ? 'text-white' : 'text-[#8A8A8A]'}`}
                                    placeholder="дд.мм.гггг"
                                    value={listEpUpdate?.approve_date ?? ''}
                                    style={{
                                        colorScheme: 'dark',
                                    }}
                                    onChange={(e) =>
                                        handleInputChange(
                                            'approve_date',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Наименование основания закупки у единственного
                                поставщика (исполнителя, подрядчика)
                            </label>
                            <textarea
                                className="w-full h-44 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                value={
                                    listEpUpdate?.single_supplier_purchase_name ??
                                    ''
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        'single_supplier_purchase_name',
                                        e.target.value
                                    )
                                }
                            />
                        </div>
                        <div className="flex flex-row items-center justify-end mt-5 text-right space-x-2">
                            <button
                                className="flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#494849] hover:bg-[#656365]"
                                onClick={() => handlePopupAddListEp('')}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#187A33] hover:bg-[#1D9A40]`}
                                onClick={handleAddListEp}
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : selectedListEpGuid ? (
                                    'Изменить'
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {popupDeleteListEp && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div
                        className="p-5 rounded-lg bg-[#262427] min-w-[300px] max-w-[600px] max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#C15C5C"
                                    d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Удаление пункта положения о закупке
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[14px] text-white">
                                Вы уверены, что хотите удалить данную позицию?
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className={`flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#701F1F] hover:bg-[#9E2C2C]`}
                                onClick={handleDeleteListEp}
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                            <button
                                className="flex flex-row min-w-[120px] h-6 text-white text-xs rounded-[10px] justify-center items-center gap-1 bg-[#494849] hover:bg-[#656365]"
                                onClick={() => handlePopupDeleteListEp('')}
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgListEpPage
