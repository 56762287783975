import React from 'react'

type Props = {
    value: string
    onChange: (value: number) => void
}

const CurrencySelector: React.FC<Props> = ({ value, onChange }) => {
    return (
        <>
            <span className="form-label">Валюта договора</span>
            <select
                className="form-select h-full focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                onChange={(e) => onChange(Number(e.target.value))}
                value={value}
            >
                <option value="643">Российский рубль</option>
                <option value="840">Доллар США</option>
                <option value="978">Евро</option>
                <option value="156">Китайский юань</option>
            </select>
        </>
    )
}

export default CurrencySelector
