import React, { useState, useRef, useEffect } from 'react'

import { useNavigate, Link } from 'react-router-dom'

import logo from '../../../../images/logo.png'

import MenuTextItem from './components/MenuTextItem'
import MenuIconItem from './components/MenuIconItem'
import SubMenuItem from './components/SubMenuItem'

import { TextLink } from './types'
import './NavBar.css'
import { useAuth } from '../../../../hooks/auth'
import { getUserData } from '../../../../services/auth'

type User = {
    token: string
    object: any
    u_role: string | null
}

const MENU_KEY_ORG = 'org'
const MENU_KEY_USER = 'user'
const MENU_KEY_REFERENCE = 'ref'

const SUB_MENU_ITEMS = new Map<string, TextLink[]>([
    [
        MENU_KEY_ORG,
        [
            { link: 'org/home', text: 'Организация', access: 'all' },
            { link: 'org/users', text: 'Пользователи', access: 'no_all' },
            { link: 'org/structure', text: 'Структура', access: 'no_all' },
            { link: 'org/roles', text: 'Роли', access: 'no_all' },
            { link: 'org/methods', text: 'Способы закупки', access: 'no_all' },
            { link: 'org/lists', text: 'Перечни', access: 'no_all' },
        ],
    ],
    [
        MENU_KEY_USER,
        [
            { link: 'home', text: 'Главная страница', access: 'all' },
            { link: 'plans', text: 'План закупки', access: 'all' },
            { link: 'approvals/plans', text: 'Согласование', access: 'all' },
        ],
    ],
    [
        MENU_KEY_REFERENCE,
        [
            { link: 'org/handbook/okpd2', text: 'ОКПД2', access: 'all' },
            { link: 'org/handbook/okved2', text: 'ОКВЭД2', access: 'all' },
        ],
    ],
])

const NavBar: React.FC = () => {
    const initialState = window.location.hash.startsWith('#/hub/org')
        ? MENU_KEY_ORG
        : MENU_KEY_USER
    const [currentMenuItem, setCurrentMenuItem] = useState(initialState)

    const { user, logout } = useAuth()
    const [roleName, setRoleName] = useState<string | null>(null)

    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()

    const handleLogout = () => {
        fetch(process.env.REACT_APP_API_URL + '/api/auth/v1/users/logout/', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((r) => {
            if (r.ok) {
                logout()
            }
        })
    }

    useEffect(() => {
        const handleUserData = async () => {
            const userData = await getUserData()
            if (userData) {
                setRoleName(userData.u_role) // Сохраняем u_role в state
            }
        }
        handleUserData()
    }, [])

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        } else {
            document.removeEventListener('mousedown', handleClickOutside)
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    return (
        <>
            <div className="h-full flex flex-col p-1 pb-2.5 items-center bg-[#181818]">
                <img src={logo} alt="Логотип" className="w-10 h-10 mb-4" />
                <ul className="flex flex-col gap-y-1 grow items-center">
                    <MenuIconItem
                        isActive={currentMenuItem === MENU_KEY_ORG}
                        onClick={() => {
                            setCurrentMenuItem(MENU_KEY_ORG)
                        }}
                        icon={`M20,8h0L14,2.74a3,3,0,0,0-4,0L4,8a3,3,0,0,0-1,2.26V19a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10.25A3,3,0,0,0,20,8ZM14,20H10V15a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1Zm5-1a1,1,0,0,1-1,1H16V15a3,3,0,0,0-3-3H11a3,3,0,0,0-3,3v5H6a1,1,0,0,1-1-1V10.25a1,1,0,0,1,.34-.75l6-5.25a1,1,0,0,1,1.32,0l6,5.25a1,1,0,0,1,.34.75Z`}
                    />
                    <li className="divider" />
                    <MenuTextItem
                        text="223"
                        isActive={currentMenuItem === MENU_KEY_USER}
                        onClick={() => {
                            setCurrentMenuItem(MENU_KEY_USER)
                        }}
                    />
                    <li className="divider" />
                    <MenuIconItem
                        isActive={currentMenuItem === MENU_KEY_REFERENCE}
                        onClick={() => {
                            setCurrentMenuItem(MENU_KEY_REFERENCE)
                        }}
                        icon={`M21.17,2.06A13.1,13.1,0,0,0,19,1.87a12.94,12.94,0,0,0-7,2.05,12.94,12.94,0,0,0-7-2,13.1,13.1,0,0,0-2.17.19,1,1,0,0,0-.83,1v12a1,1,0,0,0,1.17,1,10.9,10.9,0,0,1,8.25,1.91l.12.07.11,0a.91.91,0,0,0,.7,0l.11,0,.12-.07A10.9,10.9,0,0,1,20.83,16a1,1,0,0,0,1.17-1v-12A1,1,0,0,0,21.17,2.06ZM11,15.35a12.87,12.87,0,0,0-6-1.48c-.33,0-.66,0-1,0v-10a8.69,8.69,0,0,1,1,0,10.86,10.86,0,0,1,6,1.8Zm9-1.44c-.34,0-.67,0-1,0a12.87,12.87,0,0,0-6,1.48V5.67a10.86,10.86,0,0,1,6-1.8,8.69,8.69,0,0,1,1,0Zm1.17,4.15A13.1,13.1,0,0,0,19,17.87a12.94,12.94,0,0,0-7,2.05,12.94,12.94,0,0,0-7-2.05,13.1,13.1,0,0,0-2.17.19A1,1,0,0,0,2,19.21,1,1,0,0,0,3.17,20a10.9,10.9,0,0,1,8.25,1.91,1,1,0,0,0,1.16,0A10.9,10.9,0,0,1,20.83,20,1,1,0,0,0,22,19.21,1,1,0,0,0,21.17,18.06Z`}
                    />
                </ul>
            </div>
            <div className="h-full flex flex-col min-w-36 p-2 bg-[#262427]">
                <div className="mb-5 text-white uppercase leading-none">
                    <span className="text-white uppercase leading-none">
                        ТЕХНОЛОГИИ
                        <br />
                        ЗАКУПОК
                    </span>
                </div>
                <ul className="flex flex-col grap-y-1 grow">
                    {(SUB_MENU_ITEMS.get(currentMenuItem) as TextLink[]).map(
                        (item, index) =>
                            item.access == 'all' ? (
                                <SubMenuItem
                                    key={index}
                                    link={item.link}
                                    text={item.text}
                                    access={item.access}
                                />
                            ) : roleName == 'admin' ||
                              roleName == 'specialist' ? (
                                <SubMenuItem
                                    key={index}
                                    link={item.link}
                                    text={item.text}
                                    access={item.access}
                                />
                            ) : (
                                ''
                            )
                    )}
                </ul>
                <div
                    className="relative inline-block text-left"
                    ref={dropdownRef}
                >
                    <div
                        className="flex flex-row h-10 items-center justify-start p-2 rounded text-xs text-[#8A8A8A] hover:bg-[#464447] hover:text-white cursor-pointer gap-1"
                        onClick={toggleDropdown}
                    >
                        <div className="grow items-center justify-center text-xs">
                            {user?.object.login.split('@')[0]}
                        </div>{' '}
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="setting"
                        >
                            <path
                                fill="#8A8A8A"
                                d="M19.9 12.66a1 1 0 0 1 0-1.32l1.28-1.44a1 1 0 0 0 .12-1.17l-2-3.46a1 1 0 0 0-1.07-.48l-1.88.38a1 1 0 0 1-1.15-.66l-.61-1.83a1 1 0 0 0-.95-.68h-4a1 1 0 0 0-1 .68l-.56 1.83a1 1 0 0 1-1.15.66L5 4.79a1 1 0 0 0-1 .48L2 8.73a1 1 0 0 0 .1 1.17l1.27 1.44a1 1 0 0 1 0 1.32L2.1 14.1a1 1 0 0 0-.1 1.17l2 3.46a1 1 0 0 0 1.07.48l1.88-.38a1 1 0 0 1 1.15.66l.61 1.83a1 1 0 0 0 1 .68h4a1 1 0 0 0 .95-.68l.61-1.83a1 1 0 0 1 1.15-.66l1.88.38a1 1 0 0 0 1.07-.48l2-3.46a1 1 0 0 0-.12-1.17ZM18.41 14l.8.9-1.28 2.22-1.18-.24a3 3 0 0 0-3.45 2L12.92 20h-2.56L10 18.86a3 3 0 0 0-3.45-2l-1.18.24-1.3-2.21.8-.9a3 3 0 0 0 0-4l-.8-.9 1.28-2.2 1.18.24a3 3 0 0 0 3.45-2L10.36 4h2.56l.38 1.14a3 3 0 0 0 3.45 2l1.18-.24 1.28 2.22-.8.9a3 3 0 0 0 0 3.98Zm-6.77-6a4 4 0 1 0 4 4 4 4 0 0 0-4-4Zm0 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z"
                            ></path>
                        </svg>
                    </div>
                    {isOpen && (
                        <div
                            className="absolute flex flex-col bg-[#2A282B] w-[150px] shadow-lg rounded-lg p-1 z-50 text-[12.8px] items-center justify-start gap-1"
                            style={{ bottom: '100%' }}
                        >
                            <div className="flex w-full hover:bg-[#464447] text-[#8A8A8A] hover:text-white items-center rounded">
                                <button
                                    className="flex flex-1 flex-row py-3 pl-1 w-full text-xs text-left  h-full gap-2"
                                    onClick={() => {
                                        navigate(
                                            `/hub/org/users/${user?.object.user_id}`
                                        )
                                        setIsOpen(!isOpen)
                                    }}
                                >
                                    <svg
                                        className="w-4 h-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        data-name="Layer 1"
                                        viewBox="0 0 24 24"
                                        id="user-circle"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
                                        ></path>
                                    </svg>
                                    Учетная запись
                                </button>
                            </div>
                            <div className="flex w-full hover:bg-[#464447] text-[#8A8A8A] hover:text-white  items-center rounded">
                                <button
                                    className="flex flex-1 flex-row py-3 pl-1 w-full text-xs text-left h-full gap-2"
                                    onClick={handleLogout}
                                >
                                    <svg
                                        className="w-4 h-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        data-name="Layer 1"
                                        viewBox="0 0 24 24"
                                        id="user-circle"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"
                                        ></path>
                                    </svg>
                                    Выход
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {/* <Link
                    to={`/hub/org/users/${user?.object.user_id}`}
                    title={user?.object.login.split('@')[0]}
                    className="flex flex-row h-10 items-center p-2.5 rounded text-xs text-zinc-500 hover:bg-[#464447] hover:text-white"
                >
                    <div className="grow">
                        {user?.object.login.split('@')[0]}
                    </div>{' '}
                    <Cog6ToothIcon className="h-5 w-5" />
                </Link> */}
            </div>
        </>
    )
}

export default NavBar

// Tailwind CSS custom animation class
// Add the following to your Tailwind CSS configuration or <style> tag in the index.css file:
/* 
@keyframes fade-slide-down {
  0% {
    transform: translateY(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.animate-fade-slide-down {
  animation: fade-slide-down 0.3s ease-out;
}
*/
