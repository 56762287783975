import React from 'react'
import Select from 'react-select'

type Props = {
    options: Array<{ value: string; label: string }>
    value?: { value: string; label: string }
    onChange: (value: string) => void
    placeholder: string
    noOptionsMessage: string
    isOptionDisabled?: (value: string) => boolean
}

const MultilineSelect: React.FC<Props> = (props) => {
    return (
        <Select
            className="w-[1220px]"
            classNames={{
                control: () => 'text-sm',
                menu: () => 'text-sm',
                indicatorSeparator: () => 'hidden',
            }}
            styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: '#181818',
                    '&:hover': {
                        backgroundColor: '#181818',
                    },
                    borderColor: '#181818',
                    borderRadius: '5px', // закругление для основного селекта
                    minHeight: 0,
                }),
                valueContainer: (baseStyles) => ({
                    ...baseStyles,
                    padding: '0 0.5rem',
                }),
                indicatorsContainer: (baseStyles) => ({
                    ...baseStyles,
                    '> div': {
                        padding: '0',
                    },
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff',
                }),
                singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff',
                }),
                menu: (baseStyles) => ({
                    ...baseStyles,
                    color: '#fff',
                    backgroundColor: '#181818',
                    borderRadius: '5px',
                    marginTop: '4px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    maxWidth: '100%',
                    boxSizing: 'border-box',
                }),
                option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isDisabled
                        ? '#181818'
                        : state.isFocused
                          ? '#656365'
                          : '#181818',
                    color: state.isDisabled ? '#8A8A8A' : '#fff',
                    opacity: state.isDisabled ? 0.5 : 1,
                    borderRadius: '0px', // обнулим, чтобы исключить переполнение
                    margin: 0, // убираем отступы, чтобы не выбивалось
                    padding: '8px 12px',
                    whiteSpace: 'normal',
                    wordWrap: 'break-word', // важное добавление
                    overflowWrap: 'break-word', // тоже помогает
                    boxSizing: 'border-box',
                    maxWidth: '100%',
                }),
            }}
            options={props.options}
            value={props.value}
            onChange={(newValue) => props.onChange(newValue?.value ?? '')}
            placeholder={props.placeholder}
            noOptionsMessage={() => props.noOptionsMessage}
            isOptionDisabled={(option) =>
                props.isOptionDisabled != null
                    ? props.isOptionDisabled(option.value)
                    : false
            }
        />
    )
}

export default MultilineSelect
