import React from 'react'
import { useNavigate } from 'react-router-dom'

const OrgListsPage: React.FC = () => {
    const navigate = useNavigate()

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">Реестр перечней</p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_80px)] p-5">
                <div className="flex flex-col h-full w-[1260px] space-y-10 bg-slate-600">
                    {/* // body */}
                    <div className="flex bg-[#181818] h-[calc(100vh_-_120px)]">
                        {/* table */}
                        <div className="flex flex-col h-full w-full">
                            {/* toolbar */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg items-center h-10">
                                <div className="w-6 h-6"></div>
                            </div>

                            {/* name */}
                            <div className="flex flex-row gap-1 bg-[#262427] text-[#8A8A8A] text-[10px] px-1 py-2 h-10">
                                <div className="flex w-10 items-center justify-center text-center">
                                    №
                                </div>
                                <div className="flex flex-1 items-center justify-center text-center">
                                    Наименование перечня
                                </div>
                                <div className="flex w-28 items-center justify-center text-center">
                                    Количество позиций
                                </div>
                                <div className="flex w-28 items-center justify-center text-center">
                                    Дата изменения
                                </div>
                            </div>

                            {/* data */}
                            <div className="flex flex-col overflow-y-auto gap-1 py-1 bg-[#1D1C1E] h-dvh">
                                {/* Вывод пользователей */}
                                <>
                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        onClick={() =>
                                            navigate('/hub/org/lists/msp')
                                        }
                                    >
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            1
                                        </div>
                                        <div className="flex flex-1 items-center justify-left text-left px-2 py-2">
                                            Перечень товаров, работ, услуг,
                                            закупки которых осуществляются у
                                            субъектов малого и среднего
                                            предпринимательства
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                    </div>

                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        onClick={() =>
                                            navigate('/hub/org/lists/ep')
                                        }
                                    >
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            2
                                        </div>
                                        <div className="flex flex-1 items-center justify-left text-left px-2 py-2">
                                            Перечень оснований закупки у
                                            единственного поставщика
                                            (исполнителя, подрядчика)
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                    </div>

                                    <div
                                        className="flex flex-row cursor-pointer gap-1 mx-1 bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] rounded"
                                        onClick={() =>
                                            navigate('/hub/org/lists/fxd')
                                        }
                                    >
                                        <div className="flex w-10 items-center justify-center text-center text-[#8A8A8A]">
                                            3
                                        </div>
                                        <div className="flex flex-1 items-center justify-left text-left px-2 py-2">
                                            Перечень кодов и статей расходов
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                        <div className="flex w-28 items-center justify-center px-2">
                                            -
                                        </div>
                                    </div>
                                </>
                            </div>

                            {/* buttons */}
                            <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10 space-x-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OrgListsPage
