import React, { useState, useRef, useEffect, ReactNode, FC } from 'react'

import { ApprovalStatusFilter, PositionStatusFilter } from '../FilterTypes'

// Универсальный компонент ячейки-кнопки с PopupMenu,
// получающий через пропс конкретный компонент меню.
type Props = {
    children: ReactNode
    PopupComponent: FC<any>
    stage: string
    popupProps?: object
}

const TableCellWithPopup: FC<Props> = (props) => {
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const cellRef = useRef<HTMLDivElement>(null)

    const togglePopup = () => setShowPopup((prev) => !prev)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                cellRef.current &&
                !cellRef.current.contains(event.target as Node)
            ) {
                setShowPopup(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <div
            className="relative inline-block flex-row w-full h-full items-center justify-center text-center rounded-[10px] p-1 gap-1 hover:bg-[#333033] hover:text-white"
            ref={cellRef}
        >
            <button
                onClick={togglePopup}
                className="flex flex-row w-full h-full items-center justify-center text-center gap-1"
            >
                {props.children}
            </button>
            {showPopup && (
                <props.PopupComponent
                    stage={props.stage}
                    {...props.popupProps}
                    onClosePopup={(value: boolean) => {
                        setShowPopup(value)
                    }}
                />
            )}
        </div>
    )
}

export default TableCellWithPopup
