export enum PlanPositionStage {
    EDIT = 'Редактирование',
    READY_FOR_PLACEMENT = 'Подготовка к размещению',
    SENT_TO_EIS = 'Отправка в ЕИС',
    PURCHASE_PLAN_IN_EIS = 'План закупки в ЕИС',
}

export type PositionFinancialProvisionVolumesItem = {
    type_code: string
    type_name: string
    target_code: string
    target_name: string
    financial_volume_position_rub: number
}

export type PositionFinancialProvisionVolumesItems = {
    items: PositionFinancialProvisionVolumesItem[]
}

export type PlanPosition = {
    guid: string
    id: number
    ordinal_number: number
    contract_subject: string
    plan_item_customer: PlanItemCustomer
    minimum_requirements: string | null
    contract_end_date: string // Date string in ISO format
    modification_description: string | null
    status: string
    changed_GWS_and_dates: boolean | null
    changed_NMSK_more_ten_percent: boolean | null
    cancellation_reason: string | null
    other_changes: boolean | null
    long_term: boolean
    shared: boolean
    initial_position_guid: string | null
    initial_plan_guid: string | null
    long_term_volumes: LongTermVolumes | null
    long_term_smb_volumes: LongTermVolumes | null
    parent_id: string
    okato: string | null
    region: string | null
    is_general_address: boolean
    maximum_contract_price: number
    currency_digital_code: number
    currency_code: string
    currency_name: string
    order_pricing: string | null
    exchange_rate: number | null
    exchange_rate_date: string | null // Date string in ISO format
    maximum_contract_price_rub: number | null // Replace 'any' with the actual type if known
    purchase_planned_date: string | null // Date string in ISO format
    purchase_period_year: number | null
    purchase_period_quarter: number | null
    purchase_period_month: number | null
    purchase_method_code: number | null
    purchase_method_name: string
    hide_purchase_plan_item: boolean
    is_electronic: boolean | null
    is_purchase_ignored: boolean
    purchase_category: number | null // Replace 'any' with the actual type if known
    innovation_equivalent: boolean
    planned_after_second_year: boolean
    maximum_contract_price_from_budget: boolean
    position_financial_provision_volumes: PositionFinancialProvisionVolumesItems | null // Replace 'any' with the actual type if known
    organization_guid: string
    plan_guid: string
    guid_eis: string | null
    position_edition: number | null
    position_stage: string
    position_status: string
    position_version: number | null
    is_purchase_smb: boolean | null
    type_object_purchase_position: string
    is_nmcd_with_tax: boolean
    struct_guid: string | null
    struct_name: string | null
    expense_items_guid: string | null
    expense_items_name: string | null
    proposed_method_guid: string | null
    proposed_method_name: string | null
    exclusive_purchase_reason_guid: string | null
    exclusive_purchase_reason_name: string | null
    single_purchase_reason_guid: string | null
    single_purchase_reason_name: string | null
    user_guid: string
    last_synced_time: string // Date string in ISO format
    sys_created_at: string // Date string in ISO format
    sys_updated_at: string // Date string in ISO format
    annotation: string
    execution_address: string
    approval_status: number | null
    first_name: string | null
    last_name: string | null
    patronymic: string | null
}

export type PlanItemCustomer = {
    iko: string
    inn: number
    kpp: number
    ogrn: number
    okfs: number
    okpo: number
    email: string | null
    okato: string
    okopf: number
    full_name: string
    okfs_name: string
    okopf_name: string
    short_name: string
    legal_address: string
    postal_address: string
}

export type LongTermVolumes = {
    volume: number
    details: Details
    currency: Currency
    volume_rub?: number | undefined
    exchange_rate: number | null
    exchange_rate_date: string | null
}

export type Details = {
    items: ItemDetail[]
}

export type ItemDetail = {
    summ: number
    year: number
    summ_rub?: number
}

export type Currency = {
    code: string
    name: string
    digital_code: number
}

export type PositionFinancialProvisionVolumes = {
    items: Items[]
}

export type Items = {
    target_code: string
    target_name: string
    type_code: string
    type_name: string
    financial_volume_position_rub: number
}

export type Attachment = {
    guid: string
    create_datetime: string
    filename: string
    description: string
    attachment_status: string
    organization_guid: string
    plan_guid: string
    url: string
    sys_updated_at: string
}
