import React, { useState, useEffect } from 'react'

import { UserFilter, ApprovalStatusFilter, SortOrder } from '../FilterTypes'

type Props = {
    stage: string
    criteriaApprovalStatus?: ApprovalStatusFilter

    onClosePopup: (value: boolean) => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "Статус согласования"
    onChangeFilterSettingCriteriaApprovalStatusSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaApprovalStatus: (
        values: (number | null)[] | null
    ) => void
}

const PopupMenuApproval: React.FC<Props> = (props) => {
    // Локальное состояние для чекбоксов (для мгновенного обновления UI)
    const [selectedValues, setSelectedValues] = useState<(number | null)[]>(
        props.criteriaApprovalStatus?.values || []
    )

    // При обновлении критериев родителя синхронизируем локальное состояние
    useEffect(() => {
        setSelectedValues(props.criteriaApprovalStatus?.values || [])
    }, [props.criteriaApprovalStatus])

    // Функция для переключения выбранного значения
    const toggleValue = (value: number | null) => {
        let newSelected = [...selectedValues]

        if (value === 1) {
            // Для "Согласовано": если уже выбран (есть 1 или 2) – удаляем оба,
            // если не выбран – добавляем 1 и 2
            const isSelected =
                newSelected.includes(1) || newSelected.includes(2)
            if (isSelected) {
                newSelected = newSelected.filter((v) => v !== 1 && v !== 2)
            } else {
                newSelected.push(1, 2)
            }
        } else {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((v) => v !== value)
            } else {
                newSelected.push(value)
            }
        }

        // Если массив пустой, result будет равен null
        const result = newSelected.length === 0 ? null : newSelected

        // Локальное состояние оставляем как массив, чтобы UI корректно обновлялся
        setSelectedValues(newSelected)
        props.onChangeFilterSettingCriteriaApprovalStatus(result)
    }

    const selectAllValues = () => {
        const allValues = [0, 1, 2, 3, null]
        setSelectedValues(allValues)
        props.onChangeFilterSettingCriteriaApprovalStatus(allValues)
    }

    return (
        <div className="absolute left-0 my-2 w-56 origin-top-right bg-[#333033] border border-[#696769] rounded-[10px] shadow-lg z-10">
            <div className="p-1">
                {/* Блок с чекбоксами */}

                <div className="flex flex-col gap-1">
                    <label
                        className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleValue(0)
                        }}
                    >
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            checked={selectedValues.includes(0)}
                            readOnly
                            onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                        />
                        <span className="ml-2 text-[12.8px] text-white">
                            Идет согласование
                        </span>
                    </label>
                    <label
                        className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleValue(1)
                        }}
                    >
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            checked={
                                selectedValues.includes(1) ||
                                selectedValues.includes(2)
                            }
                            readOnly
                            onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                        />
                        <span className="ml-2 text-[12.8px] text-white">
                            Согласовано
                        </span>
                    </label>
                    <label
                        className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleValue(3)
                        }}
                    >
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            checked={selectedValues.includes(3)}
                            readOnly
                            onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                        />
                        <span className="ml-2 text-[12.8px] text-white">
                            Не согласовано
                        </span>
                    </label>
                    <label
                        className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleValue(null)
                        }}
                    >
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            checked={selectedValues.includes(null)}
                            readOnly
                            onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                        />
                        <span className="ml-2 text-[12.8px] text-white">
                            Отсутствует
                        </span>
                    </label>
                </div>

                <div className="flex flex-row w-full px-4 text-[11px] text-left items-center justify-end mt-1">
                    <label
                        className="w-full text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            selectAllValues()
                        }}
                    >
                        Выделить все
                    </label>
                    <label
                        className="w-full text-[11px] text-right text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaApprovalStatus(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <hr className="mt-3 mx-4 border-[#464447]" />

                <div className="flex flex-col w-full gap-1 mt-3">
                    {/* Заголовок сортировки и кнопки сортировки */}
                    <div className="px-4 text-[11px] text-left text-[#8A8A8A]">
                        Сортировка
                    </div>
                    {/* Разделитель */}
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaApprovalStatusSort(
                                'asc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="rotate(90 12 12)">
                                <path
                                    fill={`${props.criteriaApprovalStatus?.sort_order == 'asc' ? '#0284c7' : '#8A8A8A'}`}
                                    d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                ></path>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По возрастанию
                        </span>
                    </button>
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaApprovalStatusSort(
                                'desc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="translate(24,0) scale(-1,1)">
                                <g transform="rotate(-90 12 12)">
                                    <path
                                        fill={`${props.criteriaApprovalStatus?.sort_order == 'desc' ? '#0284c7' : '#8A8A8A'}`}
                                        d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По убыванию
                        </span>
                    </button>
                </div>

                <div className="flex w-full px-4 text-[11px] text-left items-center justify-end">
                    <label
                        className="w-fit text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaApprovalStatusSort(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <div className="flex flex-col w-full mt-3 items-start justify-center">
                    <button
                        className="flex h-6 flex-col w-full gap-1 text-left text-[12.8px] text-white rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            props.onAcceptFilter()
                            props.onClosePopup(false)
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupMenuApproval
