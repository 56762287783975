import React, { useState } from 'react'
import './index.css'
import { PlanPositionStage } from '../../types'

type Props = {
    stage: PlanPositionStage
    edit: number | null
    ready: number | null
    sent: number | null
    onTabChange: (stage: PlanPositionStage) => void
    onTabClick: (selectAllFlag: boolean) => void
}

const PlanPositionsTabs: React.FC<Props> = ({
    stage,
    edit,
    ready,
    sent,
    onTabClick,
    onTabChange,
}) => {
    return (
        <div className="flex flex-row ">
            <div className="w-5"></div>
            {Object.values(PlanPositionStage).map((item, index) => {
                return (
                    <div
                        key={index}
                        className={`cursor-pointer ${
                            stage == item
                                ? 'plan-position-tab-active rounded-t-[10px]'
                                : 'plan-position-tab-inactive rounded-t-[10px]'
                        }`}
                        onClick={() => {
                            onTabChange(item)
                            onTabClick(false)
                        }}
                    >
                        <div className="flex flex-row w-full h-full items-center justify-center gap-2">
                            {item}

                            {index == 0 ? (
                                edit ? (
                                    <div className="flex w-fit px-2 rounded-full bg-[#333033] text-white items-center justify-center text-[12.8px]">
                                        {edit}
                                    </div>
                                ) : (
                                    ''
                                )
                            ) : index == 1 ? (
                                ready ? (
                                    <div className="flex w-fit px-2 rounded-full bg-[#333033] text-white items-center justify-center text-[12.8px]">
                                        {ready}
                                    </div>
                                ) : (
                                    ''
                                )
                            ) : index == 2 ? (
                                sent ? (
                                    <div className="flex w-fit px-2 rounded-full bg-[#333033] text-white items-center justify-center text-[12.8px]">
                                        {sent}
                                    </div>
                                ) : (
                                    ''
                                )
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default PlanPositionsTabs
