import React from 'react'
import { Plan } from '../../types'

type Props = {
    positionCount: number | string
    sum: number | string
    mspSum: number
    percentMspSum: number
    plan?: Plan
    planVersion: string | null
    loadPlan: boolean
    planVersionDiff: string
    onUpdate: (regNumber: number) => void
}

const PlanPositionsHeader: React.FC<Props> = ({
    positionCount,
    sum,
    mspSum,
    percentMspSum,
    plan,
    planVersion,
    loadPlan,
    planVersionDiff,
    onUpdate,
}) => {
    return (
        <div className=" h-24 mx-6 flex flex-row gap-x-5 ">
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">Реестровый номер</div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        (plan?.registration_number ?? 'Нет данных')
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Период планирования
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        (plan?.start_date.split('-')[0] ?? 'Нет данных')
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Количество позиций
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        positionCount
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        Number(sum).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        }) + ' ₽'
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="w-60 my-auto content-center border-r border-r-[#2A282B]">
                <div className="text-xs text-[#8A8A8A] ">
                    Запланировано закупок у МСП
                </div>
                <div className="text-xs text-white ">
                    {loadPlan ? (
                        Number(mspSum).toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                        }) + ` ₽ (${percentMspSum}%)`
                    ) : (
                        <svg
                            className="animate-spin h-4 w-4 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                        </svg>
                    )}
                </div>
            </div>
            <div className="flex flex-row w-60 my-auto items-center justify-start border-r border-r-[#2A282B] gap-3">
                <div className="flex flex-col w-fit my-auto items-start justify-start">
                    <div className="text-xs text-[#8A8A8A] text-left">
                        Версия плана
                    </div>
                    <div className="text-xs text-white text-left">
                        {loadPlan ? (
                            planVersion
                        ) : (
                            <svg
                                className="animate-spin h-4 w-4 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        )}
                    </div>
                </div>
                <div className="text-xs text-white ">
                    {planVersionDiff != '' ? (
                        <button
                            onClick={() =>
                                onUpdate(
                                    plan?.registration_number
                                        ? plan?.registration_number
                                        : 0
                                )
                            }
                            className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                            title={`Обновите план. Версия плана в ЕИС: ${planVersionDiff}`}
                        >
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                                ></path>
                            </svg>
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            {/* <div className="w-60 my-auto content-center ">
                <div className="text-xs text-[#8A8A8A] ">
                    Закупки исключаемые у МСП
                </div>
                <div className="text-xs text-white ">0,00 ₽</div>
            </div> */}
        </div>
    )
}

export default PlanPositionsHeader
