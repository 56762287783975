import React, { Suspense } from 'react'
import { Await, Outlet, useLoaderData } from 'react-router-dom'
import { AuthProvider } from '../hooks/auth'
import { User } from '../services/auth'
import CookieConsent from '../components/CookieConsent'

const RootLayout: React.FC = () => {
    const { userPromise } = useLoaderData() as {
        userPromise: Promise<User | null>
    }

    return (
        <Suspense
            fallback={
                <div className="flex items-center justify-center h-full">
                    <p className="text-white text-xs">Загрузка...</p>
                </div>
            }
        >
            <Await resolve={userPromise}>
                {(user) => (
                    <AuthProvider user={user}>
                        <Outlet />
                    </AuthProvider>
                )}
            </Await>
            <CookieConsent />
        </Suspense>
    )
}

export default RootLayout
