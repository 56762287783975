import React, { useState, useEffect, useRef, useCallback } from 'react'
import { PlanPositionRow, Okei, PurchaseItemRowErrors } from '../types'
import { Okpd2 } from '../../../../Org/Handbook/OKPD2/types'
import { OrganizationInfo } from '../../../../Org/Home/types'

type LayoutProps = {
    index: number
    orgData: OrganizationInfo | null | undefined
    positionRow: PlanPositionRow
    showRegion: boolean
    onNameChanged: (value: string) => void
    onImpossibleToDetermineAttrChanged: (
        attr: boolean | null,
        value_qty: number | null,
        code_okei: string | null,
        value_okei: string | null
    ) => void
    onCountChanged: (value: number | null) => void
    onOkeiChanged: (code: string | null, value: string | null) => void
    onTypePurchaseChanged: (value: string) => void
    onDropdownSelected: (type: string) => void
    onDeleteClick: () => void
    okeiList: Okei[]
    disabledFieldset: boolean
    selected: boolean
    onClick: (row_guid: string) => void
    onCopy: (row_guid: string) => void
    errors?: PurchaseItemRowErrors
}

const PurchaseItemsTableRow: React.FC<LayoutProps> = ({
    index,
    orgData,
    positionRow,
    showRegion,
    onNameChanged,
    onImpossibleToDetermineAttrChanged,
    onCountChanged,
    onOkeiChanged,
    onTypePurchaseChanged,
    onDropdownSelected,
    onDeleteClick,
    okeiList,
    disabledFieldset,
    selected,
    onClick,
    onCopy,
    errors,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null)
    const [text, setText] = useState('')
    const [dataSearchOkpd2, setDataSearchOkpd2] = useState<Okpd2 | null>(null)

    const [localValue, setLocalValue] = useState(
        positionRow.qty != null
            ? Number(positionRow.qty).toLocaleString('ru-RU', {
                  useGrouping: false,
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 13,
              })
            : ''
    )

    // Сохраняем идентификатор таймера для debounce
    const debounceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
        null
    )

    // Функция для запроса данных OKPD2
    const searchOkpd2 = async (codeOkpd2: string) => {
        if (!codeOkpd2) {
            setDataSearchOkpd2(null)
            return
        }

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/backend/v1/okpd2/search_code?text_query=${codeOkpd2}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (response.ok) {
                const data: Okpd2[] = await response.json()
                if (data.length > 0) {
                    setDataSearchOkpd2(data[0]) // Берем первый результат
                } else {
                    setDataSearchOkpd2(null)
                }
            } else {
                throw new Error('Ошибка запроса на поиск OKPD2')
            }
        } catch (error) {
            console.error(error)
            setDataSearchOkpd2(null)
        }
    }

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto' // Сбрасываем высоту перед пересчетом
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + 2}px`
        }
    }, [text]) // Вызываем при изменении текста

    // Вызываем searchOkpd2 при монтировании компонента и при изменении positionRow.okpd2_code
    useEffect(() => {
        searchOkpd2(positionRow.okpd2_code ?? '')
    }, [positionRow.okpd2_code])

    // Если родитель изменяет значение, синхронизируем локальное состояние
    useEffect(() => {
        if (positionRow.qty != null) {
            setLocalValue(
                Number(positionRow.qty).toLocaleString('ru-RU', {
                    useGrouping: false,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 13,
                })
            )
        }
    }, [positionRow.qty])

    // Функция, которая вызывается с задержкой 500 мс после последнего изменения ввода
    const debouncedUpdate = useCallback(
        (value: string) => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current)
            }
            debounceTimeoutRef.current = setTimeout(() => {
                // Заменяем запятую на точку и пробуем распарсить число
                const parsed = parseFloat(value.replace(',', '.'))
                if (!isNaN(parsed)) {
                    // Ограничиваем число до 4 знаков после запятой
                    const rounded = Number(parsed.toFixed(4))
                    // Форматируем число согласно локали
                    const formatted = rounded.toLocaleString('ru-RU', {
                        useGrouping: false,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 13,
                    })
                    setLocalValue(formatted)
                    onCountChanged(rounded)
                } else {
                    setLocalValue('')
                    onCountChanged(0)
                }
            }, 500)
        },
        [onCountChanged]
    )

    const handleChange = (e: { target: { value: string } }) => {
        const { value } = e.target
        setLocalValue(value)
        debouncedUpdate(value)
    }

    return (
        <div className="flex flex-row gap-1 m-1 bg-[#242225] text-[11px] rounded items-start">
            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]">
                    <input
                        type="checkbox"
                        checked={selected}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => {
                            onClick(positionRow.guid)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center text-white">
                <button
                    onClick={() => onCopy(positionRow.guid)}
                    className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#656365]"
                    title="Копировать строку"
                >
                    <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        id="trash-alt"
                    >
                        <path
                            fill="#FFFFFF"
                            d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                        ></path>
                    </svg>
                </button>
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center text-white">
                {index + 1}
            </div>

            <div className="flex flex-1 min-h-8 text-left min-w-72">
                <textarea
                    ref={textareaRef}
                    className={`form-textarea-table-row flex h-8 min-h-8 w-full p-1 px-2 rounded-md text-[12px] text-white bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${
                        errors?.additional_info
                            ? 'border border-[#C15C5C]'
                            : 'border-0'
                    }`}
                    maxLength={2000}
                    value={positionRow.additional_info ?? ''}
                    onChange={(e) => {
                        onNameChanged(e.target.value)
                        setText(e.target.value)
                    }}
                    onInput={(e) => {
                        const target = e.target as HTMLTextAreaElement
                        target.style.height = 'auto' // Сброс высоты перед обновлением
                        target.style.height = `${target.scrollHeight}px` // Устанавливаем новую высоту
                    }}
                    rows={1}
                    title={
                        errors?.additional_info ? errors?.additional_info : ''
                    }
                />
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                <div
                    className="flex w-6 h-6 rounded-full items-center justify-center bg-[#242225] hover:bg-[#656365]"
                    title="Невозможно определить количество"
                >
                    <input
                        type="checkbox"
                        className="p-2"
                        checked={
                            positionRow.impossible_to_determine_attr || false
                        }
                        onChange={(e) => {
                            onImpossibleToDetermineAttrChanged(
                                e.target.checked,
                                null,
                                null,
                                null
                            )

                            console.log('Значение chacked ' + e.target.checked)
                        }}
                    />
                </div>
            </div>

            <div className="flex flex-row h-[28px] w-28 items-center justify-center text-center">
                <input
                    type="text"
                    inputMode="numeric"
                    lang="ru"
                    name="purchase_item_count"
                    className={`form-input-table-row rounded-md text-primary text-center w-full h-[28px] pr-1 focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] text-white ${
                        positionRow.impossible_to_determine_attr ? 'hidden' : ''
                    } ${errors?.qty ? 'border border-[#C15C5C]' : 'border-0'}`}
                    style={{ colorScheme: 'dark' }}
                    maxLength={18}
                    value={localValue}
                    onChange={handleChange}
                    disabled={positionRow.impossible_to_determine_attr || false}
                    title={errors?.qty ? errors?.qty : ''}
                />
            </div>

            <div className="flex flex-row h-[28px] w-32 items-center justify-center text-center">
                <select
                    name="purchase_item_count_type"
                    className={`form-input-table-row px-2 rounded-md w-full h-[28px] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] bg-[#181818] text-white ${positionRow.impossible_to_determine_attr ? 'hidden' : ''} ${errors?.okei_code ? 'border border-[#C15C5C]' : 'border-0'}`}
                    title={
                        errors?.okei_code
                            ? errors?.okei_code
                            : (positionRow.okei_name ?? '')
                    }
                    onChange={(e) => {
                        const selectedCode = e.target.value
                        const selectedOkei = okeiList.find(
                            (okei) => okei.code === selectedCode
                        )
                        onOkeiChanged(selectedCode, selectedOkei?.name || null)
                    }}
                    value={positionRow.okei_code ?? ''}
                    disabled={positionRow.impossible_to_determine_attr || false}
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    {okeiList.map((okei, index_okei) => (
                        <option
                            key={index_okei}
                            value={okei.code}
                            title={`${okei.name} (${okei.symbol?.toLowerCase()})`}
                        >
                            {okei.name} ({okei.symbol?.toLowerCase()})
                        </option>
                    ))}
                </select>
            </div>

            <div className="flex flex-row h-[28px] w-28 items-center justify-center text-center text-white">
                <select
                    name="purchase_item_type_purchase"
                    className={`form-input-table-row px-2 rounded-md bg-[#181818] w-full h-[28px] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${errors?.type_object_purchase ? 'border border-[#C15C5C]' : 'border-0'}`}
                    value={positionRow.type_object_purchase ?? ''}
                    onChange={(e) => onTypePurchaseChanged(e.target.value)}
                    title={
                        errors?.type_object_purchase
                            ? errors?.type_object_purchase
                            : ''
                    }
                >
                    <option className="text-[#8A8A8A]" value="">
                        Выбрать
                    </option>
                    <option value="G">Товар</option>
                    <option value="W">Работа</option>
                    <option value="S">Услуга</option>
                </select>
            </div>

            <div className="relative flex flex-row h-[28px] w-28 items-center justify-end">
                <input
                    type="text"
                    className={`form-input-table-row flex pl-2 pr-9 w-full h-[28px] rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${errors?.okpd2_code ? 'border border-[#C15C5C]' : 'border-0'}`}
                    value={positionRow.okpd2_code ?? ''}
                    readOnly
                    title={
                        errors?.okpd2_code
                            ? errors?.okpd2_code
                            : `${positionRow.okpd2_code} - ${positionRow.okpd2_name}`
                    }
                />
                {!disabledFieldset && (
                    <button
                        className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                        onClick={() => onDropdownSelected('okpd2')}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                )}
            </div>

            <div className="relative flex flex-row h-[28px] w-28 items-center justify-end">
                <input
                    type="text"
                    className={`form-input-table-row flex pl-2 pr-9 w-full h-[28px] rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${errors?.okved2_code ? 'border border-[#C15C5C]' : 'border-0'}`}
                    value={positionRow.okved2_code ?? ''}
                    readOnly
                    title={
                        errors?.okved2_code
                            ? errors?.okved2_code
                            : `${positionRow.okved2_code} - ${positionRow.okved2_name}`
                    }
                />

                {!disabledFieldset && (
                    <button
                        className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                        onClick={() => onDropdownSelected('okved2')}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                )}
            </div>

            {showRegion && (
                <div className="relative flex flex-row w-44 h-[28px] items-center justify-end">
                    <input
                        type="text"
                        className={`form-input-table-row flex pl-2 pr-9 w-full h-[28px] rounded-md bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] ${errors?.okato ? 'border border-[#C15C5C]' : 'border-0'}`}
                        value={positionRow.region ?? ''}
                        readOnly
                        title={
                            errors?.okato
                                ? errors?.okato
                                : `${positionRow.okato} - ${positionRow.region}`
                        }
                    />

                    <button
                        className="absolute flex w-6 h-6 mr-1 rounded-full items-center justify-center bg-[#181818] hover:bg-[#656365]"
                        onClick={() => onDropdownSelected('okato')}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="bars"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M3,8H21a1,1,0,0,0,0-2H3A1,1,0,0,0,3,8Zm18,8H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Zm0-5H3a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </button>
                </div>
            )}

            <div className="flex flex-row h-8 w-fit items-center justify-center text-center gap-1">
                <div
                    className=" flex w-6 h-5 items-center justify-center border-l border-[#464447] pl-1"
                    title="Закупка только у субъектов МСП"
                >
                    {dataSearchOkpd2?.is_msp ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className=" flex w-6 h-5 items-center justify-center"
                    title={`${dataSearchOkpd2?.is_rf_ban && dataSearchOkpd2?.is_rf_limit ? `Запрет + Ограничение ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_ban ? `Запрет ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_limit ? `Ограничение ${dataSearchOkpd2?.is_rf_quota ? `+ Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}` : `${dataSearchOkpd2?.is_rf_quota ? `Доля РФ (${dataSearchOkpd2?.quota_percent}%)` : ``}`}`}`}`}
                >
                    {dataSearchOkpd2?.is_rf_ban &&
                    dataSearchOkpd2?.is_rf_limit ? (
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#EF4444"
                                d="M16.5303 6.46967C16.8232 6.76256 16.8232 7.23744 16.5303 7.53033L6.53033 17.5303C6.38968 17.671 6.19891 17.75 6 17.75 5.80109 17.75 5.61032 17.671 5.46967 17.5303L1.46967 13.5303C1.17678 13.2374 1.17678 12.7626 1.46967 12.4697 1.76256 12.1768 2.23744 12.1768 2.53033 12.4697L6 15.9393 15.4697 6.46967C15.7626 6.17678 16.2374 6.17678 16.5303 6.46967zM22.5303 6.46966C22.8232 6.76254 22.8232 7.23742 22.5303 7.53032L12.5308 17.5303C12.2379 17.8232 11.7631 17.8232 11.4702 17.5304L9.96975 16.0304C9.67681 15.7376 9.67674 15.2627 9.96959 14.9697 10.2624 14.6768 10.7373 14.6767 11.0303 14.9696L12.0004 15.9394 21.4697 6.46968C21.7625 6.17678 22.2374 6.17677 22.5303 6.46966z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_ban ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#EF4444"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_limit ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#FAB502"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : dataSearchOkpd2?.is_rf_quota ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
                <div
                    className=" flex w-6 h-5 items-center justify-center"
                    title="Закупка только в электронной форме"
                >
                    {dataSearchOkpd2?.is_electronic == true ? (
                        <svg
                            className="w-5 h-5"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="check"
                        >
                            <path
                                fill="#187A33"
                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                            ></path>
                        </svg>
                    ) : (
                        ''
                    )}
                </div>
            </div>

            <div className="flex flex-row h-8 w-8 items-center justify-center text-center">
                {!disabledFieldset && (
                    <button
                        onClick={(e) => onDeleteClick()}
                        className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                        title="Удалить строку"
                    >
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="trash-alt"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                            ></path>
                        </svg>
                    </button>
                )}
            </div>
        </div>
    )
}

export default PurchaseItemsTableRow
