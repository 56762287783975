import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { PlanPosition } from '../types'
import TableFooterLeftToolbarButtons from '../components/Buttons/TableFooterLeftToolbarButtons'
import TableFooterRightToolbarButtons from '../components/Buttons/TableFooterRightToolbarButtons'

type Props = {
    user_guid: string | null
    position: PlanPosition
    approve: boolean
    isUserRoleName: string
    isSendPlan: boolean
    handleSave: () => void
    onPositionStatusChange: (posStatus: string) => void
    onPositionStageChange: (posStage: string) => void
    handleValidation: () => void
    isHaveError: boolean
}

const Footer: React.FC<Props> = ({
    user_guid,
    position,
    approve,
    isUserRoleName,
    isSendPlan,
    handleSave,
    onPositionStatusChange,
    onPositionStageChange,
    handleValidation,
    isHaveError,
}) => {
    const navigate = useNavigate()
    const [isStatusChanging, setStatusChanging] = useState<boolean>(false)
    const [isStageChanging, setStageChanging] = useState<boolean>(false)

    const [changePlanPositionPopup, setChangePlanPositionPopup] =
        useState<boolean>(false)

    const [isDeleting, setDeleting] = useState<boolean>(false)
    const [deletePlanPositionPopup, setDeletePlanPositionPopup] =
        useState<boolean>(false)

    const [isCopying, setCopying] = useState<boolean>(false)
    const [copyPlanPositionPopup, setCopyPlanPositionPopup] =
        useState<boolean>(false)
    const [copyPlanPositionDraftPopup, setCopyPlanPositionDraftPopup] =
        useState<boolean>(false)

    const [isCreating, setCreating] = useState<boolean>(false)

    const [isAnnulating, setAnnulating] = useState<boolean>(false)
    const [annulatePlanPositionPopup, setAnnulatePlanPositionPopup] =
        useState<boolean>(false)
    const [annulateReason, setAnnulateReason] = useState('REJECT')

    const stageChangePosition = async (
        planGuid: string,
        guid: string,
        stagePos: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_positions_draft_change_stage/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_stage: stagePos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change stage position from. Status: ${response.status}`
                )
            }

            console.log('Position stage change successfully')
        } catch (error) {
            console.error('Error while changing stage position:', error)
            setStageChanging(false)
        } finally {
        }
    }

    const statusChangePosition = async (
        planGuid: string,
        guid: string,
        statusPos: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_positions_draft_change_status/?plan_guid=${planGuid}&pos_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ position_status: statusPos }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to change status position from. Status: ${response.status}`
                )
            }

            console.log('Position status change successfully')
        } catch (error) {
            console.error('Error while changing status position:', error)
            setStatusChanging(false)
        } finally {
        }
    }

    const copyPositionDraft = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_from_draft/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCopying(false)
        } finally {
        }
    }

    const copyPosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_new_from/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }

            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCopying(false)
        } finally {
        }
    }

    const changePosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_from/?plan_guid=${planGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create new position from. Status: ${response.status}`
                )
            }
            console.log('Position created successfully')
        } catch (error) {
            console.error('Error while creating position:', error)
            setCreating(false)
        } finally {
        }
    }

    const annulatePosition = async (
        planGuid: string,
        guid: string,
        cancellationReason: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft_annulate/?plan_guid=${planGuid}&guid=${guid}&cancellation_reason=${cancellationReason}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to annulate position from. Status: ${response.status}`
                )
            }

            console.log('Position annulate successfully')
        } catch (error) {
            console.error('Error while annulating position:', error)
            setAnnulating(false)
        } finally {
        }
    }

    const deletePosition = async (
        planGuid: string,
        guid: string
    ): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_draft/?plan_guid=${planGuid}&guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to delete position. Status: ${response.status}`
                )
            }

            console.log('Position deleted successfully')
        } catch (error) {
            console.error('Error while deleting position:', error)
            setDeleting(false)
        } finally {
        }
    }

    const handleStageChange = (
        plan_guid: string,
        pos_guid: string,
        stage: string
    ) => {
        stageChangePosition(plan_guid, pos_guid, stage)
        onPositionStageChange(stage)
        // handleValidation()
    }

    const handleStatusChange = (
        plan_guid: string,
        pos_guid: string,
        status: string
    ) => {
        if (status == 'Готово') {
            handleSave()
        }
        statusChangePosition(plan_guid, pos_guid, status)
        onPositionStatusChange(status)
    }

    const handleCopyPlanPositionPopup = () => {
        setCopyPlanPositionPopup(!copyPlanPositionPopup)
    }

    const handleCopy = (plan_guid: string, pos_guid: string) => {
        copyPosition(plan_guid, pos_guid)
        navigate(`/hub/plans/${plan_guid}/positions?stage=Редактирование`)
    }

    const handleCopyPlanPositionDraftPopup = () => {
        setCopyPlanPositionDraftPopup(!copyPlanPositionDraftPopup)
    }

    const handleCopyDraft = (plan_guid: string, pos_guid: string) => {
        handleSave()
        copyPositionDraft(plan_guid, pos_guid)
        navigate(`/hub/plans/${plan_guid}/positions?stage=Редактирование`)
    }

    const handleChangePlanPositionPopup = () => {
        setChangePlanPositionPopup(!changePlanPositionPopup)
    }

    const handleChange = async (plan_guid: string, pos_guid: string) => {
        changePosition(plan_guid, pos_guid)
        navigate(`/hub/plans/${plan_guid}/positions?stage=Редактирование`)
    }

    const handleAnnulatePlanPositionPopup = () => {
        setAnnulatePlanPositionPopup(!annulatePlanPositionPopup)
    }

    const handleAnnulateReasonChange = async (value: string) => {
        setAnnulateReason(value)
    }

    const handleAnnulate = async (
        plan_guid: string,
        pos_guid: string,
        cancellationReason: string
    ) => {
        annulatePosition(plan_guid, pos_guid, cancellationReason)
        navigate(`/hub/plans/${plan_guid}/positions?stage=Редактирование`)
    }

    const handleDeletePlanPositionPopup = () => {
        setDeletePlanPositionPopup(!deletePlanPositionPopup)
    }

    const handleDelete = (
        plan_guid: string,
        pos_guid: string,
        stage = position.position_stage
    ) => {
        deletePosition(plan_guid, pos_guid)
        navigate(`/hub/plans/${plan_guid}/positions?stage=${stage}`)
    }

    const handleSaveClose = () => {
        handleSave()
        navigate(
            `/hub/plans/${position.plan_guid}/positions?stage=${position.position_stage}`
        )
    }

    const handleClose = () => {
        navigate(
            `/hub/plans/${position.plan_guid}/positions?stage=${position.position_stage}`
        )
    }

    return (
        <footer className="flex flex-row w-full min-h-10 items-center justify-end">
            <div className="flex flex-row gap-2 p-2 px-[22px] w-full h-full bg-zinc-800 items-center justify-start">
                <TableFooterLeftToolbarButtons
                    user_guid={user_guid}
                    pos_user_guid={position.user_guid}
                    role={isUserRoleName}
                    stage={position.position_stage}
                    status={position.position_status}
                    approve={approve}
                    isSendPlan={isSendPlan}
                    isHaveError={isHaveError}
                    actions={{
                        return_to_edit: () => {
                            handleStageChange(
                                position.plan_guid,
                                position.guid,
                                'Редактирование'
                            )
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Черновик'
                            )
                        },
                        return_to_ready: () => {
                            handleStageChange(
                                position.plan_guid,
                                position.guid,
                                'Подготовка к размещению'
                            )
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Подготовка'
                            )
                        },
                        ready: () =>
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Готово'
                            ),
                        edit: () =>
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Черновик'
                            ),
                        edit_ready: () =>
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Подготовка'
                            ),
                        ready_to_sent: () => {
                            handleStageChange(
                                position.plan_guid,
                                position.guid,
                                'Подготовка к размещению'
                            )
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Подготовка'
                            )
                        },
                        ready_to_eis: () => {
                            handleStageChange(
                                position.plan_guid,
                                position.guid,
                                'Отправка в ЕИС'
                            )
                            handleStatusChange(
                                position.plan_guid,
                                position.guid,
                                'Готово к отправке'
                            )
                        },
                        validation: handleValidation,
                    }}
                />
                <div className="flex flex-1"></div>
                <TableFooterRightToolbarButtons
                    user_guid={user_guid}
                    pos_user_guid={position.user_guid}
                    role={isUserRoleName}
                    stage={position.position_stage}
                    status={position.position_status}
                    approve={approve}
                    isSendPlan={isSendPlan}
                    isAnnulate={position.status == 'A'}
                    actions={{
                        save: handleSave,
                        copy: handleCopyPlanPositionPopup,
                        copy_draft: handleCopyPlanPositionDraftPopup,
                        delete: handleDeletePlanPositionPopup,
                        close_save: handleSaveClose,
                        close: handleClose,
                        close_approve: () => navigate(`/hub/approvals/plans`),
                        change: handleChangePlanPositionPopup,
                        annulate: handleAnnulatePlanPositionPopup,
                    }}
                />
            </div>

            {copyPlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCopyPlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-white">
                                    Копировать позицию?
                                </label>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#6B696C]"
                                    onClick={handleCopyPlanPositionPopup}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]"
                                    onClick={() =>
                                        handleCopy(
                                            position.plan_guid,
                                            position.guid
                                        )
                                    }
                                >
                                    Копировать
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {copyPlanPositionDraftPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCopyPlanPositionDraftPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-white">
                                    Копировать позицию?
                                </label>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#6B696C]"
                                    onClick={handleCopyPlanPositionDraftPopup}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]"
                                    onClick={() =>
                                        handleCopyDraft(
                                            position.plan_guid,
                                            position.guid
                                        )
                                    }
                                >
                                    Копировать
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {changePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleChangePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-white">
                                    Изменить позицию?
                                </label>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#6B696C]"
                                    onClick={handleChangePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]"
                                    onClick={() =>
                                        handleChange(
                                            position.plan_guid,
                                            position.guid
                                        )
                                    }
                                >
                                    Изменить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {deletePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleDeletePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-white">
                                    Удалить позицию?
                                </label>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#6B696C]"
                                    onClick={handleDeletePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#701F1F] hover:bg-[#872626]"
                                    onClick={() =>
                                        handleDelete(
                                            position.plan_guid,
                                            position.guid,
                                            position.position_stage
                                        )
                                    }
                                >
                                    Удалить
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {annulatePlanPositionPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleAnnulatePlanPositionPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-fit max-h-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col text-sm text-white items-center justify-center gap-5">
                            <div className="flex flex-col">
                                <label className="flex mb-1 text-sm text-[#8A8A8A]">
                                    Обоснование аннулирования позиций
                                </label>
                                <select
                                    className="flex p-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                    value={annulateReason}
                                    onChange={(e) =>
                                        handleAnnulateReasonChange(
                                            e.target.value
                                        )
                                    }
                                >
                                    <option value="REJECT">
                                        Отказ от проведения закупки
                                    </option>
                                    <option value="INVALID">
                                        Признание торгов недействительными
                                    </option>
                                    <option value="NOT_PERFORMED">
                                        Торги не состоялись
                                    </option>
                                </select>
                            </div>
                            <div className="flex w-full flex-row items-center justify-end">
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#6B696C]"
                                    onClick={handleAnnulatePlanPositionPopup}
                                >
                                    Отмена
                                </button>
                                <button
                                    className="flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#701F1F] hover:bg-[#872626]"
                                    onClick={() =>
                                        handleAnnulate(
                                            position.plan_guid,
                                            position.guid,
                                            annulateReason
                                        )
                                    }
                                >
                                    Аннулировать
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </footer>
    )
}

export default Footer
