import React, { useState, useEffect, useRef } from 'react'
import Section from '../../../../../../components/Section'
import Checkbox from '../../../../../../components/form/Checkbox'
import {
    PurchaseMethodType,
    IgnoredCategory,
    PurchaseMethodErrors,
} from '../types'
import { ListEp } from '../../../../Org/Lists/types'
import { OrganizationInfo } from '../../../../Org/Home/types'
import MultilineSelect from '../../../../../../components/form/MultilineSelect'

interface Props {
    posStatus: string
    methodCode: number | null
    onMethodCodeChange: (value: number) => void
    method: string | null
    onMethodChange: (value: string) => void
    isElectronic: boolean | null
    onIsElectronicChange: (value: boolean | null) => void
    basisSingleSupplier: string | null
    onBasisSingleSupplierChange: (value: string) => void
    areParticipantsSME: boolean | null
    onAreParticipantsSMEChange: (value: boolean | null) => void
    isPlannedThirdYear: boolean
    onIsPlannedThirdYearChange: (value: boolean) => void
    isInnovativeProduct: boolean
    onIsInnovativeProductChange: (value: boolean) => void
    isExcludedForSME: boolean
    onIsExcludedForSMEChange: (value: boolean) => void
    category: number | null
    onCategoryChange: (value: number | null) => void
    posMSPStatus: boolean | null
    isOkpd2Have: boolean
    errors?: PurchaseMethodErrors
    orgData: OrganizationInfo | undefined
}

const getPurchaseMethodList = async (): Promise<PurchaseMethodType[]> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/purchase_method_active',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getSingleSupplierPurchase = async (): Promise<ListEp[]> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/list_single_supplier_purchase',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const getIgnoredCategory = async (): Promise<IgnoredCategory[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/ignored_category',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const PurchaseMethod: React.FC<Props> = (props) => {
    // const { basisSingleSupplier, onBasisSingleSupplierChange } = props
    // const { category, onCategoryChange } = props

    const [disabledOnlyMspCheck, setDisabledOnlyMspCheck] = useState(true)
    const [purchaseMethodList, setPurchaseMethodList] = useState<
        PurchaseMethodType[]
    >([])
    const [singleSupplierPurchaseList, setSingleSupplierPurchaseList] =
        useState<ListEp[]>([])
    const [ignoredCategoryList, setIgnoredCategoryList] = useState<
        IgnoredCategory[]
    >([])
    const [localAreParticipantsSME, setLocalAreParticipantsSME] = useState<
        boolean | null
    >(props.areParticipantsSME)
    const prevAreParticipantsSME = useRef<boolean | null>(
        props.areParticipantsSME
    )

    useEffect(() => {
        if (props.areParticipantsSME !== prevAreParticipantsSME.current) {
            setLocalAreParticipantsSME(props.areParticipantsSME)
            prevAreParticipantsSME.current = props.areParticipantsSME
        }
    }, [props.areParticipantsSME])

    const handleSMEChange = (value: boolean) => {
        if (localAreParticipantsSME !== value) {
            setLocalAreParticipantsSME(value)
            props.onAreParticipantsSMEChange(value)
        }
    }

    useEffect(() => {
        const fetchPurchaseMethods = async () => {
            const methods = await getPurchaseMethodList()
            if (methods) {
                setPurchaseMethodList(methods) // Обновляем состояние
            }

            const singleMethods = await getSingleSupplierPurchase()
            if (singleMethods) {
                setSingleSupplierPurchaseList(singleMethods) // Обновляем состояние
            }

            const ignoredCategories = await getIgnoredCategory()
            if (ignoredCategories) {
                setIgnoredCategoryList(ignoredCategories) // Обновляем состояние
            }
        }
        fetchPurchaseMethods()
    }, [])

    useEffect(() => {
        const matchingMethod = purchaseMethodList.find(
            (method) =>
                method.purchase_method_name.toLowerCase() ===
                props.method?.toLowerCase()
        )

        props.onMethodCodeChange(matchingMethod?.purchase_method_code || 0)
        props.onIsElectronicChange(matchingMethod?.is_electronic || false)

        let newSMEState = props.areParticipantsSME

        // Проверка на настройки организации по способам закупки
        if (props.orgData?.all_purchase_method) {
            if (props.method != null) {
                if (props.method.toLowerCase().includes('единствен')) {
                    setDisabledOnlyMspCheck(false)
                } else if (matchingMethod && matchingMethod.purchase_smb_type) {
                    setDisabledOnlyMspCheck(true)
                    newSMEState = true
                } else {
                    setDisabledOnlyMspCheck(true)
                    newSMEState = false
                }
            } else {
                setDisabledOnlyMspCheck(props.areParticipantsSME || false)
            }
        } else {
            if (props.method != null) {
                if (props.isOkpd2Have) {
                    setDisabledOnlyMspCheck(true)
                } else if (props.method.toLowerCase().includes('единствен')) {
                    setDisabledOnlyMspCheck(false)
                } else if (matchingMethod && matchingMethod.purchase_smb_type) {
                    setDisabledOnlyMspCheck(true)
                    newSMEState = true
                } else {
                    setDisabledOnlyMspCheck(true)
                    newSMEState = false
                }
            } else if (props.isOkpd2Have) {
                setDisabledOnlyMspCheck(true)
            } else {
                setDisabledOnlyMspCheck(props.areParticipantsSME || false)
            }
        }

        if (newSMEState !== localAreParticipantsSME) {
            setLocalAreParticipantsSME(newSMEState)
            props.onAreParticipantsSMEChange(newSMEState)
        }
    }, [props.method, purchaseMethodList])

    useEffect(() => {
        if (props.isExcludedForSME != true) props.onCategoryChange(null)
    }, [props.isExcludedForSME])

    let basicSingleSupplierOption = undefined
    if (props.basisSingleSupplier) {
        const selected = singleSupplierPurchaseList.find(
            (item) =>
                item.single_supplier_purchase_name == props.basisSingleSupplier
        )
        if (selected) {
            basicSingleSupplierOption = {
                value: selected.single_supplier_purchase_name,
                label: `${selected.single_supplier_purchase_paragraph}. ${selected.single_supplier_purchase_name}`,
            }
        }
    }

    let ignoredCategory = undefined
    if (props.category) {
        const selected = ignoredCategoryList.find(
            (item) => item.code == props.category
        )
        if (selected) {
            ignoredCategory = {
                value: String(selected.code),
                label: `${selected.code}. ${selected.name}`,
            }
        }
    }

    return (
        <Section title="Информация о закупке">
            <div className="form-label">Способ закупки</div>
            <MultilineSelect
                options={purchaseMethodList.map((method) => ({
                    value: method.purchase_method_name,
                    label: method.purchase_method_name,
                }))}
                value={
                    props.method
                        ? {
                              value: props.method,
                              label: props.method,
                          }
                        : undefined
                }
                onChange={(newValue) => {
                    props.onMethodChange(newValue)
                }}
                placeholder="Выбрать способ закупки"
                noOptionsMessage="Данные отсутствуют"
                isOptionDisabled={(option) => {
                    const method = purchaseMethodList.find(
                        (item) => item.purchase_method_name == option
                    )
                    if (!method) {
                        return false
                    }

                    return props.orgData?.all_purchase_method
                        ? false
                        : !props.isOkpd2Have
                          ? false
                          : method.purchase_method_name
                                  .toLowerCase()
                                  .includes('единствен')
                            ? false
                            : props.method
                              ? props.areParticipantsSME !=
                                method.purchase_smb_type
                              : props.isOkpd2Have
                                ? props.areParticipantsSME !=
                                  method.purchase_smb_type
                                : false
                }}
            />
            {/* <select
                className={`w-full form-select ${
                    props.errors?.purchase_method_name
                        ? 'border border-[#C15C5C]'
                        : ''
                }`}
                title={props.errors?.purchase_method_name}
                value={props?.method || ''}
                onChange={(e) => props.onMethodChange(e.target.value)}
            >
                <option disabled value="" className="text-[#8A8A8A]">
                    Выбрать способ закупки
                </option>
                {purchaseMethodList?.length > 0 ? (
                    purchaseMethodList.map((method, index) => (
                        <option
                            key={index}
                            value={method.purchase_method_name}
                            disabled={
                                props.orgData?.all_purchase_method
                                    ? false
                                    : !props.isOkpd2Have
                                      ? false
                                      : method.purchase_method_name
                                              .toLowerCase()
                                              .includes('единствен')
                                        ? false
                                        : props.method
                                          ? props.areParticipantsSME !=
                                            method.purchase_smb_type
                                          : props.isOkpd2Have
                                            ? props.areParticipantsSME !=
                                              method.purchase_smb_type
                                            : false
                            }
                        >
                            {method.purchase_method_name}
                        </option>
                    ))
                ) : (
                    <option disabled>Данные отсутствуют</option>
                )}
            </select> */}

            {props.method?.toLowerCase().includes('единствен') && (
                <>
                    <div className="mt-2 form-label">
                        Основание для проведения закупки у единственного
                        поставщика (исполнителя, подрядчика)
                    </div>
                    <MultilineSelect
                        options={singleSupplierPurchaseList.map((item) => ({
                            value: item.single_supplier_purchase_name,
                            label: `${item.single_supplier_purchase_paragraph}. ${item.single_supplier_purchase_name}`,
                        }))}
                        value={basicSingleSupplierOption}
                        onChange={(newValue) => {
                            props.onBasisSingleSupplierChange(newValue)
                        }}
                        placeholder="Выбрать основание"
                        noOptionsMessage="Данные отсутствуют"
                    />
                </>
            )}

            <div className="mt-5 flex flex-col space-y-3">
                <Checkbox
                    label="Закупка, участниками которой являются только субъекты малого и среднего предпринимательства"
                    checked={localAreParticipantsSME || false}
                    onChange={handleSMEChange}
                    disabled={disabledOnlyMspCheck}
                />

                {localAreParticipantsSME == true ? (
                    <Checkbox
                        label="Закупка запланирована на третий или последующие годы реализации плана закупки"
                        checked={props.isPlannedThirdYear}
                        onChange={props.onIsPlannedThirdYearChange}
                        disabled={false}
                    />
                ) : (
                    ''
                )}

                <Checkbox
                    label="Закупка товаров, работ, услуг, удовлетворяющих критериям отнесения к инновационной продукции, высокотехнологичной продукции"
                    checked={props.isInnovativeProduct ?? false}
                    onChange={props.onIsInnovativeProductChange}
                    disabled={false}
                />
                <Checkbox
                    label="Закупка исключается при расчете годового объема закупок, участниками которых являются субъекты малого и среднего предпринимательства"
                    checked={props.isExcludedForSME ?? false}
                    onChange={props.onIsExcludedForSMEChange}
                    disabled={false}
                />
            </div>

            {props.isExcludedForSME && (
                <>
                    <div className="mt-2 form-label">
                        Категория закупки в соответствии с пунктом 7
                        постановления Правительства РФ от 11.12.2014 № 1352
                    </div>
                    <MultilineSelect
                        options={ignoredCategoryList.map((item) => ({
                            value: String(item.code),
                            label: `${item.code}. ${item.name}`,
                        }))}
                        value={ignoredCategory}
                        onChange={(newValue) => {
                            props.onCategoryChange(Number(newValue))
                        }}
                        placeholder="Выбрать основание"
                        noOptionsMessage="Данные отсутствуют"
                    />
                </>
            )}
        </Section>
    )
}

export default PurchaseMethod
