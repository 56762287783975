import React, { useState, useEffect, useRef } from 'react'

import {
    UserFilter,
    MaximumContractPriceFilter,
    SortOrder,
} from '../FilterTypes'

type PriceInputProps = {
    value: string
    onChange: (value: string) => void
    placeholder?: string
}

const PriceInput: React.FC<PriceInputProps> = ({
    value,
    onChange,
    placeholder,
}) => {
    // Функция форматирования для отображения в поле ввода.
    // Форматирует целую часть с разделителем тысяч, оставляет введённую десятичную часть как есть.
    const formatNumber = (val: string): string => {
        if (!val) return ''
        // Убираем пробелы
        const rawVal = val.replace(/\s/g, '')
        const dotIndex = rawVal.indexOf('.')
        let integerPart = rawVal.split('.')[0].replace(/\D/g, '')
        // Если в числе несколько цифр и оно начинается с 0, удаляем лишние ведущие нули
        if (integerPart.length > 1 && integerPart.startsWith('0')) {
            integerPart = integerPart.replace(/^0+/, '') || '0'
        }
        // Форматируем целую часть с разделителем тысяч (пробел)
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        if (dotIndex !== -1) {
            const decimalPart = rawVal.split('.')[1] || ''
            return `${integerPart}.${decimalPart}`
        } else {
            return integerPart
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let input = e.target.value

        // Заменяем запятые на точки
        input = input.replace(/,/g, '.')

        // Если первым символом идёт точка, добавляем "0" перед ней
        if (input.startsWith('.')) {
            input = '0' + input
        }

        // Разрешаем ввод только цифр и точки
        input = input.replace(/[^0-9.]/g, '')

        // Разрешаем только одну точку
        const parts = input.split('.')
        if (parts.length > 2) {
            input = parts[0] + '.' + parts.slice(1).join('')
        }

        // Ограничиваем до 2 цифр после точки, если точка уже присутствует
        const updatedParts = input.split('.')
        if (updatedParts.length === 2 && updatedParts[1].length > 2) {
            updatedParts[1] = updatedParts[1].substring(0, 2)
            input = updatedParts[0] + '.' + updatedParts[1]
        }

        // Если число начинается с "0" и за ним идет цифра (а не точка), удаляем лишние ведущие нули
        if (input.length > 1 && input[0] === '0' && input[1] !== '.') {
            input = input.replace(/^0+/, '') || '0'
        }

        onChange(input)
    }

    // onBlur: когда поле теряет фокус, если есть точка, дополнить десятичную часть до 2 цифр.
    const handleBlur = (): void => {
        if (value.indexOf('.') !== -1) {
            const parts = value.split('.')
            const integerPart = parts[0]
            let decimalPart = parts[1] || ''
            decimalPart = decimalPart.substring(0, 2).padEnd(2, '0')
            onChange(integerPart + '.' + decimalPart)
        }
    }

    return (
        <input
            type="text"
            value={formatNumber(value)}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            className="form-input focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] w-full"
        />
    )
}

type Props = {
    stage: string
    criteriaMaximumContractPrice?: MaximumContractPriceFilter

    onClosePopup: (value: boolean) => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "НМЦД"
    onChangeFilterSettingCriteriaMaximumContractPriceSort: (
        value: SortOrder
    ) => void
    onChangeFilterSettingCriteriaMaximumContractPrice: (
        valueMin: number | null,
        valueMax: number | null
    ) => void
}

const PopupMenuMaximumContractPrice: React.FC<Props> = (props) => {
    const [priceMin, setPriceMin] = useState<string>(
        props.criteriaMaximumContractPrice?.min == null
            ? ''
            : String(props.criteriaMaximumContractPrice.min)
    )
    const [priceMax, setPriceMax] = useState<string>(
        props.criteriaMaximumContractPrice?.max == null
            ? ''
            : String(props.criteriaMaximumContractPrice.max)
    )

    // Флаг, указывающий, что пользователь изменил значения
    const [isModified, setIsModified] = useState(false)

    // Флаг для пропуска эффекта при первом рендере
    const isFirstRender = useRef(true)

    // Сохраняем исходные значения для проверки изменений
    const initialPricesRef = useRef({ priceMin, priceMax })

    const handlePrice1Change = (value: string) => {
        setPriceMin(value)
        setIsModified(true)
    }

    const handlePrice2Change = (value: string) => {
        setPriceMax(value)
        setIsModified(true)
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }
        // Если изменений нет или данные не отличаются от исходных – ничего не делаем
        if (
            !isModified ||
            (priceMin === initialPricesRef.current.priceMin &&
                priceMax === initialPricesRef.current.priceMax)
        ) {
            return
        }

        const timer = setTimeout(() => {
            props.onChangeFilterSettingCriteriaMaximumContractPrice(
                priceMin.trim() === '' ? null : Number(priceMin),
                priceMax.trim() === '' ? null : Number(priceMax)
            )
            // Обновляем сохранённые значения после успешного обновления
            initialPricesRef.current = { priceMin, priceMax }
            setIsModified(false)
        }, 1000)

        return () => clearTimeout(timer)
    }, [priceMin, priceMax, isModified])

    return (
        <div className="absolute left-0 my-2 w-fit origin-top-right bg-[#333033] border border-[#696769] rounded-[10px] shadow-lg z-10">
            <div className="p-1">
                {/* Блок с чекбоксами */}

                <div className="flex flex-row items-center">
                    <label className="flex flex-row items-center rounded-lg gap-1 w-44 ">
                        <PriceInput
                            value={priceMin}
                            onChange={handlePrice1Change} // Передаем правильную функцию
                            placeholder="Введите сумму"
                        />
                    </label>
                    <div className="flex w-fit h-full px-1 items-center justify-center text-[12.8px] text-white">
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="minus"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M19,11H5a1,1,0,0,0,0,2H19a1,1,0,0,0,0-2Z"
                            ></path>
                        </svg>
                    </div>
                    <label className="flex flex-row items-center rounded-lg gap-1 w-44">
                        <PriceInput
                            value={priceMax}
                            onChange={handlePrice2Change} // Передаем правильную функцию
                            placeholder="Введите сумму"
                        />
                    </label>
                </div>

                <div className="flex flex-row w-full px-4 text-[11px] text-left items-center justify-end mt-1">
                    <label
                        className="w-full text-[11px] text-right text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            handlePrice1Change('')
                            handlePrice2Change('')
                            setIsModified(true)
                            props.onChangeFilterSettingCriteriaMaximumContractPrice(
                                null,
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                {/* Разделитель */}
                <hr className="mt-3 mx-4 border-[#464447]" />

                <div className="flex flex-col w-full gap-1 mt-3">
                    {/* Заголовок сортировки и кнопки сортировки */}
                    <div className="px-4 text-[11px] text-left text-[#8A8A8A]">
                        Сортировка
                    </div>
                    {/* Разделитель */}
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaMaximumContractPriceSort(
                                'asc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="rotate(90 12 12)">
                                <path
                                    fill={`${props.criteriaMaximumContractPrice?.sort_order == 'asc' ? '#0284c7' : '#8A8A8A'}`}
                                    d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                ></path>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По возрастанию
                        </span>
                    </button>
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaMaximumContractPriceSort(
                                'desc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="translate(24,0) scale(-1,1)">
                                <g transform="rotate(-90 12 12)">
                                    <path
                                        fill={`${props.criteriaMaximumContractPrice?.sort_order == 'desc' ? '#0284c7' : '#8A8A8A'}`}
                                        d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По убыванию
                        </span>
                    </button>
                </div>

                <div className="flex w-full px-4 text-[11px] text-left items-center justify-end">
                    <label
                        className="w-fit text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaMaximumContractPriceSort(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <div className="flex flex-col w-full mt-3 items-start justify-center">
                    <button
                        className="flex h-6 flex-col w-full gap-1 text-left text-[12.8px] text-white rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            props.onAcceptFilter()
                            props.onClosePopup(false)
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupMenuMaximumContractPrice
