import React, { useState, useEffect, useRef } from 'react'
import './style.css'

type DataPoint = {
    label: string
    value: number
}

interface PlanChartProps {
    plan_guid: string
}

const LineChart: React.FC<PlanChartProps> = ({ plan_guid }) => {
    const [data, setData] = useState<DataPoint[]>([])
    const [hoveredPoint, setHoveredPoint] = useState<DataPoint | null>(null)
    const [tooltipPosition, setTooltipPosition] = useState<{
        x: number
        y: number
    } | null>(null)
    const [infoTooltip, setInfoTooltip] = useState<{
        x: number
        y: number
    } | null>(null)
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const pathRef = useRef<SVGPathElement | null>(null)
    const animationPlayed = useRef(false)

    useEffect(() => {
        if (plan_guid === 'Нет данных') {
            return
        }

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_positions_count_by_month/?plan_guid=${plan_guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`)
                }
                return response.json()
            })
            .then((result) => {
                const scaledResult = result.map((d: DataPoint) => ({
                    label: d.label,
                    value: d.value,
                }))
                setData(scaledResult)
            })
            .catch((error) => {
                console.error('Error fetching data:', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [plan_guid])

    useEffect(() => {
        if (!isLoading && data.length > 0 && !animationPlayed.current) {
            const pathElement = pathRef.current
            if (pathElement) {
                const length = pathElement.getTotalLength()
                pathElement.style.transition = 'none'
                pathElement.style.strokeDasharray = `${length} ${length}`
                pathElement.style.strokeDashoffset = `${length}`
                pathElement.getBoundingClientRect()
                pathElement.style.transition = 'stroke-dashoffset 2s ease'
                pathElement.style.strokeDashoffset = '0'
            }
            const circles =
                document.querySelectorAll<SVGCircleElement>('.line-point')
            circles.forEach((circle, index) => {
                circle.style.opacity = '0'
                circle.style.transition = `opacity 0.4s ease ${(index + 1) * 0.1}s`
                setTimeout(() => {
                    circle.style.opacity = '1'
                }, 100) // Wait for line animation to complete before animating circles
            })
            animationPlayed.current = true
        }
    }, [isLoading, data])

    const width = 800
    const height = 340

    const offsetXLeft = 50
    const offsetXRight = 50

    const padding = 40
    const paddingY = 30

    const maxDataValue =
        Math.ceil((Math.max(...data.map((d) => d.value)) + 50) / 50) * 50
    const scaleX = (index: number) =>
        paddingY +
        offsetXLeft +
        (index * (width - 2 * paddingY - offsetXLeft - offsetXRight)) /
            (data.length - 1)
    const scaleY = (value: number) =>
        height - padding - (value * (height - 2 * padding)) / maxDataValue

    const pathD = data
        .map(
            (point, i) =>
                `${i === 0 ? 'M' : 'L'} ${scaleX(i)} ${scaleY(point.value)}`
        )
        .join(' ')

    return (
        <>
            <div className="flex flex-col w-full h-full relative">
                <div className="flex flex-row text-white text-[16px]">
                    <div className="flex flex-1">Количество закупок (ед.)</div>
                    <div
                        onMouseEnter={(e) =>
                            setInfoTooltip({
                                x: e.clientX,
                                y: e.clientY,
                            })
                        }
                        onMouseLeave={() => setInfoTooltip(null)}
                        style={{ cursor: 'pointer' }}
                    >
                        <button className="w-5 h-5">
                            <svg
                                id="info-circle"
                                data-name="Layer 1"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="#FFF"
                                    d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                                ></path>
                            </svg>
                        </button>
                    </div>
                </div>
                {plan_guid == 'Нет данных' ? (
                    <div className="flex text-white text-[16px] w-full h-full items-center justify-center">
                        Нет данных
                    </div>
                ) : (
                    <>
                        {isLoading ? (
                            <div className="flex flex-row text-white text-[16px] w-full h-full items-center justify-center gap-2">
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                                <span>Загрузка данных</span>
                            </div>
                        ) : (
                            <>
                                <div className="flex flex-row relative">
                                    <div className="flex items-center">
                                        <svg
                                            width={width}
                                            height={height}
                                            className="flex w-full h-full"
                                            viewBox={`0 0 ${width} ${height}`}
                                            onMouseLeave={() => {
                                                setHoveredPoint(null)
                                                setTooltipPosition(null)
                                            }}
                                        >
                                            {/* Сетка */}
                                            {[
                                                ...Array(
                                                    Math.ceil(
                                                        maxDataValue / 50
                                                    ) + 1
                                                ),
                                            ].map((_, i) => (
                                                <line
                                                    key={`grid-line-${i}`}
                                                    x1={padding}
                                                    y1={scaleY(50 * i)}
                                                    x2={width - padding}
                                                    y2={scaleY(50 * i)}
                                                    stroke="gray"
                                                    strokeOpacity="0.2"
                                                />
                                            ))}
                                            {data.map((_, i) => (
                                                <line
                                                    key={`grid-line-x-${i}`}
                                                    x1={scaleX(i)}
                                                    y1={height - padding}
                                                    x2={scaleX(i)}
                                                    y2={padding}
                                                    stroke="gray"
                                                    strokeOpacity="0.2"
                                                />
                                            ))}

                                            {/* Оси и подписи */}
                                            {/* Ось Y */}
                                            <line
                                                x1={padding}
                                                y1={height - padding}
                                                x2={padding}
                                                y2={padding}
                                                stroke="#464447"
                                                strokeWidth={2}
                                            />
                                            {/* Ось X */}
                                            <line
                                                x1={padding}
                                                y1={height - padding}
                                                x2={width - padding}
                                                y2={height - padding}
                                                stroke="#464447"
                                                strokeWidth={2}
                                            />

                                            {/* Подписи для оси X */}
                                            {data.map((point, i) => (
                                                <g key={i}>
                                                    <line
                                                        x1={scaleX(i)}
                                                        y1={
                                                            height - padding - 5
                                                        }
                                                        x2={scaleX(i)}
                                                        y2={
                                                            height - padding + 5
                                                        }
                                                        stroke="#464447"
                                                        strokeWidth={2}
                                                    />
                                                    <text
                                                        x={scaleX(i)}
                                                        y={
                                                            height -
                                                            padding +
                                                            20
                                                        }
                                                        textAnchor="middle"
                                                        fontSize={10}
                                                        fill="#FFF"
                                                    >
                                                        {point.label}
                                                    </text>
                                                </g>
                                            ))}

                                            {/* Подписи для оси Y */}
                                            {[
                                                ...Array(
                                                    Math.ceil(
                                                        maxDataValue / 50
                                                    ) + 1
                                                ),
                                            ].map((_, i) => (
                                                <g key={i}>
                                                    <line
                                                        x1={padding - 5}
                                                        y1={scaleY(50 * i)}
                                                        x2={padding + 5}
                                                        y2={scaleY(50 * i)}
                                                        stroke="#464447"
                                                        strokeWidth={2}
                                                    />
                                                    <text
                                                        x={padding - 10}
                                                        y={scaleY(50 * i) + 4}
                                                        textAnchor="end"
                                                        fontSize={10}
                                                        fill="#FFF"
                                                    >
                                                        {50 * i}
                                                    </text>
                                                </g>
                                            ))}

                                            {/* Линия графика */}
                                            {data.length > 0 && (
                                                <path
                                                    ref={pathRef}
                                                    id="line-path"
                                                    d={pathD}
                                                    fill="none"
                                                    stroke="#649A3B"
                                                    strokeWidth={2}
                                                    style={{
                                                        strokeDasharray: 'none',
                                                        strokeDashoffset: '0',
                                                    }}
                                                />
                                            )}

                                            {/* Точки */}
                                            {data.map((point, i) => (
                                                <circle
                                                    key={i}
                                                    cx={scaleX(i)}
                                                    cy={scaleY(point.value)}
                                                    r={
                                                        hoveredIndex === i
                                                            ? 7
                                                            : 5
                                                    }
                                                    fill={
                                                        hoveredIndex === i
                                                            ? '#a2c685'
                                                            : '#649A3B'
                                                    }
                                                    style={{
                                                        opacity: 0,
                                                    }}
                                                    className="line-point"
                                                    onMouseEnter={() => {
                                                        setHoveredPoint(point)
                                                        setHoveredIndex(i)
                                                    }}
                                                    onMouseMove={(e) =>
                                                        setTooltipPosition({
                                                            x: e.clientX,
                                                            y: e.clientY,
                                                        })
                                                    }
                                                    onMouseLeave={() => {
                                                        setHoveredPoint(null)
                                                        setHoveredIndex(null)
                                                    }}
                                                />
                                            ))}
                                        </svg>

                                        {/* Tooltip */}
                                        {hoveredPoint && tooltipPosition && (
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    left:
                                                        tooltipPosition.x - 17,
                                                    top: tooltipPosition.y - 50,
                                                    backgroundColor:
                                                        '#464447CC',
                                                    padding: '5px',
                                                    borderRadius: '5px',
                                                    pointerEvents: 'none',
                                                    whiteSpace: 'nowrap',
                                                    color: '#FFF',
                                                    fontSize: 14,
                                                }}
                                            >
                                                {hoveredPoint.value}
                                            </div>
                                        )}
                                        {infoTooltip && (
                                            <div
                                                className="flex flex-col w-[400px] p-2"
                                                style={{
                                                    position: 'fixed',
                                                    left: infoTooltip.x - 420,
                                                    top: infoTooltip.y - 10,
                                                    backgroundColor: '#464447',
                                                    border: '1px solid gray',
                                                    borderRadius: '5px',
                                                    color: '#FFF',
                                                    fontSize: 12.8,
                                                }}
                                            >
                                                Это график количества закупок по
                                                месяцам.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </>
    )
}

export default LineChart
;<style>
    {`
    @keyframes drawLine {
      from {
        stroke-dasharray: 0, 1000;
      }
      to {
        stroke-dasharray: 1000, 1000;
      }
    }
    .animate-line {
      animation: drawLine 1s ease forwards;
      stroke-dasharray: 1000;
    }
    .line-point {
      animation: fadeInPoint 1s ease forwards;
    }
    @keyframes fadeInPoint {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `}
</style>
