import React, { useState } from 'react'
import Section from '../../../../../../components/Section'
import Checkbox from '../../../../../../components/form/Checkbox'
import { PurchaseChangeInfoErrors } from '../types'

type Props = {
    changedGWSAndDates: boolean | null
    onChangedGWSAndDatesChange: (value: boolean | null) => void
    changedNMSKMoreTenPercent: boolean | null
    onChangedNMSKMoreTenPercentChange: (value: boolean | null) => void
    otherChanges: boolean | null
    onOtherChangesChange: (value: boolean | null) => void
    modificationDescription: string | null
    onModificationDescriptionChange: (value: string | null) => void
    errors?: PurchaseChangeInfoErrors
}

const PurchaseChangeInfo: React.FC<Props> = (props) => {
    return (
        <Section title="Информация об изменении позиции плана закупки">
            <div className={`flex flex-col gap-3 w-[1220px]`}>
                <div
                    className={`mt-5 flex flex-col space-y-3 ${
                        props.errors?.changeReason
                            ? 'border border-[#C15C5C] rounded-md p-2'
                            : ''
                    }`}
                    title={props.errors?.changeReason}
                >
                    <Checkbox
                        label="Изменение потребности в товарах, работах, услугах, в том числе сроков их приобретения, способа осуществления закупки и срока исполнения договора"
                        checked={props?.changedGWSAndDates || false}
                        onChange={props.onChangedGWSAndDatesChange}
                        disabled={false}
                    />

                    <Checkbox
                        label="Изменение более чем на 10 процентов стоимости планируемых к приобретению товаров (работ, услуг), выявленного в результате подготовки к процедуре проведения конкретной закупки, вследствие чего невозможно осуществление закупки в соответствии с планируемым объемом денежных средств, предусмотренным планом закупки"
                        checked={props?.changedNMSKMoreTenPercent || false}
                        onChange={props.onChangedNMSKMoreTenPercentChange}
                        disabled={false}
                    />

                    <Checkbox
                        label="Иной случай, установленный положением о закупке и другими документами заказчика"
                        checked={props?.otherChanges || false}
                        onChange={() => {
                            props.onOtherChangesChange(!props.otherChanges)
                            props.onModificationDescriptionChange(null)
                        }}
                        disabled={false}
                    />
                </div>
                {props.otherChanges && (
                    <div className="flex flex-col">
                        <label className="mb-1 text-sm text-zinc-500">
                            Обоснование внесения изменений
                        </label>
                        <textarea
                            className={`form-textarea ${
                                props.errors?.modification_description
                                    ? 'border border-[#C15C5C]'
                                    : ''
                            }`}
                            title={props.errors?.modification_description}
                            maxLength={1000}
                            rows={2}
                            value={props.modificationDescription || ''}
                            onChange={(e) =>
                                props.onModificationDescriptionChange(
                                    e.target.value
                                )
                            }
                        />
                    </div>
                )}
            </div>
        </Section>
    )
}

export default PurchaseChangeInfo
