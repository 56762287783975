import React, { useState, useEffect } from 'react'
import { useAuth } from '../../../../hooks/auth'
import { getUserData } from '../../../../services/auth'
import { Navigate, useLoaderData } from 'react-router-dom'
import { Organization, BankDetails } from './types'
import './style.css'

interface Check {
    status: boolean
}

export const loadOrganizationData = async (): Promise<Organization> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const fetchBankDetails = async (): Promise<BankDetails[]> => {
    try {
        const response = await fetch(
            process.env.REACT_APP_API_URL + '/api/backend/v1/bank_details',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response.ok) {
            throw new Error('Failed to fetch bank details')
        }

        return await response.json()
    } catch (error) {
        console.error('Error fetching bank details:', error)
        throw error // Проброс ошибки, если это нужно обработать выше
    }
}

const checkIntegration = async (): Promise<Check> => {
    return fetch(
        process.env.REACT_APP_API_URL +
            '/api/backend/v1/organization/integration_check',
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({}), // Добавлено тело запроса
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch integration check')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgHomePage: React.FC = () => {
    const { user, logout } = useAuth()
    const [roleName, setRoleName] = useState<string | null>(null)

    const organizationLoad = useLoaderData() as Organization

    const [bankDetails, setBankDetails] = useState<BankDetails[]>([])
    useEffect(() => {
        const loadData = async () => {
            const data = await fetchBankDetails()
            setBankDetails(data) // Убедитесь, что `data` — массив
        }
        loadData()
        const handleUserData = async () => {
            const userData = await getUserData()
            if (userData) {
                setRoleName(userData.u_role) // Сохраняем u_role в state
            }
        }
        handleUserData()
    }, [])
    const [addBankDetailsPopup, setAddBankDetailsPopup] = useState(false)
    const [deleteBankDetailsPopup, setDeleteBankDetailsPopup] = useState(false)
    const [selectedBankDetailsGuid, setSelectedBankDetailsGuid] = useState<
        string | null
    >(null) // Добавлено состояние
    const [touchedInputAddBankDetails, setTouchedInputAddBankDetails] =
        useState<{
            [key: string]: boolean
        }>({})
    const [errorsInputAddBankDetails, setErrorsInputAddBankDetails] = useState<{
        [key: string]: string
    }>({})
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [bankDetailsUpdate, setBankDetailsUpdate] =
        useState<BankDetails | null>(null)
    const [isMainBankDetails, setIsMainBankDetails] = useState(
        bankDetailsUpdate?.main_account ? true : false
    )

    const [organizationData, setOrganizationData] =
        useState<Organization>(organizationLoad)
    const [organizationDataUpdate, setOrganizationDataUpdate] =
        useState(organizationData)

    const [editMode, setEditMode] = useState(false)
    const [isSaving, setIsSaving] = useState(false) // Состояние для анимации загрузки

    const [touchedInput, setTouchedInput] = useState<{
        [key: string]: boolean
    }>({})
    const [errorsInput, setErrorsInput] = useState<{ [key: string]: string }>(
        {}
    )

    const [isKppBigOn, setIsKppBigOn] = useState(
        organizationLoad.kpp_big ? true : false
    )

    const [
        isNotPlacementPurchaseCustomerOn,
        setIsNotPlacementPurchaseCustomerOn,
    ] = useState(
        organizationLoad.not_placement_purchase_customer ? true : false
    )
    const [isNmcdWithTaxDefault, setIsNmcdWithTaxDefault] = useState(
        organizationLoad.is_nmcd_with_tax_default ? true : false
    )
    const [isComplianceSMB, setComplianceSMB] = useState(
        organizationLoad.is_compliance_smb ? true : false
    )
    const [isAllOkpd2, setAllOkpd2] = useState(
        organizationLoad.all_okpd2 ? true : false
    )
    const [isAllPurchaseMethod, setAllPurchaseMethod] = useState(
        organizationLoad.all_purchase_method ? true : false
    )

    const [isCheck, setIsCheck] = useState('no')
    const [isChecking, setIsChecking] = useState(false) // Состояние для анимации проверки
    const [сheckingRes, setCheckingRes] = useState(false)

    const integrationCheck = async () => {
        setIsChecking(true)
        try {
            const result = await checkIntegration()
            setCheckingRes(result.status)
        } catch (e) {
            console.log('Check itegration error')
        } finally {
            setIsCheck('yes')
            setIsChecking(false)
        }
    }

    // Валидация полей
    const validateField = (fieldName: keyof Organization, value: string) => {
        if (
            [
                'full_name',
                'short_name',
                'inn',
                'kpp',
                'ogrn',
                'kpp_big',
                'phone',
            ].includes(fieldName)
        ) {
            let error = ''

            if (!value) {
                error = 'Поле обязательно к заполнению'
            }

            if (fieldName === 'inn' && !/^\d{10}$/.test(value)) {
                error = 'Минимум 10 символов'
            } else if (fieldName === 'kpp' && !/^\d{9}$/.test(value)) {
                error = 'Минимум 9 символов'
            } else if (fieldName === 'ogrn' && !/^\d{13}$/.test(value)) {
                error = 'Минимум 13 символов'
            } else if (
                fieldName === 'kpp_big' &&
                isKppBigOn &&
                !/^\d{9}$/.test(value)
            ) {
                error = 'Минимум 9 символов'
            }
            setErrorsInput((prev) => ({ ...prev, [fieldName]: error }))
        }
    }

    // Обработчик для изменения поля
    const handleInputChange = (
        fieldName: keyof Organization,
        value: string | null
    ) => {
        if (typeof value === 'string') {
            if (
                [
                    'inn',
                    'kpp',
                    'ogrn',
                    'kpp_big',
                    'okpo',
                    'okato',
                    'oktmo',
                ].includes(fieldName)
            ) {
                value = value.replace(/\D/g, '') // Удаляем все нецифровые символы
                validateField(fieldName, value)
            }
        }
        if (['registration_date'].includes(fieldName)) {
            if (!value) {
                value = null
            }
        }

        setOrganizationDataUpdate((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const handleBlur = (fieldName: keyof Organization) => {
        setTouchedInput((prev) => ({ ...prev, [fieldName]: true }))
        validateField(
            fieldName,
            organizationDataUpdate[fieldName]?.toString() || ''
        )
    }

    const getBorderStyle = (fieldName: keyof Organization) => {
        const isTouched = touchedInput[fieldName]
        const hasError = errorsInput[fieldName]
        return isTouched && hasError ? '1px solid #C15C5C' : ''
    }

    // Проверяем, есть ли ошибки
    const hasErrors = () => {
        return Object.values(errorsInput).some((error) => error !== '')
    }

    const handleToggleIsKppBigOn = () => {
        if (editMode) {
            setIsKppBigOn((prevState) => !prevState)
            if (isKppBigOn) {
                setErrorsInput((prev) => ({ ...prev, ['kpp_big']: '' }))
            } else {
                handleBlur('kpp_big')
            }
        }
    }

    const handleToggleIsNotPlacementPurchaseCustomerOn = () => {
        if (editMode) {
            setIsNotPlacementPurchaseCustomerOn((prevState) => !prevState)
            setOrganizationDataUpdate((prev) => ({
                ...prev,
                ['not_placement_purchase_customer']:
                    !isNotPlacementPurchaseCustomerOn,
            }))
        }
    }

    const handleToggleIsNmcdWithTaxDefault = () => {
        if (editMode) {
            setIsNmcdWithTaxDefault((prevState) => !prevState)
            setOrganizationDataUpdate((prev) => ({
                ...prev,
                ['is_nmcd_with_tax_default']: !isNmcdWithTaxDefault,
            }))
        }
    }

    const handleToggleIsComplianceSMB = () => {
        if (editMode) {
            setComplianceSMB((prevState) => !prevState)
            setOrganizationDataUpdate((prev) => ({
                ...prev,
                ['is_compliance_smb']: !isComplianceSMB,
            }))
        }
    }

    const handleToggleIsAllOkpd2 = () => {
        if (editMode) {
            setAllOkpd2((prevState) => !prevState)
            setOrganizationDataUpdate((prev) => ({
                ...prev,
                ['all_okpd2']: !isAllOkpd2,
            }))
        }
    }

    const handleToggleIsAllPurchaseMethod = () => {
        if (editMode) {
            setAllPurchaseMethod((prevState) => !prevState)
            setOrganizationDataUpdate((prev) => ({
                ...prev,
                ['all_purchase_method']: !isAllPurchaseMethod,
            }))
        }
    }

    const handleEditMode = () => {
        setEditMode(true)
    }

    const handleCloseOrganization = async () => {
        try {
            setOrganizationDataUpdate(organizationData) // Обновляем состояние
            if (organizationData.kpp_big) {
                setIsKppBigOn(true)
            } else {
                setIsKppBigOn(false)
            }

            if (organizationData.not_placement_purchase_customer) {
                setIsNotPlacementPurchaseCustomerOn(true)
            } else {
                setIsNotPlacementPurchaseCustomerOn(false)
            }

            if (organizationData.is_nmcd_with_tax_default) {
                setIsNmcdWithTaxDefault(true)
            } else {
                setIsNmcdWithTaxDefault(false)
            }

            setErrorsInput({})
            setEditMode(false) // Выходим из режима редактирования
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error)
        }
    }

    const handleSaveOrganization = async () => {
        setIsSaving(true)

        let organizationDataUpdateKppBig = organizationDataUpdate

        if (!isKppBigOn) {
            // Локально обновляем объект до выполнения запроса
            organizationDataUpdateKppBig = {
                ...organizationDataUpdate,
                kpp_big: '',
            }
            setOrganizationDataUpdate(organizationDataUpdateKppBig) // Обновляем состояние
        }

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/backend/v1/organization`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(organizationDataUpdateKppBig),
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при сохранении')
            }
            console.log('Успешно сохранено')
            setOrganizationData(organizationDataUpdateKppBig) // Обновляем состояние с актуальными данными
            setOrganizationDataUpdate(organizationDataUpdateKppBig)
            setEditMode(false) // Выходим из режима редактирования
        } catch (error) {
            console.error('Ошибка сохранения:', error)
        } finally {
            setIsSaving(false)
        }
    }

    // Валидация полей Банковских реквизитов
    const validateBankDetailsField = (
        fieldName: keyof BankDetails,
        value: string
    ) => {
        if (
            [
                'bank_checking_account',
                'bank_correspondent_account',
                'bank_bik',
                'bank_name',
                'bank_address',
            ].includes(fieldName)
        ) {
            let error = ''

            if (!value) {
                error = 'Поле обязательно к заполнению'
            }

            if (
                fieldName === 'bank_checking_account' &&
                !/^\d{20}$/.test(value)
            ) {
                error = 'Минимум 20 символов'
            } else if (
                fieldName === 'bank_correspondent_account' &&
                !/^\d{20}$/.test(value)
            ) {
                error = 'Минимум 20 символов'
            } else if (fieldName === 'bank_bik' && !/^\d{9}$/.test(value)) {
                error = 'Минимум 9 символов'
            }
            setErrorsInputAddBankDetails((prev) => ({
                ...prev,
                [fieldName]: error,
            }))
        }
    }

    // Обработчик для изменения поля
    const handleInputChangeAddBankDetails = (
        fieldName: keyof BankDetails,
        value: string | null
    ) => {
        if (typeof value === 'string') {
            if (
                [
                    'bank_checking_account',
                    'bank_correspondent_account',
                    'bank_bik',
                ].includes(fieldName)
            ) {
                value = value.replace(/\D/g, '') // Удаляем все нецифровые символы
                validateBankDetailsField(fieldName, value)
            }
        }

        setBankDetailsUpdate(
            (prev) =>
                ({
                    ...prev,
                    [fieldName]: value,
                }) as BankDetails
        )
    }

    const handleBlurBankDetails = (fieldName: keyof BankDetails) => {
        setTouchedInputAddBankDetails((prev) => ({
            ...prev,
            [fieldName]: true,
        }))
        const fieldValue = bankDetailsUpdate?.[fieldName] // Получаем значение поля

        validateBankDetailsField(
            fieldName,
            fieldValue !== undefined && fieldValue !== null
                ? fieldValue.toString()
                : '' // Преобразуем в строку, если значение существует
        )
    }

    const getBorderStyleBankDetails = (fieldName: keyof BankDetails) => {
        const isTouched = touchedInputAddBankDetails[fieldName]
        const hasError = errorsInputAddBankDetails[fieldName]
        return isTouched && hasError ? '1px solid #C15C5C' : ''
    }

    // Проверяем, есть ли ошибки
    const hasErrorsBankDetails = () => {
        return Object.values(errorsInputAddBankDetails).some(
            (error) => error !== ''
        )
    }

    const handleToggleIsMainBankDetails = () => {
        setIsMainBankDetails((prevState) => !prevState)
        setBankDetailsUpdate((prev) => {
            if (!prev) return null // Если предыдущее состояние null, возвращаем null
            return {
                ...prev,
                ['main_account']: !isMainBankDetails, // Обновляем значение main_account
            }
        })
    }

    const handleAddBankDetailsPopup = (bank_guid: string | null = null) => {
        setErrorsInputAddBankDetails({})
        setAddBankDetailsPopup(!addBankDetailsPopup)
        setSelectedBankDetailsGuid(bank_guid)

        if (selectedBankDetailsGuid == null) {
            setBankDetailsUpdate(null)
            setIsMainBankDetails(false)
        }
        const foundUser = bankDetails.find((u) => u.guid === bank_guid)
        setBankDetailsUpdate(foundUser || null)
        setIsMainBankDetails(foundUser?.main_account ? true : false)
    }

    const handleAddBankDetails = async () => {
        setIsAdding(true) // Включаем анимацию загрузки

        if (selectedBankDetailsGuid !== null) {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/bank_details/${selectedBankDetailsGuid}`,
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(bankDetailsUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при изменении реквизитов')
                }

                console.log('Реквизиты успешно изменены')
                setAddBankDetailsPopup(false)
            } catch (error) {
                console.error('Ошибка при изменении реквизитов:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        } else {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/bank_details`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(bankDetailsUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при добавлении реквизитов')
                }

                console.log('Реквизиты успешно добавлены')
                setAddBankDetailsPopup(false)
            } catch (error) {
                console.error('Ошибка при добавлении реквизитов:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
        }
        const data = await fetchBankDetails()
        setBankDetails(data)
    }

    const handleDeleteBankDetailsPopup = (bank_guid: string | null = null) => {
        setDeleteBankDetailsPopup(!deleteBankDetailsPopup)

        if (bank_guid) {
            setSelectedBankDetailsGuid(bank_guid)
        }
    }

    const handleDeleteBankDetails = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки
        if (!selectedBankDetailsGuid) return

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/bank_details/${selectedBankDetailsGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении')
            }
            setDeleteBankDetailsPopup(false)
            setBankDetails((prevBankDetails) =>
                prevBankDetails.filter(
                    (bank) => bank.guid !== selectedBankDetailsGuid
                )
            )
            setSelectedBankDetailsGuid(null)
            console.log('Реквизиты успешно удалены')
        } catch (error) {
            console.error('Ошибка удаления реквизитов:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    if (!user) {
        return <Navigate to="/auth" />
    }

    return (
        <div className="flex flex-col text-white h-full">
            <div className="bg-[#111111]">
                <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-20 my-auto content-center ">
                        <p className="text-sm text-white">
                            Настройки организации
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex flex-1 justify-center max-h-[calc(100vh_-_120px)] overflow-y-auto py-5">
                <div className="flex flex-col h-max w-[800px] space-y-10">
                    {/* Моя организация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Моя организация</p>
                        </div>

                        <div className="flex flex-row space-x-5">
                            <div className="flex items-start">
                                <div className="h-[100px] w-[100px] grid bg-[#181818] rounded-full place-items-center">
                                    <div className="flex flex-col items-center">
                                        <button className="w-6 h-6">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="image-plus"
                                            >
                                                <path
                                                    fill="#8A8A8A"
                                                    d="M19,10a1,1,0,0,0-1,1v3.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.71L9.41,11.12a2.79,2.79,0,0,0-3.93,0L4,12.61V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19.22A2.79,2.79,0,0,0,4.78,22H17.22a2.88,2.88,0,0,0,.8-.12h0a2.74,2.74,0,0,0,2-2.65V11A1,1,0,0,0,19,10ZM5,20a1,1,0,0,1-1-1V15.43l2.89-2.89a.78.78,0,0,1,1.1,0L15.46,20Zm13-1a1,1,0,0,1-.18.54L13.3,15l.71-.7a.77.77,0,0,1,1.1,0L18,17.21ZM21,4H20V3a1,1,0,0,0-2,0V4H17a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2Z"
                                                ></path>
                                            </svg>
                                        </button>
                                        <p className="text-[#8A8A8A] text-xs">
                                            Загрузить
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full space-y-3">
                                {/* Полное наименование */}
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Полное наименование{' '}
                                        </label>
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            className={`org-input`}
                                            name="full_name"
                                            style={{
                                                colorScheme: 'dark',
                                                border: getBorderStyle(
                                                    'full_name'
                                                ),
                                            }}
                                            value={
                                                organizationDataUpdate.full_name ||
                                                ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'full_name',
                                                    e.target.value
                                                )
                                            }
                                            onBlur={() =>
                                                handleBlur('full_name')
                                            }
                                            placeholder="Полное наименование"
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                {/* Сокращенное наименование, Фирменное наименование */}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Сокращенное наименование{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                name="short_name"
                                                style={{
                                                    colorScheme: 'dark',
                                                    border: getBorderStyle(
                                                        'short_name'
                                                    ),
                                                }}
                                                value={
                                                    organizationDataUpdate.short_name ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'short_name',
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlur('short_name')
                                                }
                                                placeholder="Сокращенное наименование"
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* ИНН, КПП, ОГРН*/}
                                <div className="flex flex-row w-full space-x-5">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                ИНН{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                name="inn"
                                                style={{
                                                    colorScheme: 'dark',
                                                    border: getBorderStyle(
                                                        'inn'
                                                    ),
                                                }}
                                                value={
                                                    organizationDataUpdate.inn ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'inn',
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={() => handleBlur('inn')}
                                                placeholder="ИНН"
                                                disabled={true}
                                                maxLength={10}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                КПП{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                name="kpp"
                                                style={{
                                                    colorScheme: 'dark',
                                                    border: getBorderStyle(
                                                        'kpp'
                                                    ),
                                                }}
                                                value={
                                                    organizationDataUpdate.kpp ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'kpp',
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={() => handleBlur('kpp')}
                                                placeholder="КПП"
                                                disabled={true}
                                                maxLength={9}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                ОГРН{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                name="ogrn"
                                                style={{
                                                    colorScheme: 'dark',
                                                    border: getBorderStyle(
                                                        'ogrn'
                                                    ),
                                                }}
                                                value={
                                                    organizationDataUpdate.ogrn ||
                                                    ''
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'ogrn',
                                                        e.target.value
                                                    )
                                                }
                                                onBlur={() =>
                                                    handleBlur('ogrn')
                                                }
                                                placeholder="ОГРН"
                                                disabled={true}
                                                maxLength={13}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Дата постановки на учет */}
                                <div className="flex flex-row w-full space-x-5">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Дата постановки на учет{' '}
                                            </label>
                                        </div>
                                        <div className="flex w-full space-x-5 ">
                                            <div className="flex flex-1 w-full ">
                                                <input
                                                    type="date"
                                                    className="org-input"
                                                    name="registration_date"
                                                    style={{
                                                        colorScheme: 'dark',
                                                        border: getBorderStyle(
                                                            'registration_date'
                                                        ),
                                                    }}
                                                    value={
                                                        organizationDataUpdate.registration_date ||
                                                        ''
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'registration_date',
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleBlur(
                                                            'registration_date'
                                                        )
                                                    }
                                                    disabled={!editMode}
                                                />
                                            </div>
                                            <div className="flex flex-1 w-full"></div>
                                            <div className="flex flex-1 w-full"></div>
                                        </div>
                                    </div>
                                </div>

                                {/* Имеется КПП крупнейшего налогоплательщика */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsKppBigOn
                                                    }
                                                    className={`switch ${isKppBigOn ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isKppBigOn
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isKppBigOn ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsKppBigOn
                                                    }
                                                >
                                                    Имеется КПП крупнейшего
                                                    налогоплательщика
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* КПП крупнейшего налогоплательщика */}
                                {!isKppBigOn ? (
                                    <></>
                                ) : (
                                    <div className="flex flex-row w-full space-x-5 ">
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    КПП крупнейшего
                                                    налогоплательщика{' '}
                                                    {editMode && (
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                            <div className="flex w-full space-x-5 ">
                                                <div className="flex flex-1 w-full ">
                                                    <input
                                                        className="org-input"
                                                        name="kpp_big"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'kpp_big'
                                                            ),
                                                        }}
                                                        value={
                                                            organizationDataUpdate.kpp_big ||
                                                            ''
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'kpp_big',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'kpp_big'
                                                            )
                                                        }
                                                        placeholder="КПП крупнейшего"
                                                        disabled={!editMode}
                                                        maxLength={9}
                                                    />
                                                </div>
                                                <div className="flex flex-1 w-full"></div>
                                                <div className="flex flex-1 w-full"></div>
                                            </div>
                                            {touchedInput.kpp_big &&
                                                errorsInput.kpp_big && (
                                                    <span
                                                        style={{
                                                            color: '#C15C5C',
                                                            fontSize: '12.8px',
                                                        }}
                                                    >
                                                        {errorsInput.kpp_big}
                                                    </span>
                                                )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Общая информация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Общая информация</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Идентификационный код заказчика, Часовой пояс */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Идентификационный код заказчика
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={organizationDataUpdate.iko}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'iko',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Часовой пояс
                                        </label>
                                    </div>
                                    <div>
                                        <select
                                            className="org-input"
                                            value={
                                                organizationDataUpdate.timezone
                                            } // Устанавливаем выбранное значение
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'timezone',
                                                    e.target.value
                                                )
                                            }
                                            disabled={!editMode}
                                        >
                                            <option value="-1">МСК -1</option>
                                            <option value="0">МСК</option>
                                            <option value="1">МСК +1</option>
                                            <option value="2">МСК +2</option>
                                            <option value="3">МСК +3</option>
                                            <option value="4">МСК +4</option>
                                            <option value="5">МСК +5</option>
                                            <option value="6">МСК +6</option>
                                            <option value="7">МСК +7</option>
                                            <option value="8">МСК +8</option>
                                            <option value="9">МСК +9</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            {/* Юридический адрес */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Юридический адрес{' '}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input"
                                        style={{
                                            colorScheme: 'dark',
                                        }}
                                        value={
                                            organizationDataUpdate.legal_address
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                'legal_address',
                                                e.target.value
                                            )
                                        }
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            {/* Почтовый адрес */}
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                        Почтовый адрес{' '}
                                    </label>
                                </div>
                                <div>
                                    <input
                                        className="org-input"
                                        style={{
                                            colorScheme: 'dark',
                                        }}
                                        value={
                                            organizationDataUpdate.postal_address
                                        }
                                        onChange={(e) =>
                                            handleInputChange(
                                                'postal_address',
                                                e.target.value
                                            )
                                        }
                                        disabled={!editMode}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Классификационные признаки */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Классификационные признаки</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* ОГРН, ОКПО, ОКАТО, ОКТМО */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКПО{' '}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={organizationDataUpdate.okpo}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okpo',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКАТО
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={organizationDataUpdate.okato}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okato',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1 ">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКТМО
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={
                                                organizationDataUpdate.oktmo ??
                                                ''
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'oktmo',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ОКФС, Наименование */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКФС
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={organizationDataUpdate.okfs}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okfs',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-3/4 space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Наименование
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={
                                                organizationDataUpdate.okfs_name
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okfs_name',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* ОКОПФ, Наименование */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            ОКОПФ{' '}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={organizationDataUpdate.okopf}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okopf',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-3/4 space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Наименование
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value={
                                                organizationDataUpdate.okopf_name
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'okopf_name',
                                                    e.target.value
                                                )
                                            }
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Контактная информация */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Контактная информация</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Контактный телефон , Примечание */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Контактный телефон{' '}
                                            {editMode && (
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                                border: getBorderStyle('phone'),
                                            }}
                                            value={organizationDataUpdate.phone}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'phone',
                                                    e.target.value
                                                )
                                            }
                                            onBlur={() => handleBlur('phone')}
                                            disabled={!editMode}
                                        />
                                        {touchedInput.phone &&
                                            errorsInput.phone && (
                                                <span
                                                    style={{
                                                        color: '#C15C5C',
                                                        fontSize: '12.8px',
                                                    }}
                                                >
                                                    {errorsInput.phone}
                                                </span>
                                            )}
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Примечание
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value=""
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Адрес электронной почты , Примечание */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Адрес электронной почты{' '}
                                            {editMode && (
                                                <span className="text-[#FF0000]">
                                                    *
                                                </span>
                                            )}
                                        </label>
                                    </div>
                                    <div className="flex flex-col">
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                                border: getBorderStyle('email'),
                                            }}
                                            value={organizationDataUpdate.email}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'email',
                                                    e.target.value
                                                )
                                            }
                                            onBlur={() => handleBlur('email')}
                                            disabled={!editMode}
                                        />
                                        {touchedInput.email &&
                                            errorsInput.email && (
                                                <span
                                                    style={{
                                                        color: '#C15C5C',
                                                        fontSize: '12.8px',
                                                    }}
                                                >
                                                    {errorsInput.email}
                                                </span>
                                            )}
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Примечание
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            style={{
                                                colorScheme: 'dark',
                                            }}
                                            value=""
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Руководитель */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Руководитель</p>
                        </div>

                        <div className="flex flex-col w-full space-y-3">
                            {/* Должность */}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Должность{' '}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            value={
                                                organizationDataUpdate.head_job_title
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'head_job_title',
                                                    e.target.value
                                                )
                                            }
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full"></div>
                                <div className="flex flex-col w-full"></div>
                            </div>

                            {/* Фамилия , Имя , Отчество (при наличии)*/}
                            <div className="flex flex-row w-full space-x-5 ">
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Фамилия{' '}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            value={
                                                organizationDataUpdate.head_last_name
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'head_last_name',
                                                    e.target.value
                                                )
                                            }
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Имя{' '}
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            value={
                                                organizationDataUpdate.head_first_name
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'head_first_name',
                                                    e.target.value
                                                )
                                            }
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Отчество (при наличии)
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            className="org-input"
                                            value={
                                                organizationDataUpdate.head_patronymic
                                            }
                                            onChange={(e) =>
                                                handleInputChange(
                                                    'head_patronymic',
                                                    e.target.value
                                                )
                                            }
                                            disabled={!editMode}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Банковские реквизиты */}
                    <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                        <div className="text-lg text-white">
                            <p>Банковские реквизиты</p>
                        </div>

                        <div className="flex flex-row w-full space-x-5 pt-2">
                            <div className="flex flex-col w-full">
                                {/* toolbar */}
                                <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-t-lg h-10">
                                    <button
                                        className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#656365]"
                                        onClick={() =>
                                            handleAddBankDetailsPopup(null)
                                        }
                                        title="Добавить структуру"
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="plus"
                                        >
                                            <path
                                                fill="#FFFFFF"
                                                d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                                {/* name */}
                                <div className="flex flex-row gap-1 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-2 h-10">
                                    <div className="flex w-12 items-center justify-center">
                                        №
                                    </div>
                                    <div className="flex flex-1 items-center justify-center">
                                        Наименование банка
                                    </div>
                                    <div className="flex w-36 items-center justify-center">
                                        Номер счета
                                    </div>
                                    <div className="flex w-24 items-center justify-center">
                                        БИК
                                    </div>
                                    <div className="flex w-20 items-center justify-center text-center">
                                        Основной счет
                                    </div>
                                    <div className="flex w-10 items-center justify-center"></div>
                                </div>
                                {/* data */}
                                <div className="flex flex-col overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E]">
                                    <div className="flex flex-col w-full space-y-1">
                                        {bankDetails.map((bank, index) => (
                                            <div
                                                key={bank.guid}
                                                className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                            >
                                                <div
                                                    className="flex flex-row w-full cursor-pointer"
                                                    onClick={() =>
                                                        handleAddBankDetailsPopup(
                                                            bank.guid
                                                        )
                                                    }
                                                >
                                                    <div className="flex flex-row flex-1 h-10 gap-1 text-white text-[11px] ">
                                                        <div className="flex w-12 items-center justify-center text-[#8A8A8A]">
                                                            {index + 1}
                                                        </div>
                                                        <div className="flex flex-1 items-center justify-left">
                                                            {bank.bank_name}
                                                        </div>
                                                        <div className="flex w-36 items-center justify-center">
                                                            {
                                                                bank.bank_checking_account
                                                            }
                                                        </div>
                                                        <div className="flex w-24 items-center justify-center">
                                                            {bank.bank_bik}
                                                        </div>
                                                        <div className="flex w-20 items-center justify-center text-[#8A8A8A]">
                                                            {bank.main_account
                                                                ? 'Да'
                                                                : 'Нет'}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex w-10 items-center justify-center">
                                                    {/* button Delete*/}
                                                    <button
                                                        className="w-6 h-6 grid rounded-full place-items-center hover:bg-[#701F1F]"
                                                        onClick={() =>
                                                            handleDeleteBankDetailsPopup(
                                                                bank.guid
                                                            )
                                                        } // Передать plan_guid
                                                    >
                                                        <svg
                                                            className="w-3 h-3"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="trash-alt"
                                                        >
                                                            <path
                                                                fill="#FFFFFF"
                                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* buttons */}
                                <div className="flex flex-row gap-1 text-white p-2 bg-[#333033] rounded-b-lg h-10">
                                    <button
                                        className="button flex flex-row justify-start items-start"
                                        onClick={() =>
                                            handleAddBankDetailsPopup(null)
                                        }
                                    >
                                        <svg
                                            className="plus-icon flex h-4 w-4 justify-start items-start mr-1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            id="plus"
                                        >
                                            <path
                                                fill="#FFF"
                                                d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                            ></path>
                                        </svg>{' '}
                                        Добавить реквизиты
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {roleName == 'admin' || roleName == 'specialist' ? (
                        <>
                            {/* Настройки */}
                            <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                <div className="text-lg text-white">
                                    <p>Настройки</p>
                                </div>

                                {/* Заказчик относится к организациям, информация о закупках которых не подлежит размещению на Официальном сайте ЕИС в соответствии с частью 16 статьи 4 Федерального закона № 223-ФЗ */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsNotPlacementPurchaseCustomerOn
                                                    }
                                                    className={`switch ${isNotPlacementPurchaseCustomerOn ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isNotPlacementPurchaseCustomerOn
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isNotPlacementPurchaseCustomerOn ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsNotPlacementPurchaseCustomerOn
                                                    }
                                                >
                                                    Заказчик относится к
                                                    организациям, информация о
                                                    закупках которых не подлежит
                                                    размещению на Официальном
                                                    сайте ЕИС в соответствии с
                                                    частью 16 статьи 4
                                                    Федерального закона № 223-ФЗ
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Начальная (максимальная) цена договора включает НДС 20% */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsNmcdWithTaxDefault
                                                    }
                                                    className={`switch ${isNmcdWithTaxDefault ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isNmcdWithTaxDefault
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isNmcdWithTaxDefault ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsNmcdWithTaxDefault
                                                    }
                                                >
                                                    Начальная (максимальная)
                                                    цена договора включает НДС
                                                    20%
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* План подлежит обязательному мониторингу */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsComplianceSMB
                                                    }
                                                    className={`switch ${isComplianceSMB ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isComplianceSMB
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isComplianceSMB ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsComplianceSMB
                                                    }
                                                >
                                                    План подлежит обязательному
                                                    мониторингу
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Выбор в справочнике только конечных кодов ОКПД2 */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsAllOkpd2
                                                    }
                                                    className={`switch ${isAllOkpd2 ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isAllOkpd2
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isAllOkpd2 ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsAllOkpd2
                                                    }
                                                >
                                                    Выбор в справочнике только
                                                    конечных кодов ОКПД2
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Разрешить выбор любого способа закупки */}
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="flex flex-row items-center">
                                                <div
                                                    onClick={
                                                        handleToggleIsAllPurchaseMethod
                                                    }
                                                    className={`switch ${isAllPurchaseMethod ? 'switch-on' : 'switch-off'} ${
                                                        !editMode
                                                            ? 'switch-disabled'
                                                            : ''
                                                    }`}
                                                >
                                                    <div
                                                        className={`switch-toggle ${
                                                            isAllPurchaseMethod
                                                                ? `toggle-on ${
                                                                      !editMode
                                                                          ? 'toggle-on-disabled'
                                                                          : ''
                                                                  }`
                                                                : `toggle-off ${
                                                                      !editMode
                                                                          ? 'toggle-off-disabled'
                                                                          : ''
                                                                  }`
                                                        }`}
                                                    >
                                                        {isAllPurchaseMethod ? (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="check"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#187A33'
                                                                    }`}
                                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                ></path>
                                                            </svg>
                                                        ) : (
                                                            <svg
                                                                className="w-[15px] h-[15px]"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                id="times"
                                                            >
                                                                <path
                                                                    fill={`${
                                                                        !editMode
                                                                            ? '#262427'
                                                                            : '#656365'
                                                                    }`}
                                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                ></path>
                                                            </svg>
                                                        )}
                                                    </div>
                                                </div>
                                                <span
                                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                    onClick={
                                                        handleToggleIsAllPurchaseMethod
                                                    }
                                                >
                                                    Разрешить выбор любого
                                                    способа закупки и любых
                                                    кодов ОКПД2
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* Минимально необходимые требования, предъявляемые к закупаемым товарам, работам, услугам */}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Минимально необходимые
                                                требования, предъявляемые к
                                                закупаемым товарам, работам,
                                                услугам{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                value={
                                                    organizationDataUpdate.minimum_requirements
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'minimum_requirements',
                                                        e.target.value
                                                    )
                                                }
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Обоснование начальной (максимальной) цены договора */}
                                <div className="flex flex-row w-full space-x-5 ">
                                    <div className="flex flex-col w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Обоснование начальной
                                                (максимальной) цены договора{' '}
                                            </label>
                                        </div>
                                        <div>
                                            <input
                                                className="org-input"
                                                value={
                                                    organizationDataUpdate.order_pricing
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'order_pricing',
                                                        e.target.value
                                                    )
                                                }
                                                disabled={!editMode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Настройка интеграции */}
                            <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                <div className="text-lg text-white">
                                    <p>Настройка интеграции</p>
                                </div>

                                <div className="flex flex-col w-full space-y-3">
                                    {/* Логин , Пароль */}
                                    <div className="flex flex-row w-full space-x-5 ">
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Логин{' '}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="org-input"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                    value={
                                                        organizationDataUpdate.integration_login
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'integration_login',
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Пароль{' '}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="org-input"
                                                    type="password"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                    value={
                                                        organizationDataUpdate.integration_password
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'integration_password',
                                                            e.target.value
                                                        )
                                                    }
                                                    disabled={!editMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {!editMode ? (
                                        <div className="flex flex-row items-center justify-center gap-1">
                                            <div className="flex flex-1"></div>
                                            <div className="flex w-6 h-6 items-center justify-center">
                                                {isChecking ? (
                                                    <div className="flex h-12 w-24 items-center justify-center px-2 rounded gap-1">
                                                        <div className="flex w-5 h-5 rounded-full items-center justify-center text-white">
                                                            <svg
                                                                className="animate-spin h-4 w-4 text-white"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <circle
                                                                    className="opacity-25"
                                                                    cx="12"
                                                                    cy="12"
                                                                    r="10"
                                                                    stroke="currentColor"
                                                                    strokeWidth="4"
                                                                ></circle>
                                                                <path
                                                                    className="opacity-75"
                                                                    fill="currentColor"
                                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                ) : isCheck == 'yes' ? (
                                                    сheckingRes ? (
                                                        <div className="flex h-12 w-24 items-center justify-center px-2 rounded gap-1">
                                                            <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#187A33]">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="check"
                                                                    className="h-4 w-4"
                                                                >
                                                                    <path
                                                                        fill="#FFFFFF"
                                                                        d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="flex h-12 w-24 items-center justify-center px-2 rounded gap-1">
                                                            <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#701F1F]">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="times"
                                                                    className="h-4 w-4"
                                                                >
                                                                    <path
                                                                        fill="#FFFFFF"
                                                                        d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                    ></path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <div className="flex flex-row items-end">
                                                <button
                                                    className="flex h-6 w-[200px] px-5 bg-[#187A33] rounded-lg text-xs justify-center items-center"
                                                    onClick={integrationCheck}
                                                >
                                                    Проверить интеграцию
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        ''
                    )}
                </div>
            </div>

            {/* buttons */}
            <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                {editMode ? (
                    <>
                        <button
                            className={`flex flex-row min-w-[120px] h-6 px-4 ${hasErrors() ? 'bg-[#3D3B3F] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-green-600'}  rounded-[10px] text-xs justify-center items-center`}
                            onClick={handleSaveOrganization}
                            disabled={hasErrors()}
                        >
                            {isSaving ? (
                                <svg
                                    className="animate-spin h-4 w-4 text-white"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                >
                                    <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                    ></circle>
                                    <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                    ></path>
                                </svg>
                            ) : (
                                'Сохранить'
                            )}
                        </button>
                        <button
                            className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                            onClick={handleCloseOrganization}
                        >
                            Закрыть
                        </button>
                    </>
                ) : roleName == 'admin' || roleName == 'specialist' ? (
                    <button
                        className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                        onClick={handleEditMode}
                    >
                        Редактировать
                    </button>
                ) : (
                    ''
                )}
            </div>

            {addBankDetailsPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleAddBankDetailsPopup(null)}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[600px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Добавление реквизитов</h2>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Расчетный счет{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="org-input"
                                value={
                                    bankDetailsUpdate?.bank_checking_account ??
                                    ''
                                } // Привязка значения
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyleBankDetails(
                                        'bank_checking_account'
                                    ),
                                }}
                                onChange={(e) =>
                                    handleInputChangeAddBankDetails(
                                        'bank_checking_account',
                                        e.target.value
                                    )
                                }
                                onBlur={() =>
                                    handleBlurBankDetails(
                                        'bank_checking_account'
                                    )
                                }
                                maxLength={20} // Ограничение на уровне HTML
                            />
                            {touchedInputAddBankDetails.bank_checking_account &&
                                errorsInputAddBankDetails.bank_checking_account && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {
                                            errorsInputAddBankDetails.bank_checking_account
                                        }
                                    </span>
                                )}
                        </div>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Корреспондентский счёт{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="org-input"
                                value={
                                    bankDetailsUpdate?.bank_correspondent_account ??
                                    ''
                                } // Привязка значения
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyleBankDetails(
                                        'bank_correspondent_account'
                                    ),
                                }}
                                onChange={(e) =>
                                    handleInputChangeAddBankDetails(
                                        'bank_correspondent_account',
                                        e.target.value
                                    )
                                }
                                onBlur={() =>
                                    handleBlurBankDetails(
                                        'bank_correspondent_account'
                                    )
                                }
                                maxLength={20} // Ограничение на уровне HTML
                            />
                            {touchedInputAddBankDetails.bank_correspondent_account &&
                                errorsInputAddBankDetails.bank_correspondent_account && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInputAddBankDetails.bank_correspondent_account ??
                                            ''}
                                    </span>
                                )}
                        </div>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                БИК банка{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="org-input"
                                value={bankDetailsUpdate?.bank_bik ?? ''} // Привязка значения
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyleBankDetails(
                                        'bank_bik'
                                    ),
                                }}
                                onChange={(e) =>
                                    handleInputChangeAddBankDetails(
                                        'bank_bik',
                                        e.target.value
                                    )
                                }
                                onBlur={() => handleBlurBankDetails('bank_bik')}
                                maxLength={9} // Ограничение на уровне HTML
                            />
                            {touchedInputAddBankDetails.bank_bik &&
                                errorsInputAddBankDetails.bank_bik && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInputAddBankDetails.bank_bik}
                                    </span>
                                )}
                        </div>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Наименование банка{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>
                            <input
                                className="org-input"
                                value={bankDetailsUpdate?.bank_name ?? ''} // Привязка значения
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyleBankDetails(
                                        'bank_name'
                                    ),
                                }}
                                onChange={(e) =>
                                    handleInputChangeAddBankDetails(
                                        'bank_name',
                                        e.target.value
                                    )
                                }
                                onBlur={() =>
                                    handleBlurBankDetails('bank_name')
                                }
                            />
                            {touchedInputAddBankDetails.bank_name &&
                                errorsInputAddBankDetails.bank_name && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInputAddBankDetails.bank_name}
                                    </span>
                                )}
                        </div>

                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Адрес банка{' '}
                                <span className="text-[#FF0000]">*</span>
                            </label>

                            <input
                                className="org-input"
                                value={bankDetailsUpdate?.bank_address ?? ''} // Привязка значения
                                style={{
                                    colorScheme: 'dark',
                                    border: getBorderStyleBankDetails(
                                        'bank_address'
                                    ),
                                }}
                                onChange={(e) =>
                                    handleInputChangeAddBankDetails(
                                        'bank_address',
                                        e.target.value
                                    )
                                }
                                onBlur={() =>
                                    handleBlurBankDetails('bank_address')
                                }
                            />
                            {touchedInputAddBankDetails.bank_address &&
                                errorsInputAddBankDetails.bank_address && (
                                    <span
                                        style={{
                                            color: '#C15C5C',
                                            fontSize: '12.8px',
                                        }}
                                    >
                                        {errorsInputAddBankDetails.bank_address}
                                    </span>
                                )}
                        </div>

                        {/* Основной */}
                        <div className="flex flex-row w-full space-x-5 pt-2">
                            <div className="flex flex-col w-full space-y-1">
                                <div>
                                    <label className="flex flex-row items-center">
                                        <div
                                            onClick={
                                                handleToggleIsMainBankDetails
                                            }
                                            className={`switch ${isMainBankDetails ? 'switch-on' : 'switch-off'}`}
                                        >
                                            <div
                                                className={`switch-toggle ${
                                                    isMainBankDetails
                                                        ? `toggle-on`
                                                        : `toggle-off`
                                                }`}
                                            >
                                                {isMainBankDetails ? (
                                                    <svg
                                                        className="w-[15px] h-[15px]"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="check"
                                                    >
                                                        <path
                                                            fill={'#187A33'}
                                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                        ></path>
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        className="w-[15px] h-[15px]"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="times"
                                                    >
                                                        <path
                                                            fill={'#656365'}
                                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                        ></path>
                                                    </svg>
                                                )}
                                            </div>
                                        </div>
                                        <span className="w-full ml-5 text-[12.8px] text-[#8A8A8A]">
                                            Основной счет
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={() => handleAddBankDetailsPopup(null)}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs ml-2 items-center justify-center bg-[#494849]  ${hasErrorsBankDetails() ? 'bg-[#3D3B3F] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-green-600 text-white'}`}
                                onClick={handleAddBankDetails}
                                disabled={
                                    !bankDetailsUpdate
                                        ? true
                                        : hasErrorsBankDetails()
                                }
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : selectedBankDetailsGuid !== null ? (
                                    'Изменить'
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {deleteBankDetailsPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[400px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Удаление реквизита</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="text-[12.8px] text-[#8A8A8A]">
                                Вы точно хотите удалить реквизит?
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end">
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={() =>
                                    handleDeleteBankDetailsPopup(null)
                                }
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex px-4 py-1 w-24 h-7 rounded-full shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#701F1F]`}
                                onClick={handleDeleteBankDetails} // Вызвать handleDelete
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrgHomePage
