import React, { useCallback, useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type Props = {
    planGuidNav: string
    isAttachment: boolean
}

const MAX_FILE_SIZE_MB = 50
const ALLOWED_TYPES = [
    // Документы
    'application/pdf', // .pdf
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/rtf', // .rtf

    // Таблицы
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx

    // Изображения
    'image/jpeg', // .jpg, .jpeg
    'image/png', // .png
    'image/tiff', // .tiff

    // Архивы
    'application/zip', // .zip
    'application/x-rar-compressed', // .rar

    // Простой текст
    'text/plain',

    'image/bmp', // .bmp
    'image/gif', // .gif
    'text/csv', // .csv
    'application/vnd.oasis.opendocument.presentation', // .odp
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/vnd.oasis.opendocument.text', // .odt
    'application/vnd.ms-powerpoint', // .ppt
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
    'application/xml',
    'text/html',
    'application/gzip',
]

const FileUploaderBlock: React.FC<Props> = (props) => {
    const [uploading, setUploading] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([])
    const [infoTooltip, setInfoTooltip] = useState<{
        x: number
        y: number
    } | null>(null)

    const isAllowedByExtension = (fileName: string) => {
        const allowedExtensions = [
            '.pdf',
            '.docx',
            '.doc',
            '.rtf',
            '.xls',
            '.xlsx',
            '.jpeg',
            '.jpg',
            '.bmp',
            '.tif',
            '.tiff',
            '.txt',
            '.gif',
            '.csv',
            '.odp',
            '.odf',
            '.ods',
            '.odt',
            '.sxc',
            '.sxw',
            '.zip',
            '.rar',
            '.html',
            '.ppt',
            '.pptx',
            '.dwg',
            '.sig',
            '.sgn',
            '.sign',
            '.gzip',
            '.xml',
            '.htm',
            '.png',
        ]
        return allowedExtensions.some((ext) =>
            fileName.toLowerCase().endsWith(ext)
        )
    }

    const Icons = {
        spinner: (
            <svg
                className="animate-spin h-4 w-4 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                ></path>
            </svg>
        ),
        success: (
            <svg className="w-4 h-4" viewBox="0 0 24 24">
                <path
                    fill="#00FFAA"
                    d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                ></path>
            </svg>
        ),
        error: (
            <svg className="w-4 h-4" viewBox="0 0 24 24">
                <path
                    fill="#FF5555"
                    d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z"
                ></path>
            </svg>
        ),
    }

    const fetchUploadedFiles = async () => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/attachments?plan_guid=${props.planGuidNav}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                    },
                }
            )

            if (!response.ok) throw new Error('Ошибка загрузки файлов')

            const data = await response.json()
            const filtered = data.filter(
                (item: any) => item.attachment_status === 'Готово к отправке'
            )

            setUploadedFiles(filtered)
        } catch (error) {
            console.error('Ошибка при получении файлов:', error)
            toast.error('Не удалось загрузить файлы')
        }
    }

    const uploadFile = async (file: File) => {
        // Проверка типа
        if (
            !ALLOWED_TYPES.includes(file.type) &&
            !isAllowedByExtension(file.name)
        ) {
            toast.error(`Недопустимый тип файла: ${file.name}`)
            return
        }

        // Проверка размера
        if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
            toast(
                `Слишком большой файл (${file.name})\nМаксимальный объем ${MAX_FILE_SIZE_MB} МБ`,
                {
                    type: 'error',
                    style: { whiteSpace: 'pre-line' },
                }
            )
            return
        }

        const formData = new FormData()
        formData.append('file', file) // поле обязательно должно называться "file"

        const toastId = toast.loading(`Загружается: ${file.name}`, {
            icon: Icons.spinner,
        })

        try {
            setUploading(true)
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/attachments?plan_guid=${props.planGuidNav}`,
                {
                    method: 'POST',
                    credentials: 'include',
                    body: formData,
                    headers: {
                        Accept: 'application/json',
                        // Не указываем Content-Type — браузер сам проставит multipart boundary!
                    },
                }
            )

            if (!response.ok) throw new Error('Upload failed')

            const result = await response.json()
            setUploadedFiles((prev) => [...prev, result])
            toast.update(toastId, {
                render: `Загружено: ${result.filename}`,
                type: 'success',
                isLoading: false,
                autoClose: 3000,
                icon: Icons.success,
            })
            await fetchUploadedFiles()
        } catch (err) {
            console.error(err)
            toast.update(toastId, {
                render: `Ошибка загрузки: ${file.name}`,
                type: 'error',
                isLoading: false,
                autoClose: 3000,
                icon: Icons.error,
            })
        } finally {
            setUploading(false)
        }
    }

    const handleDrop = useCallback((e: React.DragEvent) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files.length > 0) {
            Array.from(e.dataTransfer.files).forEach(uploadFile)
        }
    }, [])

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            Array.from(files).forEach(uploadFile)
        }
    }

    useEffect(() => {
        if (!props.isAttachment) {
            const deleteAllReadyFiles = async () => {
                try {
                    const response = await fetch(
                        process.env.REACT_APP_API_URL +
                            `/api/backend/v1/attachments?plan_guid=${props.planGuidNav}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                Accept: 'application/json',
                            },
                        }
                    )

                    if (!response.ok) throw new Error('Ошибка получения файлов')

                    const allFiles = await response.json()

                    const readyToDelete = allFiles.filter(
                        (file: any) =>
                            file.attachment_status === 'Готово к отправке'
                    )

                    if (readyToDelete.length === 0) return

                    for (const file of readyToDelete) {
                        const toastId = toast.loading(
                            `Удаление: ${file.filename}`
                        )
                        try {
                            const res = await fetch(
                                process.env.REACT_APP_API_URL +
                                    `/api/backend/v1/attachments/${file.guid}?plan_guid=${props.planGuidNav}`,
                                {
                                    method: 'DELETE',
                                    credentials: 'include',
                                    headers: {
                                        Accept: 'application/json',
                                    },
                                }
                            )
                            if (!res.ok) throw new Error()

                            toast.update(toastId, {
                                render: `Удалено: ${file.filename}`,
                                type: 'success',
                                isLoading: false,
                                autoClose: 3000,
                                icon: Icons.success,
                            })
                        } catch (err) {
                            toast.update(toastId, {
                                render: `Ошибка при удалении: ${file.filename}`,
                                type: 'error',
                                isLoading: false,
                                autoClose: 3000,
                                icon: Icons.error,
                            })
                        }
                    }

                    await fetchUploadedFiles()
                } catch (err) {
                    console.error(
                        'Ошибка удаления при отключении вложений:',
                        err
                    )
                    toast.error('Не удалось получить список вложений')
                }
            }

            deleteAllReadyFiles()
        }
    }, [props.isAttachment])

    const handleDelete = async (guidToDelete: string, planGuid: string) => {
        const toastId = toast.loading('Удаление файла...')
        try {
            const res = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/attachments/${guidToDelete}?plan_guid=${planGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        Accept: 'application/json',
                    },
                }
            )

            if (!res.ok) throw new Error('Удаление не удалось')

            setUploadedFiles((prev) =>
                prev.filter((file) => file.guid !== guidToDelete)
            )
            toast.update(toastId, {
                render: 'Удалено',
                type: 'info',
                isLoading: false,
                autoClose: 3000,
                icon: Icons.success,
            })
        } catch (err) {
            toast.update(toastId, {
                render: 'Ошибка удаления',
                type: 'error',
                isLoading: false,
                autoClose: 3000,
                icon: Icons.error,
            })
        }
    }

    const handleDownload = (guid: string, planGuid: string) => {
        const form = document.createElement('form')
        form.method = 'GET'
        form.action =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/attachments/${guid}`
        form.target = '_blank'

        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = 'plan_guid'
        input.value = planGuid

        form.appendChild(input)
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    useEffect(() => {
        fetchUploadedFiles()
    }, [])

    if (!props.isAttachment) return null

    return (
        <div className="flex flex-col w-full space-y-2">
            <div
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                className="flex items-center justify-center text-center border-2 border-dashed border-[#8A8A8A] rounded-lg p-6 bg-[#1D1C1E]  hover:bg-[#2A282B] transition"
            >
                <div className="flex flex-col relative w-full h-full items-center space-y-2 text-[#8A8A8A]">
                    <label
                        htmlFor="fileInput"
                        className="flex flex-row gap-2 text-[14px] rounded-[20px] w-[170px] h-[45px] cursor-pointer text-white items-center justify-center bg-[#494849] hover:bg-[#656365] shadow-md"
                    >
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="paperclip"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M18.08,12.42,11.9,18.61a4.25,4.25,0,0,1-6-6l8-8a2.57,2.57,0,0,1,3.54,0,2.52,2.52,0,0,1,0,3.54l-6.9,6.89A.75.75,0,1,1,9.42,14l5.13-5.12a1,1,0,0,0-1.42-1.42L8,12.6a2.74,2.74,0,0,0,0,3.89,2.82,2.82,0,0,0,3.89,0l6.89-6.9a4.5,4.5,0,0,0-6.36-6.36l-8,8A6.25,6.25,0,0,0,13.31,20l6.19-6.18a1,1,0,1,0-1.42-1.42Z"
                            ></path>
                        </svg>
                        Выберите файл
                    </label>
                    <span className="text-[12.8px]">
                        или перетащите файл сюда
                    </span>
                    <input
                        id="fileInput"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        className="hidden"
                    />
                    <div
                        className="absolute -top-6 -right-4"
                        onMouseEnter={(e) =>
                            setInfoTooltip({
                                x: e.clientX,
                                y: e.clientY,
                            })
                        }
                        onMouseLeave={() => setInfoTooltip(null)}
                        style={{ cursor: 'pointer' }}
                    >
                        <svg
                            className="w-5 h-5"
                            id="info-circle"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#FFFFFF"
                                d="M12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Zm0-8.5a1,1,0,0,0-1,1v3a1,1,0,0,0,2,0v-3A1,1,0,0,0,12,11.5Zm0-4a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,7.5Z"
                            ></path>
                        </svg>
                    </div>
                </div>
            </div>

            {uploadedFiles.length > 0 && (
                <div className="flex flex-col w-full pt-2">
                    <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                        <div className="flex w-10 items-center justify-center">
                            №
                        </div>
                        <div className="flex flex-1 items-center justify-center">
                            Наименование
                        </div>
                    </div>
                    <div className="flex flex-col gap-1 bg-[#1D1C1E] rounded-b-lg px-1 py-1 max-h-[200px] overflow-y-auto custom-scroll">
                        {uploadedFiles.map((item, index) => (
                            <div
                                key={item.guid}
                                className="flex flex-row w-full items-center rounded bg-[#2A282B] hover:bg-[#373538] text-white text-[11px] px-1 py-1"
                            >
                                <div
                                    className="flex flex-row w-[520px] h-full cursor-pointer py-1"
                                    onClick={() =>
                                        handleDownload(
                                            item.guid,
                                            props.planGuidNav
                                        )
                                    }
                                >
                                    <div className="flex w-10 justify-center text-[#8A8A8A]">
                                        {index + 1}
                                    </div>
                                    <div
                                        className="flex w-full items-center truncate pr-2"
                                        title={item.filename}
                                    >
                                        <p className="onerow">
                                            {item.filename}
                                        </p>
                                    </div>
                                </div>
                                <button
                                    onClick={() =>
                                        handleDelete(
                                            item.guid,
                                            props.planGuidNav
                                        )
                                    }
                                    className="flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="trash-alt"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            {infoTooltip && (
                <div
                    className="flex flex-col w-[350px] p-2 gap-2 text-left justify-start items-center"
                    style={{
                        position: 'fixed',
                        left: infoTooltip.x,
                        top: infoTooltip.y,
                        backgroundColor: '#464447',
                        border: '1px solid gray',
                        borderRadius: '5px',
                        color: '#FFF',
                        fontSize: 9,
                    }}
                >
                    <div className="flex flex-col items-center justify-start">
                        <span className="text-[12.8px] w-full text-left">
                            Максимально допустимый размер файла: 50 МБ.
                        </span>
                        <span className="text-[12.8px] w-full text-left">
                            Допустимый формат файла: pdf, docx, doc, rtf, xls,
                            xlsx, jpeg, jpg, bmp, tif, tiff, txt, gif, csv, odp,
                            odf, ods, odt, sxc, sxw, zip, rar, html, ppt, pptx,
                            dwg, sig, sgn, sign, gzip, xml, htm, png.
                        </span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FileUploaderBlock
