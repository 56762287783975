import React, { useState, useEffect } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { User } from '../types'
import { Structure } from '../../Structure/types'
import { Role } from '../../Roles/types'
import { useAuth } from '../../../../../hooks/auth'

const getCurrentDate = (): string => {
    const today = new Date()
    const year = today.getFullYear()
    const month = String(today.getMonth() + 1).padStart(2, '0') // добавляем 1, так как месяцы начинаются с 0
    const day = String(today.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

function formatDateToYYYYMMDD(isoDate: string): string {
    const date = new Date(isoDate)
    const year = date.getUTCFullYear()
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export const loadUser = async ({ params }: any): Promise<User> => {
    if (params.guid === 'new') {
        // Возвращаем пустого пользователя для нового
        return {
            guid: null,
            first_name: '',
            last_name: '',
            patronymic: '',
            position: '',
            struct_guid: '',
            role_guid: '',
            role_name: '',
            email: '',
            phone: '',
            phone_add: null,
            extension_phone: '',
            fax: null,
            birth_day: '',
            gender: '',
            organization_guid: null,
            sys_created_at: null,
            sys_updated_at: null,
            is_active: true,
            password: null,
        }
    }
    try {
        // Подставляем guid пользователя в URL
        const response = await fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/organization/users/${params.guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response.ok) {
            throw new Error(`Error loading user: ${response.status}`)
        }

        // Парсим JSON-ответ
        const user: User = await response.json()
        return user
    } catch (error) {
        console.error('Failed to load user:', error)
        throw error // Или обработайте ошибку соответствующим образом
    }
}

const loadStructure = async (): Promise<Structure[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization_struct',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization_struct')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const loadRole = async (): Promise<Role> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organizations/roles/',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch organization')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const OrgUserViewOrEditPage: React.FC = () => {
    const { user } = useAuth()

    const userData = useLoaderData() as User
    const navigate = useNavigate()

    const isNew = location.hash.includes('new')
    const isEdit = window.location.hash.endsWith('/edit')

    const [userDataUpdate, setUserDataUpdate] = useState(userData)
    const [isActiveUser, setIsActiveUser] = useState(userData.is_active)

    const [structData, setStructData] = useState<Structure[]>([])
    const [roleData, setRoleData] = useState<Role[]>([])

    const [editMode, setEditMode] = useState(false)
    const [isSaving, setIsSaving] = useState(false) // Состояние для анимации загрузки

    const [isCreating, setIsCreating] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки

    const [emailResetPwd, setEmailResetPwd] = useState('')

    const [resetPwdPopup, setResetPwdPopup] = useState(false)
    const [resetPwdDonePopup, setResetPwdDonePopup] = useState(false)

    const [isResetting, setIsResetting] = useState(false) // Состояние для анимации загрузки

    const [touchedInput, setTouchedInput] = useState<{
        [key: string]: boolean
    }>({})
    const [errorsInput, setErrorsInput] = useState<{ [key: string]: string }>(
        {}
    )

    useEffect(() => {
        const loadData = async () => {
            const data = await loadStructure()
            setStructData(data)

            const dataRole = (await loadRole()) as unknown as { result: Role[] }
            const roleLoad = dataRole.result || [] // Извлекаем массив из `result`
            setRoleData(roleLoad)
        }
        loadData()
    }, [])

    const getNameStruct = (struct_guid: string) => {
        const nameSctruct = structData.find((s) => s.guid === struct_guid)
        return nameSctruct?.struct_name || '-'
    }

    const getNameRole = (role_guid: string) => {
        const nameRole = roleData.find((r) => r.guid === role_guid)
        return nameRole?.name || '-'
    }

    const handleEditMode = () => {
        setEditMode(!editMode)
    }

    const handleToggleIsActiveUser = () => {
        if (isEdit) {
            setIsActiveUser((prevState) => !prevState)
            setUserDataUpdate((prev) => ({
                ...prev,
                ['is_active']: !isActiveUser,
            }))
        }
        if (editMode) {
            setIsActiveUser((prevState) => !prevState)
            setUserDataUpdate((prev) => ({
                ...prev,
                ['is_active']: !isActiveUser,
            }))
        }
    }

    // Валидация полей
    const validateField = (fieldName: keyof User, value: string) => {
        if (
            [
                'first_name',
                'last_name',
                'position',
                'email',
                'struct_guid',
                'role_guid',
                'gender',
                'birth_day',
            ].includes(fieldName)
        ) {
            let error = ''

            if (!value) {
                error = 'Поле обязательно к заполнению'
            }

            setErrorsInput((prev) => ({ ...prev, [fieldName]: error }))
        }
    }

    // Обработчик для изменения поля
    const handleInputChange = (fieldName: keyof User, value: string | null) => {
        setUserDataUpdate((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const handleBlur = (fieldName: keyof User) => {
        setTouchedInput((prev) => ({ ...prev, [fieldName]: true }))
        validateField(fieldName, userDataUpdate[fieldName]?.toString() || '')
    }

    const getBorderStyle = (fieldName: keyof User) => {
        const isTouched = touchedInput[fieldName]
        const hasError = errorsInput[fieldName]
        return isTouched && hasError ? '1px solid #C15C5C' : ''
    }

    // Проверяем, есть ли ошибки
    const hasErrors = () => {
        return Object.values(errorsInput).some((error) => error !== '')
    }

    const handleCloseEditUser = async () => {
        try {
            setUserDataUpdate(userData) // Обновляем состояние
            if (userData.guid) {
                setIsActiveUser(true)
            } else {
                setIsActiveUser(false)
            }
            setErrorsInput({})
            setTouchedInput({})
            setEditMode(false) // Выходим из режима редактирования
        } catch (error) {
            console.error('Ошибка при загрузке данных:', error)
        }
    }

    const createDataUser = async () => {
        setIsCreating(true)

        const url =
            process.env.REACT_APP_API_URL + `/api/backend/v1/organization/users`

        const formattedDate = new Date(userDataUpdate?.birth_day)
            .toISOString()
            .split('T')[0]

        const filteredData = {
            name: userDataUpdate.first_name,
            lastname: userDataUpdate.last_name,
            patronymic: userDataUpdate.patronymic,
            password: userDataUpdate.password,
            phone: userDataUpdate.phone,
            phone_add: userDataUpdate.extension_phone,
            email: userDataUpdate.email,
            role_name: userDataUpdate.role_name,
            birth_day: formattedDate,
            gender: userDataUpdate.gender,
            struct_guid: userDataUpdate.struct_guid,
            position: userDataUpdate.position,
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filteredData),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create user. Status: ${response.status}`
                )
            }

            const newUser = await response.json()

            navigate(`/hub/org/users`)

            console.log('User Create successfully')
        } catch (error) {
            console.error('Error while creating user:', error)
            setIsCreating(false)
        }
    }

    const saveUpdateDataUser = async () => {
        setIsSaving(true)

        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/organization/users/${userDataUpdate.guid}`

        const formattedDate = new Date(userDataUpdate?.birth_day)
            .toISOString()
            .split('T')[0]

        const filteredData = {
            first_name: userDataUpdate.first_name,
            last_name: userDataUpdate.last_name,
            patronymic: userDataUpdate.patronymic,
            position: userDataUpdate.position,
            struct_guid: userDataUpdate.struct_guid,
            role_guid: userDataUpdate.role_guid,
            phone: userDataUpdate.phone,
            extension_phone: userDataUpdate.extension_phone,
            fax: userDataUpdate.fax,
            birth_day: formattedDate,
            email: userDataUpdate.email,
            gender: userDataUpdate.gender,
            is_active: userDataUpdate.is_active,
        }

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filteredData),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to update  position from. Status: ${response.status}`
                )
            }

            navigate(`/hub/org/users`)

            console.log('Position update successfully')
        } catch (error) {
            console.error('Error while updating position:', error)
            setIsSaving(false)
        } finally {
            setIsSaving(false)
        }
    }

    const deleteUser = async () => {
        setIsDeleting(true)

        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/organization/users/${userDataUpdate.guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to create user. Status: ${response.status}`
                )
            }

            navigate(`/hub/org/users`)

            console.log('User Create successfully')
        } catch (error) {
            console.error('Error while creating user:', error)
            setIsDeleting(false)
        }
    }

    const saveUpdateDataUserSelf = async () => {
        setIsSaving(true)

        const url = process.env.REACT_APP_API_URL + `/api/backend/v1/user`

        const formattedDate = new Date(userDataUpdate?.birth_day)
            .toISOString()
            .split('T')[0]

        const filteredData = {
            first_name: userDataUpdate.first_name,
            last_name: userDataUpdate.last_name,
            patronymic: userDataUpdate.patronymic,
            position: userDataUpdate.position,
            struct_guid: userDataUpdate.struct_guid,
            role_guid: userDataUpdate.role_guid,
            phone: userDataUpdate.phone,
            extension_phone: userDataUpdate.extension_phone,
            fax: userDataUpdate.fax,
            birth_day: userDataUpdate.birth_day,
            email: userDataUpdate.email,
            gender: userDataUpdate.gender,
            is_active: userDataUpdate.is_active,
        }

        try {
            const response = await fetch(url, {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(filteredData),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to update Userself from. Status: ${response.status}`
                )
            }
            setIsSaving(false)

            console.log('Userself update successfully')
        } catch (error) {
            console.error('Error while updating Userself:', error)
            setIsSaving(false)
        } finally {
            setUserDataUpdate(userDataUpdate)
            setIsSaving(false)
        }
    }

    useEffect(() => {
        setEmailResetPwd(userDataUpdate.email)
    }, [userDataUpdate.email])

    const handleResetPwdPopup = () => {
        setResetPwdPopup(!resetPwdPopup)
    }

    const handleResetPwdDonePopup = () => {
        setResetPwdDonePopup(!resetPwdDonePopup)
    }

    const handleInputEmail = (value: string) => {
        setEmailResetPwd(value)
    }

    const handleResetPwd = async () => {
        setIsResetting(true)

        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/user/request_reset_password`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: emailResetPwd,
                    base_url: `${process.env.REACT_APP_API_URL}/#/auth`,
                }),
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to reset user. Status: ${response.status}`
                )
            }

            console.log('User reset successfully')
        } catch (error) {
            console.error('Error while resetting user:', error)
        } finally {
            setIsResetting(false)
            setResetPwdPopup(!resetPwdPopup)
            setResetPwdDonePopup(!resetPwdDonePopup)
        }
    }

    return (
        <span className="text-white">
            {isEdit ? (
                <>
                    <div className="flex flex-col text-white h-full">
                        <div className="bg-[#111111]">
                            <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                                <div className="w-60 h-20 my-auto content-center ">
                                    <p className="text-sm text-white">
                                        Реестр пользователей
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center min-h-[calc(100vh_-_124px)] overflow-y-auto py-5">
                            <div className="flex flex-col h-max w-[600px] space-y-10">
                                {/* Моя организация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>
                                            {isNew
                                                ? 'Создание пользователя'
                                                : 'Профиль пользователя'}
                                        </p>
                                    </div>

                                    {/* Активный пользователь */}
                                    <div className="flex flex-row w-full space-x-5 pt-2">
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="flex flex-row items-center">
                                                    <div
                                                        onClick={
                                                            handleToggleIsActiveUser
                                                        }
                                                        className={`switch ${isActiveUser ? 'switch-on' : 'switch-off'} ${
                                                            !isEdit
                                                                ? 'switch-disabled'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div
                                                            className={`switch-toggle ${
                                                                isActiveUser
                                                                    ? `toggle-on ${
                                                                          !isEdit
                                                                              ? 'toggle-on-disabled'
                                                                              : ''
                                                                      }`
                                                                    : `toggle-off ${
                                                                          !isEdit
                                                                              ? 'toggle-off-disabled'
                                                                              : ''
                                                                      }`
                                                            }`}
                                                        >
                                                            {isActiveUser ? (
                                                                <svg
                                                                    className="w-[15px] h-[15px]"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="check"
                                                                >
                                                                    <path
                                                                        fill={`${
                                                                            !isEdit
                                                                                ? '#262427'
                                                                                : '#187A33'
                                                                        }`}
                                                                        d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                    ></path>
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    className="w-[15px] h-[15px]"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="times"
                                                                >
                                                                    <path
                                                                        fill={`${
                                                                            !isEdit
                                                                                ? '#262427'
                                                                                : '#656365'
                                                                        }`}
                                                                        d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                    ></path>
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <span
                                                        className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                        onClick={
                                                            handleToggleIsActiveUser
                                                        }
                                                    >
                                                        Активный пользователь
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row space-x-5">
                                        <div className="flex items-start">
                                            <div className="h-[100px] w-[100px] grid bg-[#181818] rounded-full place-items-center">
                                                <div className="flex flex-col items-center">
                                                    <button className="w-6 h-6">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="image-plus"
                                                        >
                                                            <path
                                                                fill="#8A8A8A"
                                                                d="M19,10a1,1,0,0,0-1,1v3.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.71L9.41,11.12a2.79,2.79,0,0,0-3.93,0L4,12.61V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19.22A2.79,2.79,0,0,0,4.78,22H17.22a2.88,2.88,0,0,0,.8-.12h0a2.74,2.74,0,0,0,2-2.65V11A1,1,0,0,0,19,10ZM5,20a1,1,0,0,1-1-1V15.43l2.89-2.89a.78.78,0,0,1,1.1,0L15.46,20Zm13-1a1,1,0,0,1-.18.54L13.3,15l.71-.7a.77.77,0,0,1,1.1,0L18,17.21ZM21,4H20V3a1,1,0,0,0-2,0V4H17a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    <p className="text-[#8A8A8A] text-xs">
                                                        Загрузить
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full space-y-3">
                                            {/* Фамилия */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Фамилия{' '}
                                                        {isEdit && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'last_name'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.last_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'last_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'last_name'
                                                            )
                                                        }
                                                        placeholder="Фамилия"
                                                    />
                                                </div>
                                                {touchedInput.last_name &&
                                                    errorsInput.last_name && (
                                                        <span
                                                            style={{
                                                                color: '#C15C5C',
                                                                fontSize:
                                                                    '12.8px',
                                                            }}
                                                        >
                                                            {
                                                                errorsInput.last_name
                                                            }
                                                        </span>
                                                    )}
                                            </div>

                                            {/* Имя */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Имя{' '}
                                                        {isEdit && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'first_name'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.first_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'first_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'first_name'
                                                            )
                                                        }
                                                        placeholder="Имя"
                                                    />
                                                    {touchedInput.first_name &&
                                                        errorsInput.first_name && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.first_name
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>

                                            {/* Отчество (при наличии) */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Отчество (при наличии)
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.patronymic
                                                                ? userDataUpdate.patronymic
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'patronymic',
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Отчество"
                                                    />
                                                </div>
                                            </div>

                                            {/* Дата рождения, Пол*/}
                                            <div className="flex flex-row w-full space-x-5 ">
                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Дата рождения{' '}
                                                            {isEdit && (
                                                                <span className="text-[#FF0000]">
                                                                    *
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className={`user-input`}
                                                            style={{
                                                                colorScheme:
                                                                    'dark',
                                                                border: getBorderStyle(
                                                                    'birth_day'
                                                                ),
                                                            }}
                                                            value={
                                                                userDataUpdate.birth_day
                                                                    ? userDataUpdate.birth_day
                                                                    : ''
                                                            }
                                                            onChange={(e) => {
                                                                const date =
                                                                    e.target
                                                                        .value // Здесь браузер вернёт дату в формате YYYY-MM-DD
                                                                handleInputChange(
                                                                    'birth_day',
                                                                    date
                                                                )
                                                            }}
                                                            onBlur={() =>
                                                                handleBlur(
                                                                    'birth_day'
                                                                )
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                        {touchedInput.birth_day &&
                                                            errorsInput.birth_day && (
                                                                <span
                                                                    style={{
                                                                        color: '#C15C5C',
                                                                        fontSize:
                                                                            '12.8px',
                                                                    }}
                                                                >
                                                                    {
                                                                        errorsInput.birth_day
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Пол{' '}
                                                            {isEdit && (
                                                                <span className="text-[#FF0000]">
                                                                    *
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <select
                                                            className={`user-input`}
                                                            style={{
                                                                colorScheme:
                                                                    'dark',
                                                                border: getBorderStyle(
                                                                    'gender'
                                                                ),
                                                            }}
                                                            value={
                                                                userDataUpdate.gender
                                                                    ? userDataUpdate.gender
                                                                    : '-'
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    'gender',
                                                                    e.target
                                                                        .value ==
                                                                        '-'
                                                                        ? null
                                                                        : e
                                                                              .target
                                                                              .value
                                                                )
                                                            }
                                                            onBlur={() =>
                                                                handleBlur(
                                                                    'gender'
                                                                )
                                                            }
                                                            disabled={!isEdit}
                                                        >
                                                            <option value="-">
                                                                Выбрать
                                                            </option>
                                                            <option value="male">
                                                                Мужской
                                                            </option>
                                                            <option value="female">
                                                                Женский
                                                            </option>
                                                        </select>
                                                        {touchedInput.gender &&
                                                            errorsInput.gender && (
                                                                <span
                                                                    style={{
                                                                        color: '#C15C5C',
                                                                        fontSize:
                                                                            '12.8px',
                                                                    }}
                                                                >
                                                                    {
                                                                        errorsInput.gender
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Общая информация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Общая информация</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Контактный телефон, Добавочный номер */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-2/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Контактный телефон
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.phone
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'phone',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col w-1/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Добавочный номер
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.extension_phone
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'extension_phone',
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Должность */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Должность{' '}
                                                    {isEdit && (
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className={`user-input`}
                                                    style={{
                                                        colorScheme: 'dark',
                                                        border: getBorderStyle(
                                                            'position'
                                                        ),
                                                    }}
                                                    value={
                                                        userDataUpdate.position
                                                            ? userDataUpdate.position
                                                            : ''
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'position',
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleBlur('position')
                                                    }
                                                    placeholder="Должность"
                                                />
                                                {touchedInput.position &&
                                                    errorsInput.position && (
                                                        <span
                                                            style={{
                                                                color: '#C15C5C',
                                                                fontSize:
                                                                    '12.8px',
                                                            }}
                                                        >
                                                            {
                                                                errorsInput.position
                                                            }
                                                        </span>
                                                    )}
                                            </div>
                                        </div>

                                        {/* Подразделение */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Подразделение{' '}
                                                        {isEdit && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'struct_guid'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.struct_guid
                                                                ? userDataUpdate.struct_guid
                                                                : '-'
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'struct_guid',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : e.target
                                                                          .value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'struct_guid'
                                                            )
                                                        }
                                                        disabled={!isEdit}
                                                    >
                                                        <option
                                                            value="-"
                                                            className="text-[#8A8A8A]"
                                                        >
                                                            Выбрать
                                                        </option>
                                                        {structData?.map(
                                                            (struct, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        struct.guid
                                                                    }
                                                                >
                                                                    {
                                                                        struct.struct_name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {touchedInput.struct_guid &&
                                                        errorsInput.struct_guid && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.struct_guid
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Роль */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Роль{' '}
                                                        {isEdit && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'role_guid'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.role_guid
                                                                ? userDataUpdate.role_guid
                                                                : '-'
                                                        }
                                                        onChange={(e) => {
                                                            const roleName =
                                                                getNameRole(
                                                                    e.target
                                                                        .value
                                                                )
                                                            handleInputChange(
                                                                'role_guid',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : e.target
                                                                          .value
                                                            )
                                                            handleInputChange(
                                                                'role_name',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : roleName
                                                            )
                                                        }}
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'role_guid'
                                                            )
                                                        }
                                                        disabled={!isEdit}
                                                    >
                                                        <option value="">
                                                            Выбрать
                                                        </option>

                                                        {/* sregfsdgrgedrghr */}
                                                        {roleData.map(
                                                            (list, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        list.guid
                                                                    }
                                                                >
                                                                    {list.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {touchedInput.role_guid &&
                                                        errorsInput.role_guid && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.role_guid
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Учетные данные */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Учетные данные</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Электронная почта */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Электронная почта{' '}
                                                    {isEdit && (
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className={`user-input`}
                                                    style={{
                                                        colorScheme: 'dark',
                                                        border: getBorderStyle(
                                                            'email'
                                                        ),
                                                    }}
                                                    value={
                                                        userDataUpdate.email
                                                            ? userDataUpdate.email
                                                            : ''
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'email',
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleBlur('email')
                                                    }
                                                    placeholder="email"
                                                />
                                                {touchedInput.email &&
                                                    errorsInput.email && (
                                                        <span
                                                            style={{
                                                                color: '#C15C5C',
                                                                fontSize:
                                                                    '12.8px',
                                                            }}
                                                        >
                                                            {errorsInput.email}
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Пароль учетной записи */}
                                        {isNew ? (
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Пароль учетной записи
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'password'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.password
                                                                ? userDataUpdate.password
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'password',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'password'
                                                            )
                                                        }
                                                        placeholder="password"
                                                    />
                                                    {touchedInput.password &&
                                                        errorsInput.password && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.password
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        ) : (
                                            <button
                                                className="flex flex-row w-[180px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                                //onClick={handleEditMode}
                                            >
                                                Поменять пароль
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* buttons */}
                        <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end items-center gap-3">
                            <button
                                className={`flex flex-row px-4 min-w-[120px] h-6 ${hasErrors() ? 'bg-[#494849] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-green-600'}  rounded-[10px] text-xs justify-center items-center gap-2`}
                                disabled={!userDataUpdate ? true : hasErrors()}
                                onClick={() => {
                                    if (isNew == true) {
                                        createDataUser()
                                    } else {
                                        saveUpdateDataUser()
                                    }
                                }}
                            >
                                {isNew ? 'Создать' : 'Сохранить'}
                            </button>
                            {isNew ? (
                                ''
                            ) : (
                                <button
                                    className="flex flex-row px-4 py-1 min-w-[120px] h-6 rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#494849] hover:bg-[#9E2C2C] gap-2"
                                    onClick={deleteUser}
                                >
                                    Удалить
                                </button>
                            )}
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={() => navigate('/hub/org/users')}
                            >
                                Закрыть
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex flex-col text-white h-full">
                        <div className="bg-[#111111]">
                            <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                                <div className="w-60 h-20 my-auto content-center ">
                                    <p className="text-sm text-white">
                                        Пользователь
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center min-h-[calc(100vh_-_124px)] overflow-y-auto py-5">
                            <div className="flex flex-col h-max w-[600px] space-y-10">
                                {/* Моя организация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Профиль пользователя</p>
                                    </div>

                                    {/* Активный пользователь */}
                                    <div className="flex flex-row w-full space-x-5 pt-2">
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="flex flex-row items-center">
                                                    <div
                                                        onClick={
                                                            handleToggleIsActiveUser
                                                        }
                                                        className={`switch ${isActiveUser ? 'switch-on' : 'switch-off'} ${
                                                            !editMode
                                                                ? 'switch-disabled'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div
                                                            className={`switch-toggle ${
                                                                isActiveUser
                                                                    ? `toggle-on ${
                                                                          !editMode
                                                                              ? 'toggle-on-disabled'
                                                                              : ''
                                                                      }`
                                                                    : `toggle-off ${
                                                                          !editMode
                                                                              ? 'toggle-off-disabled'
                                                                              : ''
                                                                      }`
                                                            }`}
                                                        >
                                                            {isActiveUser ? (
                                                                <svg
                                                                    className="w-[15px] h-[15px]"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="check"
                                                                >
                                                                    <path
                                                                        fill={`${
                                                                            !editMode
                                                                                ? '#262427'
                                                                                : '#187A33'
                                                                        }`}
                                                                        d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                    ></path>
                                                                </svg>
                                                            ) : (
                                                                <svg
                                                                    className="w-[15px] h-[15px]"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 24 24"
                                                                    id="times"
                                                                >
                                                                    <path
                                                                        fill={`${
                                                                            !editMode
                                                                                ? '#262427'
                                                                                : '#656365'
                                                                        }`}
                                                                        d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                    ></path>
                                                                </svg>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <span
                                                        className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                        onClick={
                                                            handleToggleIsActiveUser
                                                        }
                                                    >
                                                        Активный пользователь
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex flex-row space-x-5">
                                        <div className="flex items-start">
                                            <div className="h-[100px] w-[100px] grid bg-[#181818] rounded-full place-items-center">
                                                <div className="flex flex-col items-center">
                                                    <button className="w-6 h-6">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 24 24"
                                                            id="image-plus"
                                                        >
                                                            <path
                                                                fill="#8A8A8A"
                                                                d="M19,10a1,1,0,0,0-1,1v3.38L16.52,12.9a2.79,2.79,0,0,0-3.93,0l-.7.71L9.41,11.12a2.79,2.79,0,0,0-3.93,0L4,12.61V7A1,1,0,0,1,5,6h8a1,1,0,0,0,0-2H5A3,3,0,0,0,2,7V19.22A2.79,2.79,0,0,0,4.78,22H17.22a2.88,2.88,0,0,0,.8-.12h0a2.74,2.74,0,0,0,2-2.65V11A1,1,0,0,0,19,10ZM5,20a1,1,0,0,1-1-1V15.43l2.89-2.89a.78.78,0,0,1,1.1,0L15.46,20Zm13-1a1,1,0,0,1-.18.54L13.3,15l.71-.7a.77.77,0,0,1,1.1,0L18,17.21ZM21,4H20V3a1,1,0,0,0-2,0V4H17a1,1,0,0,0,0,2h1V7a1,1,0,0,0,2,0V6h1a1,1,0,0,0,0-2Z"
                                                            ></path>
                                                        </svg>
                                                    </button>
                                                    <p className="text-[#8A8A8A] text-xs">
                                                        Загрузить
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col w-full space-y-3">
                                            {/* Фамилия */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Фамилия{' '}
                                                        {editMode && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        name="last_name"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'last_name'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.last_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'last_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'last_name'
                                                            )
                                                        }
                                                        placeholder="Фамилия"
                                                        disabled={!editMode}
                                                    />
                                                    {touchedInput.last_name &&
                                                        errorsInput.last_name && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.last_name
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>

                                            {/* Имя */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Имя{' '}
                                                        {editMode && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        name="first_name"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'first_name'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.first_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'first_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'first_name'
                                                            )
                                                        }
                                                        placeholder="Имя"
                                                        disabled={!editMode}
                                                    />
                                                    {touchedInput.first_name &&
                                                        errorsInput.first_name && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.first_name
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>

                                            {/* Отчество (при наличии) */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Отчество (при наличии){' '}
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        name="patronymic"
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.patronymic
                                                                ? userDataUpdate.patronymic
                                                                : ''
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'patronymic',
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Отчество"
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>

                                            {/* Дата рождения, Пол*/}
                                            <div className="flex flex-row w-full space-x-5 ">
                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Дата рождения{' '}
                                                            {isEdit && (
                                                                <span className="text-[#FF0000]">
                                                                    *
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="date"
                                                            className={`user-input`}
                                                            style={{
                                                                colorScheme:
                                                                    'dark',
                                                                border: getBorderStyle(
                                                                    'birth_day'
                                                                ),
                                                            }}
                                                            value={
                                                                userDataUpdate.birth_day
                                                                    ? formatDateToYYYYMMDD(
                                                                          userDataUpdate.birth_day
                                                                      )
                                                                    : ''
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    'birth_day',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            onBlur={() =>
                                                                handleBlur(
                                                                    'birth_day'
                                                                )
                                                            }
                                                            disabled={!editMode}
                                                        />
                                                        {touchedInput.birth_day &&
                                                            errorsInput.birth_day && (
                                                                <span
                                                                    style={{
                                                                        color: '#C15C5C',
                                                                        fontSize:
                                                                            '12.8px',
                                                                    }}
                                                                >
                                                                    {
                                                                        errorsInput.birth_day
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                                            Пол{' '}
                                                            {isEdit && (
                                                                <span className="text-[#FF0000]">
                                                                    *
                                                                </span>
                                                            )}
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <select
                                                            className={`user-input`}
                                                            style={{
                                                                colorScheme:
                                                                    'dark',
                                                                border: getBorderStyle(
                                                                    'gender'
                                                                ),
                                                            }}
                                                            value={
                                                                userDataUpdate.gender
                                                                    ? userDataUpdate.gender
                                                                    : '-'
                                                            }
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    'gender',
                                                                    e.target
                                                                        .value ==
                                                                        '-'
                                                                        ? null
                                                                        : e
                                                                              .target
                                                                              .value
                                                                )
                                                            }
                                                            onBlur={() =>
                                                                handleBlur(
                                                                    'gender'
                                                                )
                                                            }
                                                            disabled={!editMode}
                                                        >
                                                            <option value="-">
                                                                Выбрать
                                                            </option>
                                                            <option value="male">
                                                                Мужской
                                                            </option>
                                                            <option value="female">
                                                                Женский
                                                            </option>
                                                        </select>
                                                        {touchedInput.gender &&
                                                            errorsInput.gender && (
                                                                <span
                                                                    style={{
                                                                        color: '#C15C5C',
                                                                        fontSize:
                                                                            '12.8px',
                                                                    }}
                                                                >
                                                                    {
                                                                        errorsInput.gender
                                                                    }
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Общая информация */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Общая информация</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Контактный телефон, Добавочный номер */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-2/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Контактный телефон
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.phone
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'phone',
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Контактный телефон"
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-col w-1/3 space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Добавочный номер
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                        }}
                                                        value={
                                                            userDataUpdate.extension_phone
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'extension_phone',
                                                                e.target.value
                                                            )
                                                        }
                                                        placeholder="Добавочный номер"
                                                        disabled={!editMode}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Должность */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Должность{' '}
                                                    {editMode && (
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className={`user-input`}
                                                    style={{
                                                        colorScheme: 'dark',
                                                        border: getBorderStyle(
                                                            'position'
                                                        ),
                                                    }}
                                                    value={
                                                        userDataUpdate.position
                                                            ? userDataUpdate.position
                                                            : ''
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'position',
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleBlur('position')
                                                    }
                                                    placeholder="Должность"
                                                    disabled={!editMode}
                                                />
                                                {touchedInput.position &&
                                                    errorsInput.position && (
                                                        <span
                                                            style={{
                                                                color: '#C15C5C',
                                                                fontSize:
                                                                    '12.8px',
                                                            }}
                                                        >
                                                            {
                                                                errorsInput.position
                                                            }
                                                        </span>
                                                    )}
                                            </div>
                                        </div>

                                        {/* Подразделение */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Подразделение{' '}
                                                        {editMode && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'struct_guid'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.struct_guid
                                                                ? userDataUpdate.struct_guid
                                                                : '-'
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'struct_guid',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : e.target
                                                                          .value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'struct_guid'
                                                            )
                                                        }
                                                        disabled={!isEdit}
                                                    >
                                                        <option
                                                            value="-"
                                                            className="text-[#8A8A8A]"
                                                        >
                                                            Выбрать
                                                        </option>
                                                        {structData?.map(
                                                            (struct, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        struct.guid
                                                                    }
                                                                >
                                                                    {
                                                                        struct.struct_name
                                                                    }
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {touchedInput.struct_guid &&
                                                        errorsInput.struct_guid && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.struct_guid
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        {/* Роль */}
                                        <div className="flex flex-row w-full space-x-5 ">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Роль{' '}
                                                        {editMode && (
                                                            <span className="text-[#FF0000]">
                                                                *
                                                            </span>
                                                        )}
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        className={`user-input`}
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'role_guid'
                                                            ),
                                                        }}
                                                        value={
                                                            userDataUpdate.role_guid
                                                                ? userDataUpdate.role_guid
                                                                : '-'
                                                        }
                                                        onChange={(e) => {
                                                            const roleName =
                                                                getNameRole(
                                                                    e.target
                                                                        .value
                                                                )
                                                            handleInputChange(
                                                                'role_guid',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : e.target
                                                                          .value
                                                            )
                                                            handleInputChange(
                                                                'role_name',
                                                                e.target
                                                                    .value ==
                                                                    '-'
                                                                    ? null
                                                                    : roleName
                                                            )
                                                        }}
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'role_guid'
                                                            )
                                                        }
                                                        disabled={!isEdit}
                                                    >
                                                        <option value="">
                                                            Выбрать
                                                        </option>

                                                        {/* sregfsdgrgedrghr */}
                                                        {roleData.map(
                                                            (list, index) => (
                                                                <option
                                                                    key={index}
                                                                    value={
                                                                        list.guid
                                                                    }
                                                                >
                                                                    {list.name}
                                                                </option>
                                                            )
                                                        )}
                                                    </select>
                                                    {touchedInput.role_guid &&
                                                        errorsInput.role_guid && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.role_guid
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Учетные данные */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Учетные данные</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Электронная почта */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Электронная почта{' '}
                                                    {editMode && (
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    )}
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className={`user-input`}
                                                    style={{
                                                        colorScheme: 'dark',
                                                        border: getBorderStyle(
                                                            'email'
                                                        ),
                                                    }}
                                                    value={userDataUpdate.email}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'email',
                                                            e.target.value
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        handleBlur('email')
                                                    }
                                                    placeholder="email"
                                                    disabled={!editMode}
                                                />
                                                {touchedInput.email &&
                                                    errorsInput.email && (
                                                        <span
                                                            style={{
                                                                color: '#C15C5C',
                                                                fontSize:
                                                                    '12.8px',
                                                            }}
                                                        >
                                                            {errorsInput.email}
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                        {/* Пароль учетной записи */}

                                        <button
                                            className="flex flex-row w-[180px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                            onClick={handleResetPwdPopup}
                                        >
                                            Поменять пароль
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* buttons */}
                        <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 justify-end space-x-5 items-center ">
                            {editMode ? (
                                <>
                                    <button
                                        className={`flex flex-row min-w-[120px] h-6 ${hasErrors() ? 'bg-[#494849] text-[#8A8A8A]' : 'bg-green-700 hover:bg-green-600'}  w-[120px] rounded-[10px] text-xs justify-center items-center`}
                                        disabled={false}
                                        onClick={saveUpdateDataUserSelf}
                                    >
                                        {isSaving ? (
                                            <svg
                                                className="animate-spin h-4 w-4 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    className="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    strokeWidth="4"
                                                ></circle>
                                                <path
                                                    className="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                                ></path>
                                            </svg>
                                        ) : (
                                            'Сохранить'
                                        )}
                                    </button>
                                    <button
                                        className="flex flex-row min-w-[120px] h-6 rounded-[10px] text-xs justify-center items-center bg-[#494849] hover:bg-[#656365]"
                                        onClick={handleCloseEditUser}
                                    >
                                        Закрыть
                                    </button>
                                </>
                            ) : user?.object.user_id == userData.guid ? (
                                <button
                                    className="flex flex-row min-w-[120px] h-6 rounded-[10px] text-xs justify-center items-center bg-[#494849] hover:bg-[#656365]"
                                    onClick={handleEditMode}
                                >
                                    Редактировать
                                </button>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                </>
            )}
            {resetPwdPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleResetPwdPopup}
                >
                    <div
                        className="flex flex-col p-5 rounded-lg bg-[#262427] w-[500px] gap-2"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-start gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#FFBF00"
                                    d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Восстановление пароля
                            </h2>
                        </div>
                        <label className="text-[14px] text-[#8A8A8A]">
                            Для восстановления пароля введите email своей
                            учётной записи. Мы отправим на него ссылку для его
                            восстанволения.
                        </label>

                        <div className="flex flex-row w-full gap-5 items-center justify-center">
                            <div className="flex flex-col flex-1 w-[300px] gap-1">
                                <div>
                                    <label className="text-[14px] text-[#8A8A8A]">
                                        Email
                                    </label>
                                </div>
                                <div className="flex w-full space-x-5 ">
                                    <div className="flex flex-1 w-full ">
                                        <input
                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                            value={emailResetPwd}
                                            placeholder="email"
                                            onChange={(e) =>
                                                handleInputEmail(e.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row mt-5 text-right justify-end gap-3">
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={handleResetPwdPopup}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]`}
                                onClick={handleResetPwd}
                            >
                                {isResetting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Восстановить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {resetPwdDonePopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleResetPwdDonePopup}
                >
                    <div
                        className="flex flex-col p-5 rounded-lg bg-[#262427] w-[500px] gap-2"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-start gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#187A33"
                                    d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Восстановление пароля
                            </h2>
                        </div>
                        <label className="text-[14px] text-[#8A8A8A]">
                            Ссылка направлена на указанный email. Если ее нет во
                            входящих, то проверьте в спаме.
                        </label>

                        <div className="flex flex-row mt-5 text-right justify-end gap-3">
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={handleResetPwdDonePopup}
                            >
                                Хорошо
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </span>
    )
}

export default OrgUserViewOrEditPage
