import React, { useEffect, useState, useCallback } from 'react'
import PurchaseItemsTableRow from './PurchaseItemsTableRow'
import {
    Okato,
    PlanPositionRow,
    Okei,
    PurchaseItemRowErrors,
    PurchaseItemsSectionErrors,
} from '../types'
import PurchaseItemsPopup from './PurchaseItemsPopup'
import { Okpd2 } from '../../../../Org/Handbook/OKPD2/types'
import { Okved2 } from '../../../../Org/Handbook/OKVED2/types'
import { OrganizationInfo } from '../../../../Org/Home/types'

type LayoutProps = {
    planGuid: string
    posGuid: string
    posMSPStatus: boolean | null
    purchaseMethodCode: number | null
    purchaseMethodName: string | null
    showRegion: boolean
    isDraft: boolean
    onAreParticipantsSMEChange: (value: boolean) => void
    onOkpdHave: (value: boolean) => void
    disabledFieldset: boolean
    onRowsChange: (rows: PlanPositionRow[]) => void
    onHaveError: (value: boolean) => void
    errors?: PurchaseItemsSectionErrors // ошибки для каждой строки, если есть
    posTypeObjectPurchase: string
    onTypeObjectPurchasePositionChange: (name: string) => void
    orgData: OrganizationInfo | undefined
}

const debounce = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): T => {
    let timeoutId: ReturnType<typeof setTimeout>
    return ((...args: Parameters<T>) => {
        clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            func(...args)
        }, delay)
    }) as T
}

function calculateTotalCount(): number {
    const inputs = document.getElementsByName('purchase_item_count')
    let count = 0
    for (let i = 0; i < inputs.length; i++) {
        let value = (inputs[i] as HTMLInputElement).value
        if (value !== null && value !== '') {
            // Заменяем запятую на точку для корректного преобразования
            value = value.replace(',', '.')
            const num = parseFloat(value)
            if (!isNaN(num)) {
                count += num
            }
        }
    }
    // Округляем число до 4 знаков после запятой
    return Math.round(count * 10000) / 10000
}

function formatTotalCount(totalCount: number) {
    // Получаем число с 5 знаками после запятой (с округлением)
    let str = totalCount.toFixed(5)
    // Если есть десятичная часть, удаляем лишние нули
    if (str.indexOf('.') !== -1) {
        // Удаляем концевые нули
        str = str.replace(/0+$/, '')
        // Если после удаления нулей осталась точка, убираем её
        str = str.replace(/\.$/, '')
    }
    // Заменяем точку на запятую
    return str.replace('.', ',')
}

const getOkeiList = async (): Promise<Okei[]> => {
    return fetch(process.env.REACT_APP_API_URL + '/api/backend/v1/okei', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

const PurchaseItemsTable: React.FC<LayoutProps> = ({
    planGuid,
    posGuid,
    posMSPStatus,
    isDraft,
    showRegion,
    purchaseMethodCode,
    purchaseMethodName,
    onAreParticipantsSMEChange,
    onOkpdHave,
    disabledFieldset,
    onRowsChange,
    onHaveError,
    errors,
    posTypeObjectPurchase,
    onTypeObjectPurchasePositionChange,
    orgData,
}) => {
    const [isLoading, setLoading] = useState(true)
    const [rows, setRows] = useState<PlanPositionRow[]>([])

    const [totalCount, setTotalCount] = useState(0)
    const [popupType, setPopupType] = useState<[number, string] | null>(null)
    const [okeiList, setOkeiList] = useState<Okei[]>([])
    const [isFirstSelected, setFirstSelected] = useState(false)

    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false)

    const [deletePlanPositionRowsPopup, setDeletePlanPositionRowsPopup] =
        useState<boolean>(false)
    const [isDeleting, setDeleting] = useState<boolean>(false)
    const [deleteRows, setDeleteRows] = useState<PlanPositionRow[]>([])

    const [copyPlanPositionRowsPopup, setCopyPlanPositionRowsPopup] =
        useState<boolean>(false)
    const [isCopying, setCopying] = useState<boolean>(false)
    const [copyRows, setCopyRows] = useState<PlanPositionRow[]>([])

    const [debounceTimer, setDebounceTimer] = useState<NodeJS.Timeout | null>(
        null
    )

    const loadRows = async (organizationData?: OrganizationInfo | null) => {
        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_position_rows' +
                (isDraft ? '_draft' : '') +
                `/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow[]>
                } else {
                    throw new Error('Failed to fetch plan position rows')
                }
            })
            .then((results) => {
                setRows(results)
                setTotalCount(
                    results.reduce((acc, row) => acc + Number(row.qty), 0)
                )

                // Проверка включена ли настройка "Все способы закупки"
                if (organizationData?.all_purchase_method) {
                } else {
                    const emptyOKPD2 = results.filter(
                        (row) => row.okpd2_code !== null
                    )

                    if (emptyOKPD2.length != 0) {
                        setFirstSelected(true) // Есть записи где выбран ОКПД2
                        onOkpdHave(true)
                    }
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // load rows
    useEffect(() => {
        // Только после того, как orgData будет установлено, вызываем loadRows
        loadRows(orgData)
    }, [])

    const addNewRow = () => {
        setLoading(true)

        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow>
                } else {
                    throw new Error('Failed to create a new row')
                }
            })
            .then((newRow) => {
                setRows((prev) => [...prev, newRow])
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setLoading(false)
                onHaveError(true)
            })
    }

    const deleteRow = (guid: string) => {
        setLoading(true)

        // Получаем список строк перед удалением
        fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/plan_position_rows' +
                (isDraft ? '_draft' : '') +
                `/?plan_guid=${planGuid}&pos_guid=${posGuid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json() as Promise<PlanPositionRow[]>
                } else {
                    throw new Error('Failed to fetch plan position rows')
                }
            })
            .then((results) => {
                // Проверяем, остаются ли записи после удаления
                const remainingRows = results.filter((row) => row.guid !== guid)

                if (remainingRows.length == 0) {
                    setFirstSelected(false) // Если все записи удалены
                    onOkpdHave(false)
                    onAreParticipantsSMEChange(false)
                }

                // Удаляем строку
                return fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${guid}`,
                    {
                        method: 'DELETE',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                ).then((response) => {
                    if (response.ok) {
                        setRows((prev) =>
                            prev.filter((row) => row.guid !== guid)
                        )
                        setTotalCount(calculateTotalCount())
                    } else {
                        throw new Error('Failed to delete row')
                    }
                })
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
                onHaveError(true)
            })
    }

    const deleteRowsMass = async (guid: string): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to delete row. Status: ${response.status}`
                )
            }

            console.log('Row deleted successfully')
        } catch (error) {
            console.error('Error while deleting row:', error)
            setDeleting(false)
        } finally {
            onHaveError(true)
        }
    }

    const copyRow = async (guid: string): Promise<void> => {
        const url =
            process.env.REACT_APP_API_URL +
            `/api/backend/v1/plan_position_row_draft_from_row/?plan_guid=${planGuid}&pos_guid=${posGuid}&from_guid=${guid}`

        try {
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            if (!response.ok) {
                throw new Error(
                    `Failed to row position. Status: ${response.status}`
                )
            }

            console.log('Row copy successfully')
        } catch (error) {
            console.error('Error while coping position:', error)
            setDeleting(false)
        } finally {
            loadRows(orgData)
            onHaveError(true)
        }
    }

    const updateRowOnServer = useCallback(
        debounce((row: PlanPositionRow) => {
            fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${row.guid}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(row),
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(
                            `HTTP error! Status: ${response.status}`
                        )
                    }
                })
                .catch((error) => {
                    console.error('Error updating row:', error)
                })
        }, 300),
        []
    )

    const updateRowOnServerSMB = (row: PlanPositionRow) => {
        fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/plan_position_row_draft/?plan_guid=${planGuid}&pos_guid=${posGuid}&guid=${row.guid}`,
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(row),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }
            })
            .catch((error) => {
                console.error('Error updating row:', error)
            })
    }

    const updateRowsForSmb = async (isPurchaseSmb: boolean | null) => {
        const updatedRowss = await Promise.all(
            rows.map(async (row) => {
                if (!row.okpd2_code) {
                    return row
                }

                try {
                    const response = await fetch(
                        process.env.REACT_APP_API_URL +
                            `/api/backend/v1/list_gws_smb/code_search?text_query=${encodeURIComponent(row.okpd2_code)}`,
                        {
                            method: 'GET',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    )
                    const data = await response.json()

                    const isMspCode = data && data.length > 0

                    if (
                        (isPurchaseSmb && !isMspCode) ||
                        (!isPurchaseSmb && isMspCode)
                    ) {
                        console.log(row.okpd2_name)

                        const updatedRow = {
                            ...row,
                            okpd2_code: null,
                            okpd2_name: null,
                        }
                        await updateRowOnServerSMB(updatedRow)
                        return updatedRow
                    }

                    return row
                } catch (error) {
                    console.error(
                        `Error checking OKPD2 for row ${row.guid}:`,
                        error
                    )
                    return row
                }
            })
        )

        setRows(updatedRowss)
    }

    useEffect(() => {
        // Если включена настройка "Все способы закупок"
        if (!orgData?.all_purchase_method) {
            if (rows.length > 1) {
                updateRowsForSmb(posMSPStatus)
            }
        }
    }, [posMSPStatus])

    const scheduleRowUpdateOnServer = (
        guid: string,
        updates: Partial<PlanPositionRow>
    ) => {
        const updatedRow = rows.find((row) => row.guid === guid)
        if (updatedRow) {
            updateRowOnServer({ ...updatedRow, ...updates })
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const okei = await getOkeiList()
            if (okei) {
                setOkeiList(okei)
            }
        }
        fetchData()
    }, [])

    // Функция проверки кода ОКПД2 первой строки
    const checkOkpd2FirstRow = async (okpd2Code: string) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/okpd2/search_code?text_query=${encodeURIComponent(okpd2Code)}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            const data = await response.json()
            // Проверяем стоит ли в настройках "Все способы закупок"
            if (orgData?.all_purchase_method) {
            } else {
                // Проверяем единственный элемент и его is_msp
                const isSME =
                    Array.isArray(data) &&
                    data.length === 1 &&
                    data[0].is_msp === true
                onAreParticipantsSMEChange(isSME)
            }
        } catch (error) {
            console.error('Error checking OKPD2 code:', error)
            if (orgData?.all_purchase_method) {
            } else {
                onAreParticipantsSMEChange(false)
            }
        }
    }

    const handleOkpd2Selection = (
        rowIndex: number,
        okpd2Code: string | null,
        okpd2Name: string | null
    ) => {
        setRows((prevRows) =>
            prevRows.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...row,
                        okpd2_code: okpd2Code,
                        okpd2_name: okpd2Name,
                    }
                }
                return row
            })
        )

        // Проверка включена ли настройка "Все способы закупки"
        if (orgData?.all_purchase_method) {
            // Проверка только для первой строки (index === 0)
        } else {
            if (okpd2Code) {
                checkOkpd2FirstRow(okpd2Code)
                setFirstSelected(true)
                onOkpdHave(true)
            }
        }
    }

    // Функция для обработки изменения состояния отдельного checkbox
    const handleCheckboxChange = (row_guid: string) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(row_guid)) {
                return prevSelectedRows.filter((rowId) => rowId !== row_guid)
            } else {
                return [...prevSelectedRows, row_guid]
            }
        })
    }

    // Функция для обработки изменения состояния "Select All"
    const handleSelectAllChange = () => {
        setSelectAll(!selectAll)
        if (!selectAll) {
            // Выбираем только те элементы, которые соответствуют текущей стадии
            const filteredRows = rows.map((row) => row.guid)

            setSelectedRows(filteredRows)
        } else {
            // Сбрасываем выбор всех элементов
            setSelectedRows([])
        }
    }

    const handleDeletePlanPositionRowsPopup = () => {
        if (deletePlanPositionRowsPopup == false) {
            const filteredData = rows.filter((row) =>
                selectedRows.includes(row.guid)
            )
            setDeleteRows(filteredData)
        }
        setDeletePlanPositionRowsPopup(!deletePlanPositionRowsPopup)
    }

    const handleDeleteClick = async () => {
        setDeleting(true)

        let remainingDeleteRows = [...selectedRows] // Копируем массив, чтобы избежать изменения оригинала.

        for (const row of selectedRows) {
            await deleteRowsMass(row) // Отправляем запрос на удаление.
            // Уменьшаем массив, убирая удалённый элемент.
            remainingDeleteRows = remainingDeleteRows.filter((i) => i !== row)
            const filteredDeleteRows = deleteRows.filter((delRow) =>
                remainingDeleteRows.includes(delRow.guid)
            )
            setDeleteRows(filteredDeleteRows)
        }
        setSelectedRows([])
        loadRows(orgData)
        setSelectAll(false)
        setDeleting(false)
        setDeletePlanPositionRowsPopup(false)
    }

    const handleDeleteRow = (rowGuid: string) => {
        setDeleteRows((prevRows) =>
            prevRows.filter((row) => row.guid !== rowGuid)
        )
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((selectedGuid) => selectedGuid !== rowGuid)
        )
    }

    const handleCopyPlanPositionRowsPopup = () => {
        if (copyPlanPositionRowsPopup == false) {
            const filteredData = rows.filter((row) =>
                selectedRows.includes(row.guid)
            )
            setCopyRows(filteredData)
        }
        setCopyPlanPositionRowsPopup(!copyPlanPositionRowsPopup)
    }

    const handleCopyClick = async () => {
        setCopying(true)

        let remainingCopyRows = [...selectedRows] // Копируем массив, чтобы избежать изменения оригинала.

        for (const row of selectedRows) {
            await copyRow(row) // Отправляем запрос на копирование.
            remainingCopyRows = remainingCopyRows.filter((i) => i !== row)

            const filteredCopyRows = copyRows.filter((rowItem) =>
                remainingCopyRows.includes(rowItem.guid)
            )
            setCopyRows(filteredCopyRows)
        }
        setSelectedRows([])
        loadRows(orgData)
        setSelectAll(false)
        setCopying(false)
        setCopyPlanPositionRowsPopup(false)
    }

    const handleCopyRow = (rowGuid: string) => {
        setCopyRows((prevRows) =>
            prevRows.filter((row) => row.guid !== rowGuid)
        )
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.filter((selectedGuid) => selectedGuid !== rowGuid)
        )
    }

    // При изменении rows уведомляем родительский компонент
    useEffect(() => {
        onRowsChange(rows)
    }, [rows, onRowsChange])

    return (
        <fieldset disabled={!isDraft || isLoading}>
            <div
                className={`flex flex-col w-full min-w-fit bg-[#242225] rounded ${
                    errors?.rowsEmpty ? 'border border-[#C15C5C]' : ''
                }`}
                title={errors?.rowsEmpty ? errors?.rowsEmpty : ''}
            >
                {/* toolbar */}
                <div className="flex flex-row gap-1 place-items-center text-white p-2 h-10 bg-[#333033] rounded-t ">
                    <div className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]">
                        <input
                            type="checkbox"
                            className="p-2"
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                        />
                    </div>

                    {isDraft && !disabledFieldset && (
                        <>
                            {/* Добавить */}
                            <button
                                onClick={addNewRow}
                                className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                                title="Добавить строку"
                            >
                                <svg
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="plus"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                    ></path>
                                </svg>
                            </button>
                            <button
                                onClick={handleCopyPlanPositionRowsPopup}
                                className={`flex w-6 h-6 rounded-full items-center justify-center ${selectedRows.length > 0 ? 'hover:bg-[#656365]' : ''} `}
                                disabled={
                                    selectedRows.length > 0 ? false : true
                                }
                                title="Копировать"
                            >
                                <svg
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="trash-alt"
                                >
                                    <path
                                        fill={`${selectedRows.length > 0 ? '#FFFFFF' : '#FFFFFF44'} `}
                                        d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>
                            </button>
                            <button
                                onClick={handleDeletePlanPositionRowsPopup}
                                className={`flex w-6 h-6 rounded-full items-center justify-center ${selectedRows.length > 0 ? 'hover:bg-[#701F1F]' : ''}`}
                                disabled={
                                    selectedRows.length > 0 ? false : true
                                }
                                title="Удалить"
                            >
                                <svg
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="trash-alt"
                                >
                                    <path
                                        fill={`${selectedRows.length > 0 ? '#FFFFFF' : '#FFFFFF44'} `}
                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                    ></path>
                                </svg>
                            </button>
                            {!orgData?.all_purchase_method && (
                                <button
                                    onClick={() => {
                                        onAreParticipantsSMEChange(
                                            !posMSPStatus
                                        )
                                        setFirstSelected(false)
                                        onOkpdHave(false)
                                        onHaveError(true)
                                    }}
                                    className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                                    title="Очистить ОКПД2"
                                >
                                    <svg
                                        className="w-4 h-4"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        id="plus"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M14.71,12.29a1,1,0,0,0-1.42,0L12,13.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L10.59,15l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,16.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,15l1.3-1.29A1,1,0,0,0,14.71,12.29ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Z"
                                        ></path>
                                    </svg>
                                </button>
                            )}
                        </>
                    )}
                    <span className="grow"></span>

                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] items-center justify-center text-center border-r border-r-[#464447]">
                        Позиций <br />
                        {rows.length}
                    </div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] items-center justify-center text-center">
                        Количество (объем) <br />
                        {formatTotalCount(totalCount)}
                    </div>
                </div>
                {/* name */}
                <div className="flex flex-row gap-1 h-10 bg-[#2A282B] text-[#8A8A8A] text-[10px] p-1 ">
                    <div className="flex w-8 items-center justify-center text-center"></div>
                    <div className="flex w-8 items-center justify-center text-center"></div>
                    <div className="flex w-8 items-center justify-center text-center">
                        №
                    </div>
                    <div className="flex flex-1 items-center justify-center text-center min-w-72">
                        Наименование
                    </div>
                    <div
                        className="flex w-8 items-center justify-center text-center"
                        title="Невозможно определить количество"
                    >
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 120 160"
                            id="uncount"
                        >
                            <path
                                fill="#8A8A8A"
                                d="M100 57c0,0 -1,1 -1,1 0,0 0,1 -1,1 0,0 0,1 0,1 0,0 0,0 0,1 0,0 0,0 0,1 0,0 0,0 0,0 0,1 0,2 1,2 1,2 2,3 4,4 1,0 2,1 3,1 1,0 2,0 3,-1 2,-1 3,-2 4,-4 0,-1 0,-2 0,-2 0,0 0,0 0,0 0,0 0,0 0,0 0,-2 -1,-4 -2,-5 -1,-1 -3,-2 -5,-2 -2,0 -4,1 -5,2l0 0zm-12 -26l-27 -17c-1,0 -1,-1 -2,-1l-1 0c0,0 -1,0 -1,0 0,0 -1,0 -1,0l-1 0c-1,0 -2,0 -2,1l-51 32 -1 0 -1 1 -1 0 0 0 0 1 0 1 0 0c0,0 0,0 0,1 0,0 0,0 0,1l0 60c0,0 0,0 0,0 0,2 1,5 3,6l51 32 0 0 1 0 1 0c1,0 1,0 2,0 1,0 1,0 2,0l1 0 1 0 0 0 51 -31c2,-1 3,-3 3,-6 0,0 0,0 0,0l0 -35c-2,-2 -10,-3 -13,0l0 30 -38 23 0 -44 29 -18c1,-3 -4,-10 -8,-11l-28 17 -38 -23 38 -24 23 14c4,1 10,-8 8,-11zm-38 99l-38 -23 0 -44 38 23 0 43zm54 -130c0,0 0,0 0,0 -7,0 -14,4 -18,10 -1,1 -1,2 -1,4 0,4 3,7 7,7 3,0 5,-1 6,-4 1,-2 3,-3 6,-3 0,0 0,0 0,0 4,0 7,3 7,7 0,4 -3,7 -7,7 -4,0 -7,3 -7,7l0 7c0,4 3,7 7,7 4,0 7,-3 7,-7l0 -1c8,-3 13,-11 13,-19 0,-11 -9,-20 -20,-20l0 0z"
                            />
                        </svg>
                    </div>
                    <div className="flex w-28 items-center justify-center text-center">
                        Количество (объем)
                    </div>
                    <div className="flex w-32 items-center justify-center text-center">
                        Единица измерения
                    </div>
                    <div className="flex w-28 items-center justify-center text-center">
                        Тип объекта закупки
                    </div>
                    <div className="flex w-28 items-center justify-center text-center">
                        ОКПД2
                    </div>
                    <div className="flex w-28 items-center justify-center text-center">
                        ОКВЭД2
                    </div>
                    {showRegion && (
                        <div className="flex w-44 items-center justify-center text-center">
                            Регион
                        </div>
                    )}
                    <div className="flex flex-row w-fit items-center justify-center text-left gap-1">
                        <div
                            className="flex w-6 items-center justify-center text-center border-l border-[#464447] pl-1"
                            title="Закупка только у субъектов МСП"
                        >
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="uncount"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M19,6H16V5a2,2,0,0,0-2-2H10A2,2,0,0,0,8,5V6H5A3,3,0,0,0,2,9v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM10,5h4V6H10ZM20,18a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V12.39L8.68,14A1.19,1.19,0,0,0,9,14h6a1.19,1.19,0,0,0,.32-.05L20,12.39Zm0-7.72L14.84,12H9.16L4,10.28V9A1,1,0,0,1,5,8H19a1,1,0,0,1,1,1Z"
                                />
                            </svg>
                        </div>
                        <div
                            className="flex w-6 items-center justify-center text-center"
                            title="Нацрежим"
                        >
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="uncount"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M21,10a.99974.99974,0,0,0,1-1V6a.9989.9989,0,0,0-.68359-.94824l-9-3a1.002,1.002,0,0,0-.63282,0l-9,3A.9989.9989,0,0,0,2,6V9a.99974.99974,0,0,0,1,1H4v7.18427A2.99507,2.99507,0,0,0,2,20v2a.99974.99974,0,0,0,1,1H21a.99974.99974,0,0,0,1-1V20a2.99507,2.99507,0,0,0-2-2.81573V10ZM20,21H4V20a1.001,1.001,0,0,1,1-1H19a1.001,1.001,0,0,1,1,1ZM6,17V10H8v7Zm4,0V10h4v7Zm6,0V10h2v7ZM4,8V6.7207l8-2.667,8,2.667V8Z"
                                />
                            </svg>
                        </div>
                        <div
                            className="flex w-6 items-center justify-center text-center"
                            title="Закупка только в электронной форме"
                        >
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="uncount"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M19.87,8.6A1,1,0,0,0,19,8H14.42l1.27-4.74a1,1,0,0,0-.17-.87A1,1,0,0,0,14.73,2h-7a1,1,0,0,0-1,.74l-2.68,10a1,1,0,0,0,.17.87,1,1,0,0,0,.8.39H8.89L7.08,20.74a1,1,0,0,0,1.71.93l10.9-12A1,1,0,0,0,19.87,8.6Zm-9.79,8.68,1.07-4a1,1,0,0,0-.17-.87,1,1,0,0,0-.79-.39H6.35L8.49,4h4.93L12.15,8.74a1,1,0,0,0,1,1.26h3.57Z"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="flex w-8 items-center justify-center text-center"></div>
                </div>
                {/* date */}
                <div className="flex flex-col overflow-y-auto gap-1 my-1">
                    {Object.values(rows).map((row, index) => (
                        <PurchaseItemsTableRow
                            key={index}
                            index={index}
                            orgData={orgData}
                            positionRow={row}
                            showRegion={showRegion}
                            onNameChanged={(value: string) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                additional_info: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )

                                // Удаляем предыдущий таймер, если пользователь продолжает ввод
                                if (debounceTimer) {
                                    clearTimeout(debounceTimer)
                                }

                                // Устанавливаем новый таймер для обновления на сервере
                                const newTimer = setTimeout(() => {
                                    scheduleRowUpdateOnServer(row.guid, {
                                        additional_info: value,
                                    })
                                }, 500) // Задержка 1000 мс

                                setDebounceTimer(newTimer)

                                onHaveError(true)
                            }}
                            onImpossibleToDetermineAttrChanged={(
                                attr: boolean | null,
                                value_qty: number | null,
                                code_okei: string | null,
                                value_okei: string | null
                            ) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                impossible_to_determine_attr:
                                                    attr,
                                                qty: value_qty,
                                                okei_code: code_okei,
                                                okei_name: value_okei,
                                            }
                                        }
                                        return prevRow
                                    })
                                )

                                // Удаляем предыдущий таймер, если пользователь продолжает ввод
                                if (debounceTimer) {
                                    clearTimeout(debounceTimer)
                                }

                                // Устанавливаем новый таймер для обновления на сервере
                                const newTimer = setTimeout(() => {
                                    scheduleRowUpdateOnServer(row.guid, {
                                        impossible_to_determine_attr: attr,
                                        qty: value_qty,
                                        okei_code: code_okei,
                                        okei_name: value_okei,
                                    })
                                }, 500) // Задержка 1000 мс

                                setDebounceTimer(newTimer)

                                onHaveError(true)
                            }}
                            onCountChanged={(value: number | null) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                qty: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )
                                setTotalCount(calculateTotalCount())

                                // Удаляем предыдущий таймер, если пользователь продолжает ввод
                                if (debounceTimer) {
                                    clearTimeout(debounceTimer)
                                }

                                // Устанавливаем новый таймер для обновления на сервере
                                const newTimer = setTimeout(() => {
                                    scheduleRowUpdateOnServer(row.guid, {
                                        qty: value,
                                    })
                                }, 500) // Задержка 1000 мс

                                setDebounceTimer(newTimer)

                                onHaveError(true)
                            }}
                            onOkeiChanged={(
                                code: string | null,
                                value: string | null
                            ) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                okei_code: code,
                                                okei_name: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )

                                // Удаляем предыдущий таймер, если пользователь продолжает ввод
                                if (debounceTimer) {
                                    clearTimeout(debounceTimer)
                                }

                                // Устанавливаем новый таймер для обновления на сервере
                                const newTimer = setTimeout(() => {
                                    scheduleRowUpdateOnServer(row.guid, {
                                        okei_code: code,
                                        okei_name: value,
                                    })
                                }, 1000) // Задержка 1000 мс

                                setDebounceTimer(newTimer)

                                onHaveError(true)
                            }}
                            onTypePurchaseChanged={(value: string | null) => {
                                setRows((prevRows) =>
                                    prevRows.map((prevRow, idx) => {
                                        if (idx === index) {
                                            return {
                                                ...prevRow,
                                                type_object_purchase: value,
                                            }
                                        }
                                        return prevRow
                                    })
                                )

                                // Удаляем предыдущий таймер, если пользователь продолжает ввод
                                if (debounceTimer) {
                                    clearTimeout(debounceTimer)
                                }

                                // Устанавливаем новый таймер для обновления на сервере
                                const newTimer = setTimeout(() => {
                                    scheduleRowUpdateOnServer(row.guid, {
                                        type_object_purchase: value,
                                    })
                                }, 500) // Задержка 1000 мс

                                setDebounceTimer(newTimer)

                                if (!posTypeObjectPurchase) {
                                    if (value) {
                                        onTypeObjectPurchasePositionChange(
                                            value
                                        )
                                    }
                                }

                                onHaveError(true)
                            }}
                            onDeleteClick={() => {
                                // setRows(rows.filter((_, idx) => idx !== index))
                                // setTotalCount(calculateTotalCount())
                                deleteRow(row.guid)
                                onHaveError(true)
                            }}
                            onDropdownSelected={(type: string) => {
                                setPopupType([index, type])
                                onHaveError(true)
                            }}
                            okeiList={okeiList}
                            disabledFieldset={disabledFieldset}
                            selected={selectedRows.includes(row.guid)}
                            onClick={(guid: string) => {
                                handleCheckboxChange(guid)
                            }}
                            onCopy={(guid: string) => {
                                copyRow(guid)
                                onHaveError(true)
                            }}
                            errors={
                                errors?.rows ? errors.rows[index] : undefined
                            }
                        />
                    ))}
                </div>
                {/* buttons */}
                <div className="flex flex-row gap-2 h-10 p-2 bg-[#333033] rounded-b items-center">
                    {!disabledFieldset && (
                        <>
                            <button
                                className="flex flex-row gap-2 px-3 min-w-[120px] h-6 items-center justify-center rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={addNewRow}
                                title="Добавить строку"
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="add"
                                >
                                    <path
                                        fill="#FFFFFF"
                                        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                    ></path>
                                </svg>{' '}
                                Добавить строку
                            </button>
                            <button
                                className={`flex flex-row gap-2 px-3 min-w-[120px] h-6 items-center justify-center rounded-[10px] shadow-md text-xs ${selectedRows.length > 0 ? 'text-white bg-[#494849] hover:bg-[#656365]' : 'text-[#8A8A8A] bg-[#494849]'}`}
                                onClick={handleCopyPlanPositionRowsPopup}
                                title="Копировать"
                                disabled={
                                    selectedRows.length > 0 ? false : true
                                }
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="trash-alt"
                                >
                                    <path
                                        fill={`${selectedRows.length > 0 ? '#FFFFFF' : '#FFFFFF44'} `}
                                        d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"
                                    ></path>
                                </svg>{' '}
                                Копировать
                            </button>
                            <button
                                className={`flex flex-row gap-2 px-3 min-w-[120px] h-6 items-center justify-center rounded-[10px] shadow-md text-xs ${selectedRows.length > 0 ? 'text-white bg-[#494849] hover:bg-[#701F1F]' : 'text-[#8A8A8A] bg-[#494849]'} `}
                                onClick={handleDeletePlanPositionRowsPopup}
                                title="Удалить"
                                disabled={
                                    selectedRows.length > 0 ? false : true
                                }
                            >
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="trash-alt"
                                >
                                    <path
                                        fill={`${selectedRows.length > 0 ? '#FFFFFF' : '#FFFFFF44'} `}
                                        d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                    ></path>
                                </svg>{' '}
                                Удалить
                            </button>
                            {!orgData?.all_purchase_method && (
                                <button
                                    className="flex flex-row gap-2 px-3 min-w-[120px] h-6 items-center justify-center rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                    onClick={() => {
                                        onAreParticipantsSMEChange(
                                            !posMSPStatus
                                        )
                                        setFirstSelected(false)
                                        onOkpdHave(false)
                                        onHaveError(true)
                                    }}
                                    title="Очистка ОКПД2"
                                >
                                    <svg
                                        className="w-3 h-3"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 22 22"
                                        id="add"
                                    >
                                        <path
                                            fill="#FFFFFF"
                                            d="M14.71,12.29a1,1,0,0,0-1.42,0L12,13.59l-1.29-1.3a1,1,0,0,0-1.42,1.42L10.59,15l-1.3,1.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,16.41l1.29,1.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,15l1.3-1.29A1,1,0,0,0,14.71,12.29ZM20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19l-.1,0A1.1,1.1,0,0,0,13.06,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Z"
                                        ></path>
                                    </svg>{' '}
                                    Очистка ОКПД2
                                </button>
                            )}
                        </>
                    )}

                    <div className="flex flex-1 gap-1 text-[10px] text-white place-items-end text-center "></div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center border-r border-r-[#464447]">
                        Позиций <br />
                        {rows.length}
                    </div>
                    <div className="flex flex-col w-48 gap-1 text-[10px] text-[#8A8A8A] place-items-center text-center">
                        Количество (объем) <br />
                        {formatTotalCount(totalCount)}
                    </div>
                </div>

                {popupType !== null && (
                    <PurchaseItemsPopup
                        type={popupType[1]}
                        posMSPStatus={posMSPStatus}
                        orgData={orgData}
                        purchaseMethodCode={purchaseMethodCode}
                        purchaseMethodName={purchaseMethodName}
                        isFirstSelected={isFirstSelected}
                        rowsCount={rows.length}
                        onSelected={(value) => {
                            const rowIndex = popupType[0]
                            const type = popupType[1]
                            setRows((prevRows) => {
                                return prevRows.map((row, index) => {
                                    if (index === rowIndex) {
                                        return {
                                            ...row,
                                            okpd2_code:
                                                type === 'okpd2'
                                                    ? (value as Okpd2).code
                                                    : row.okpd2_code,
                                            okpd2_name:
                                                type === 'okpd2'
                                                    ? (value as Okpd2).name
                                                    : row.okpd2_name,
                                            okved2_code:
                                                type === 'okved2'
                                                    ? (value as Okved2).code
                                                    : row.okved2_code,
                                            okved2_name:
                                                type === 'okved2'
                                                    ? (value as Okved2).name
                                                    : row.okved2_name,
                                            okato:
                                                type === 'okato'
                                                    ? (value as Okato).code
                                                    : row.okato,
                                            region:
                                                type === 'okato'
                                                    ? (value as Okato).name
                                                    : row.region,
                                        }
                                    }
                                    return row
                                })
                            })

                            const a = value as Okpd2
                            if (type === 'okpd2') {
                                handleOkpd2Selection(rowIndex, a.code, a.name)
                            }

                            // close popup
                            setPopupType(null)

                            if (type === 'okpd2') {
                                const v = value as Okpd2
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okpd2_code: v.code,
                                    okpd2_name: v.name,
                                })
                            } else if (type === 'okved2') {
                                const v = value as Okved2
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okved2_code: v.code,
                                    okved2_name: v.name,
                                })
                            } else if (type === 'okato') {
                                const v = value as Okato
                                scheduleRowUpdateOnServer(rows[rowIndex].guid, {
                                    okato: v.code,
                                    region: v.name,
                                })
                            }
                        }}
                        onClose={() => setPopupType(null)}
                    />
                )}

                {deletePlanPositionRowsPopup && (
                    <div
                        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                        onClick={handleDeletePlanPositionRowsPopup}
                    >
                        <div
                            className="p-5 rounded-lg bg-[#262427] w-[800px] max-h-[600px]"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                                <svg
                                    className="w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="times-circle"
                                >
                                    <path
                                        fill="#C15C5C"
                                        d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z"
                                    ></path>
                                </svg>
                                <h2 className="text-white text-[18px]">
                                    Удаление строки
                                </h2>
                            </div>
                            <div className="flex flex-col mt-2 space-y-1">
                                <label className="text-[14px] text-white">
                                    Вы уверены, что хотите удалить данные
                                    строки? Это действие является необратимым.
                                    Продолжить?
                                </label>
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full">
                                        {/* name */}
                                        <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                            <div className="flex w-10 items-center justify-center text-center ">
                                                №
                                            </div>
                                            <div className="flex flex-1 items-center justify-center text-center ">
                                                Наименование
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                Количество (объем)
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                Единица измерения
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                ОКПД2
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                ОКВЭД2
                                            </div>
                                            <div className="flex w-7 items-center justify-center "></div>
                                        </div>
                                        {/* data */}
                                        <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                            <div className="flex flex-col w-full space-y-1">
                                                {deleteRows.map(
                                                    (row, index) => (
                                                        <div
                                                            key={row.guid}
                                                            className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                        >
                                                            <div className="flex flex-row w-full">
                                                                <div className="flex flex-row min-h-8 flex-1 gap-1 text-white text-[11px]">
                                                                    <div className="flex w-10 items-center justify-center ">
                                                                        {row.ordinal_number
                                                                            ? row.ordinal_number
                                                                            : '-'}
                                                                    </div>
                                                                    <div
                                                                        className="flex flex-1 items-center justify-left p-1 "
                                                                        title={
                                                                            row.additional_info
                                                                                ? row.additional_info
                                                                                : '-'
                                                                        }
                                                                    >
                                                                        {row.additional_info ? (
                                                                            <span className="fourrow">
                                                                                {
                                                                                    row.additional_info
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </div>
                                                                    <div className="flex w-28 items-center justify-center ">
                                                                        {row.qty
                                                                            ? row.qty
                                                                            : '-'}
                                                                    </div>
                                                                    <div className="flex w-28 items-center justify-center ">
                                                                        {row.okei_name
                                                                            ? row.okei_name
                                                                            : '-'}
                                                                    </div>
                                                                    <div
                                                                        className="flex w-28 items-center justify-center "
                                                                        title={
                                                                            row.okpd2_name
                                                                                ? row.okpd2_name
                                                                                : '-'
                                                                        }
                                                                    >
                                                                        {row.okpd2_code
                                                                            ? row.okpd2_code
                                                                            : '-'}
                                                                    </div>
                                                                    <div
                                                                        className="flex w-28 items-center justify-center "
                                                                        title={
                                                                            row.okved2_name
                                                                                ? row.okved2_name
                                                                                : '-'
                                                                        }
                                                                    >
                                                                        {row.okved2_code
                                                                            ? row.okved2_code
                                                                            : '-'}
                                                                    </div>
                                                                    <div className="flex w-8 items-center justify-center text-[#8A8A8A] ">
                                                                        <button
                                                                            onClick={() =>
                                                                                handleDeleteRow(
                                                                                    row.guid
                                                                                )
                                                                            }
                                                                            className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                                                        >
                                                                            <svg
                                                                                className="w-3 h-3"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                viewBox="0 0 24 24"
                                                                                id="trash-alt"
                                                                            >
                                                                                <path
                                                                                    fill="#FFFFFF"
                                                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                                                ></path>
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mt-5 text-right justify-end gap-2">
                                <button
                                    className={`flex px-4 py-1 min-w-[120px] h-6 rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#701F1F]  hover:bg-[#9e2c2c]`}
                                    onClick={handleDeleteClick} // Вызвать handleDelete
                                    disabled={isDeleting ? true : false}
                                >
                                    {isDeleting ? (
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        'Удалить'
                                    )}
                                </button>
                                {isDeleting ? (
                                    ''
                                ) : (
                                    <button
                                        className="px-4 py-1 min-w-[120px] h-6 rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                        onClick={
                                            handleDeletePlanPositionRowsPopup
                                        }
                                    >
                                        Отмена
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {copyPlanPositionRowsPopup && (
                    <div
                        className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                        onClick={handleCopyPlanPositionRowsPopup}
                    >
                        <div
                            className="p-5 rounded-lg bg-[#262427] w-[800px] max-h-[600px]"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                                <svg
                                    className="w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="times-circle"
                                >
                                    <path
                                        fill="#FFBF00"
                                        d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                                    ></path>
                                </svg>
                                <h2 className="text-white text-[18px]">
                                    Копирование строки
                                </h2>
                            </div>
                            <div className="flex flex-col mt-2 space-y-1">
                                <label className="text-[14px] text-white">
                                    Вы уверены, что хотите копировать следующие
                                    строки? Продолжить?
                                </label>
                                <div className="flex flex-row w-full space-x-5 pt-2">
                                    <div className="flex flex-col w-full">
                                        {/* name */}
                                        <div className="flex flex-row gap-1 bg-[#333033] text-[#8A8A8A] text-[10px] px-1 py-2 rounded-t-lg">
                                            <div className="flex w-10 items-center justify-center text-center ">
                                                №
                                            </div>
                                            <div className="flex flex-1 items-center justify-center text-center ">
                                                Наименование
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                Количество (объем)
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                Единица измерения
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                ОКПД2
                                            </div>
                                            <div className="flex w-28 items-center justify-center text-center ">
                                                ОКВЭД2
                                            </div>
                                            <div className="flex w-7 items-center justify-center "></div>
                                        </div>
                                        {/* data */}
                                        <div className="flex flex-col h-[200px] overflow-y-auto gap-1 py-1 px-1 grow bg-[#1D1C1E] rounded-b-lg">
                                            <div className="flex flex-col w-full space-y-1">
                                                {copyRows.map((row, index) => (
                                                    <div
                                                        key={row.guid}
                                                        className="flex flex-row w-full rounded bg-[#2A282B] hover:bg-[#373538]"
                                                    >
                                                        <div className="flex flex-row w-full">
                                                            <div className="flex flex-row min-h-8 flex-1 gap-1 text-white text-[11px]">
                                                                <div className="flex w-10 items-center justify-center ">
                                                                    {row.ordinal_number
                                                                        ? row.ordinal_number
                                                                        : '-'}
                                                                </div>
                                                                <div
                                                                    className="flex flex-1 items-center justify-left p-1 "
                                                                    title={
                                                                        row.additional_info
                                                                            ? row.additional_info
                                                                            : '-'
                                                                    }
                                                                >
                                                                    {row.additional_info ? (
                                                                        <span className="fourrow">
                                                                            {
                                                                                row.additional_info
                                                                            }
                                                                        </span>
                                                                    ) : (
                                                                        '-'
                                                                    )}
                                                                </div>
                                                                <div className="flex w-28 items-center justify-center ">
                                                                    {row.qty
                                                                        ? row.qty
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex w-28 items-center justify-center ">
                                                                    {row.okei_name
                                                                        ? row.okei_name
                                                                        : '-'}
                                                                </div>
                                                                <div
                                                                    className="flex w-28 items-center justify-center "
                                                                    title={
                                                                        row.okpd2_name
                                                                            ? row.okpd2_name
                                                                            : '-'
                                                                    }
                                                                >
                                                                    {row.okpd2_code
                                                                        ? row.okpd2_code
                                                                        : '-'}
                                                                </div>
                                                                <div
                                                                    className="flex w-28 items-center justify-center "
                                                                    title={
                                                                        row.okved2_name
                                                                            ? row.okved2_name
                                                                            : '-'
                                                                    }
                                                                >
                                                                    {row.okved2_code
                                                                        ? row.okved2_code
                                                                        : '-'}
                                                                </div>
                                                                <div className="flex w-8 items-center justify-center text-[#8A8A8A] ">
                                                                    <button
                                                                        onClick={() =>
                                                                            handleCopyRow(
                                                                                row.guid
                                                                            )
                                                                        }
                                                                        className=" flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                                                    >
                                                                        <svg
                                                                            className="w-3 h-3"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="trash-alt"
                                                                        >
                                                                            <path
                                                                                fill="#FFFFFF"
                                                                                d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                                            ></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row mt-5 text-right justify-end">
                                {isCopying ? (
                                    ''
                                ) : (
                                    <button
                                        className="px-4 py-1 min-w-[120px] h-6 rounded-[10px] shadow-md text-xs text-white bg-[#494849] hover:bg-[#656365]"
                                        onClick={
                                            handleCopyPlanPositionRowsPopup
                                        }
                                    >
                                        Отмена
                                    </button>
                                )}
                                <button
                                    className={`flex px-4 py-1 min-w-[120px] h-6 rounded-[10px] shadow-md text-xs text-white ml-2 items-center justify-center bg-[#187A33] hover:bg-[#1D9A40]`}
                                    onClick={handleCopyClick} // Вызвать handleCopy
                                    disabled={isCopying ? true : false}
                                >
                                    {isCopying ? (
                                        <svg
                                            className="animate-spin h-4 w-4 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        'Копировать'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </fieldset>
    )
}

export default PurchaseItemsTable
