import React, { useState, useEffect } from 'react'
import { PlanPosition } from '../../types'

interface PositionsStageCountProps {
    planPosition: PlanPosition
}

const POSITION_STATUS = new Map([
    ['P', 'Размещена'],
    ['A', 'Аннулирована'],
    ['N', 'Новая'],
    ['C', 'Изменение'],
])

const POSITION_DRAFT_STATUS = new Map([
    ['P', 'Размещение'],
    ['A', 'Аннулирование позиции'],
    ['N', 'Новая позиция'],
    ['C', 'Изменение позиции'],
])

const formatNumber = (num: number | string | null | undefined): string => {
    if (num === null || num === undefined) return '-' // Обрабатываем пустые значения

    const parsedNum = typeof num === 'number' ? num : parseFloat(num)

    if (isNaN(parsedNum)) {
        return '-' // Обрабатываем некорректные значения
    }

    return parsedNum
        .toFixed(2) // Два знака после запятой
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Разделяем тысячные пробелами
        .replace('.', ',') // Заменяем точку на запятую
}

const PositionsInfo: React.FC<PositionsStageCountProps> = ({
    planPosition,
}) => {
    return (
        <>
            <div className="relative flex flex-row w-[1710px] h-full gap-2 bg-[#111111] items-center">
                <div className="flex flex-col flex-1 h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        Предмет договора
                    </div>
                    <div
                        className="flex w-full h-full items-start justify-start text-xs text-white"
                        title={planPosition.contract_subject}
                    >
                        {planPosition.contract_subject ? (
                            <p className="tworow">
                                {planPosition.contract_subject}
                            </p>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-[1px] h-[30px] mx-2 bg-[#2A282B]"></div>
                <div className="flex flex-col w-[100px] h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        НМЦД
                    </div>
                    <div className="flex w-full h-full items-start justify-start text-xs text-white">
                        {formatNumber(planPosition.maximum_contract_price)}
                    </div>
                </div>
                <div className="flex flex-col w-[1px] h-[30px] mx-2 bg-[#2A282B]"></div>
                <div className="flex flex-col w-[300px] h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        Способ закупки
                    </div>
                    <div
                        className="flex w-full h-full items-start justify-start text-xs text-white"
                        title={planPosition.purchase_method_name}
                    >
                        {planPosition.purchase_method_name ? (
                            <p className="tworow">
                                {planPosition.purchase_method_name}
                            </p>
                        ) : (
                            '-'
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-[1px] h-[30px] mx-2 bg-[#2A282B]"></div>
                <div className="flex flex-col w-[100px] h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        Номер позиции
                    </div>
                    <div className="flex w-full h-full items-start justify-start text-xs text-white">
                        {planPosition.ordinal_number
                            ? planPosition.ordinal_number
                            : '-'}
                    </div>
                </div>
                <div className="flex flex-col w-[1px] h-[30px] mx-2 bg-[#2A282B]"></div>
                <div className="flex flex-col w-[100px] h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        Статус в плане
                    </div>
                    <div className="flex w-full h-full items-start justify-start text-xs text-white">
                        {planPosition.position_stage == 'План закупки в ЕИС'
                            ? POSITION_STATUS.get(String(planPosition.status))
                            : POSITION_DRAFT_STATUS.get(
                                  String(planPosition.status)
                              )}
                    </div>
                </div>
                <div className="flex flex-col w-[1px] h-[30px] mx-2 bg-[#2A282B]"></div>
                <div className="flex flex-col w-[100px] h-full mx-2 py-2 items-start justify-start">
                    <div className="flex w-full h-fit items-start justify-start text-xs text-[#8A8A8A]">
                        Редакция
                    </div>
                    <div className="flex w-full h-full items-start justify-start text-xs text-white">
                        <p className="threerow">
                            {planPosition.position_version}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PositionsInfo
