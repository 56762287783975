import React from 'react'

import TableCellWithPopup from './components/TableCellWithPopup'
import PopupMenuPositionStatus from './components/PopupMenuPositionStatus'
import PopupMenuApproval from './components/PopupMenuApproval'
import PopupMenuContractSubject from './components/PopupMenuContractSubject'
import PopupMenuMaximumContractPrice from './components/PopupMenuMaximumContractPrice'
import PopupMenuDatePurchase from './components/PopupMenuDatePurchase'
import PopupMenuDateContractEnd from './components/PopupMenuDateContractEnd'
import PopupMenuPurchaseMethod from './components/PopupMenuPurchaseMethod'
import PopupMenuStatusInPlan from './components/PopupMenuStatusInPlan'
import PopupMenuTypeObjectPurchase from './components/PopupMenuTypeObjectPurchase'
import PopupMenuStruct from './components/PopupMenuStruct'
import PopupMenuDateCreated from './components/PopupMenuDateCreated'
import PopupMenuUser from './components/PopupMenuUser'

import { UserFilter, SortOrder } from './FilterTypes'

import { PurchaseMethodType } from '../../Edit/types'

type Props = {
    stage: string
    hasScroll: boolean
    filter: UserFilter | null
    onFilterErase: () => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "Статус согласования"
    onChangeFilterSettingCriteriaApprovalStatusSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaApprovalStatus: (
        values: (number | null)[] | null
    ) => void

    // Фильтрация по столбцу "Статус позиции"
    onChangeFilterSettingCriteriaPositionStatusSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaPositionStatus: (
        values: string[] | null
    ) => void

    // Фильтрация по столбцу "Предмет догвоора"
    onChangeFilterSettingCriteriaContractSubjectSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaContractSubject: (
        values: string | null
    ) => void

    // Фильтрация по столбцу "НМЦД"
    onChangeFilterSettingCriteriaMaximumContractPriceSort: (
        value: SortOrder
    ) => void
    onChangeFilterSettingCriteriaMaximumContractPrice: (
        valueMin: number | null,
        valueMax: number | null
    ) => void

    // Фильтрация по столбцу "Дата размещения"
    onChangeFilterSettingCriteriaPurchasePlannedDateSort: (
        value: SortOrder
    ) => void
    onChangeFilterSettingCriteriaPurchasePlannedDate: (
        valueFrom: string | null,
        valueTo: string | null
    ) => void

    // Фильтрация по столбцу "Срок исполнения"
    onChangeFilterSettingCriteriaContractEndDateSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaContractEndDate: (
        valueFrom: string | null,
        valueTo: string | null
    ) => void

    // Фильтрация по столбцу "Способ закупки"
    onChangeFilterSettingCriteriaPurchaseMethodCodeSort: (
        value: SortOrder
    ) => void
    onChangeFilterSettingCriteriaPurchaseMethodCode: (
        values: (number | null)[] | null
    ) => void

    // Фильтрация по столбцу "Статус в плане"
    onChangeFilterSettingCriteriaStatusSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaStatus: (values: string[] | null) => void

    // Фильтрация по столбцу "Статус в плане"
    onChangeFilterSettingCriteriaTypeObjectPurchasePositionSort: (
        value: SortOrder
    ) => void
    onChangeFilterSettingCriteriaTypeObjectPurchasePosition: (
        values: (string | null)[] | null
    ) => void

    // Фильтрация по столбцу "Способ закупки"
    onChangeFilterSettingCriteriaStructGuidSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaStructGuid: (
        values: (string | null)[] | null
    ) => void

    // Фильтрация по столбцу "Дата создания"
    onChangeFilterSettingCriteriaCreatedDateSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaCreatedDate: (
        valueFrom: string | null,
        valueTo: string | null
    ) => void

    // Фильтрация по столбцу "Пользователь"
    onChangeFilterSettingCriteriaUserGuidSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaUserGuid: (
        values: (string | null)[] | null
    ) => void
}

const TableHeaderDraft: React.FC<Props> = (props) => {
    return (
        <div className="flex flex-row gap-1 items-center justify-center bg-[#262427] text-[#8A8A8A] text-[10px] p-1 ">
            <div className="flex w-8 items-center justify-center text-center h-full rounded-[10px] p-1">
                {props.filter?.filter_settings.filter_enabled ? (
                    <button
                        className={`flex w-fit h-fit items-center justify-center rounded-full p-1 ${props.filter?.filter_settings.criteria != null ? 'hover:bg-[#464447]' : ''}`}
                        title="Очистить фильтр"
                        disabled={
                            props.filter?.filter_settings.criteria != null
                                ? false
                                : true
                        }
                        onClick={() => {
                            props.onFilterErase()
                        }}
                    >
                        <svg
                            className="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 30 30"
                            id="eraser"
                            fill={`${props.filter?.filter_settings.criteria != null ? '#FFFFFF' : '#8A8A8A'}`}
                        >
                            <g>
                                <path
                                    d="M28.7,8.9l-5.7-5.7c-1.1-1.1-3.1-1.1-4.2,0l-7.1,7.1c0,0,0,0,0,0s0,0,0,0l-7.5,7.5c-1.2,1.2-1.2,3.1,0,4.2l3.8,3.8
                       c0.2,0.2,0.4,0.3,0.7,0.3h6.6c0.3,0,0.5-0.1,0.7-0.3l12.7-12.7c0,0,0,0,0,0C29.9,12,29.9,10.1,28.7,8.9z M14.9,24.1H9.2l-3.5-3.5
                       c-0.4-0.4-0.4-1,0-1.4l6.8-6.8l7.1,7.1L14.9,24.1z"
                                />
                                <path d="M27,28H5c-0.6,0-1,0.4-1,1s0.4,1,1,1h22c0.6,0,1-0.4,1-1S27.6,28,27,28z" />
                            </g>
                        </svg>
                    </button>
                ) : (
                    ''
                )}
            </div>
            <div className="flex w-12 items-center justify-center text-center h-full rounded-[10px] p-1">
                №
            </div>
            <div className="flex flex-col w-10 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuApproval}
                        stage={props.stage}
                        popupProps={{
                            criteriaApprovalStatus:
                                props.filter.filter_settings.criteria
                                    ?.approval_status,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaApprovalStatusSort:
                                props.onChangeFilterSettingCriteriaApprovalStatusSort,
                            onChangeFilterSettingCriteriaApprovalStatus:
                                props.onChangeFilterSettingCriteriaApprovalStatus,
                        }}
                    >
                        <div
                            className="flex w-fit h-full items-center justify-center text-center "
                            title="Статус согласования"
                        >
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                <svg
                                    className="w-4 h-4"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    id="approval_status"
                                >
                                    <path
                                        fill="#8A8A8A"
                                        d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,13.05,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-3.71-6.71L11,15.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.approval_status?.values ||
                            props.filter?.filter_settings.criteria
                                ?.approval_status?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div
                        className="flex w-fit h-full items-center justify-center text-center "
                        title="Статус согласования"
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                id="user-circle"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,13.05,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-3.71-6.71L11,15.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                )}
            </div>
            <div className="flex w-12 items-center justify-center text-center h-full rounded-[10px] p-1">
                Номер в ЕИС
            </div>
            <div className="flex w-24 items-center justify-center text-center h-full rounded-[10px] p-1">
                Стадия
            </div>

            <div className="flex flex-col w-24 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuPositionStatus}
                        stage={props.stage}
                        popupProps={{
                            criteriaPositionStatus:
                                props.filter.filter_settings.criteria
                                    ?.position_status,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaPositionStatusSort:
                                props.onChangeFilterSettingCriteriaPositionStatusSort,
                            onChangeFilterSettingCriteriaPositionStatus:
                                props.onChangeFilterSettingCriteriaPositionStatus,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Статус
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.position_status?.values ||
                            props.filter?.filter_settings.criteria
                                ?.position_status?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Статус
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col flex-1 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuContractSubject}
                        stage={props.stage}
                        popupProps={{
                            criteriaContractSubject:
                                props.filter.filter_settings.criteria
                                    ?.contract_subject,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaContractSubjectSort:
                                props.onChangeFilterSettingCriteriaContractSubjectSort,
                            onChangeFilterSettingCriteriaContractSubject:
                                props.onChangeFilterSettingCriteriaContractSubject,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Предмет договора
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.contract_subject?.query ||
                            props.filter?.filter_settings.criteria
                                ?.contract_subject?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Предмет договора
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col w-28 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuMaximumContractPrice}
                        stage={props.stage}
                        popupProps={{
                            criteriaMaximumContractPrice:
                                props.filter.filter_settings.criteria
                                    ?.maximum_contract_price,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaMaximumContractPriceSort:
                                props.onChangeFilterSettingCriteriaMaximumContractPriceSort,
                            onChangeFilterSettingCriteriaMaximumContractPrice:
                                props.onChangeFilterSettingCriteriaMaximumContractPrice,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                НМЦД
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.maximum_contract_price?.min ||
                            props.filter?.filter_settings.criteria
                                ?.maximum_contract_price?.max ||
                            props.filter?.filter_settings.criteria
                                ?.maximum_contract_price?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            НМЦД
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col w-28 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuDatePurchase}
                        stage={props.stage}
                        popupProps={{
                            criteriaPurchasePlannedDate:
                                props.filter.filter_settings.criteria
                                    ?.purchase_planned_date,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaPurchasePlannedDateSort:
                                props.onChangeFilterSettingCriteriaPurchasePlannedDateSort,
                            onChangeFilterSettingCriteriaPurchasePlannedDate:
                                props.onChangeFilterSettingCriteriaPurchasePlannedDate,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Дата (период) размещения
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.purchase_planned_date?.from ||
                            props.filter?.filter_settings.criteria
                                ?.purchase_planned_date?.to ||
                            props.filter?.filter_settings.criteria
                                ?.purchase_planned_date?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Дата (период) размещения
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col w-28 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuDateContractEnd}
                        stage={props.stage}
                        popupProps={{
                            criteriaContractEndDate:
                                props.filter.filter_settings.criteria
                                    ?.contract_end_date,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaContractEndDateSort:
                                props.onChangeFilterSettingCriteriaContractEndDateSort,
                            onChangeFilterSettingCriteriaContractEndDate:
                                props.onChangeFilterSettingCriteriaContractEndDate,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Срок исполнения договора
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.contract_end_date?.from ||
                            props.filter?.filter_settings.criteria
                                ?.contract_end_date?.to ||
                            props.filter?.filter_settings.criteria
                                ?.contract_end_date?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Срок исполнения договора
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col w-44 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuPurchaseMethod}
                        stage={props.stage}
                        popupProps={{
                            criteriaPurchaseMethodCode:
                                props.filter.filter_settings.criteria
                                    ?.purchase_method_code,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaPurchaseMethodCodeSort:
                                props.onChangeFilterSettingCriteriaPurchaseMethodCodeSort,
                            onChangeFilterSettingCriteriaPurchaseMethodCode:
                                props.onChangeFilterSettingCriteriaPurchaseMethodCode,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Способ закупки
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.purchase_method_code?.values ||
                            props.filter?.filter_settings.criteria
                                ?.purchase_method_code?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Способ закупки
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col w-24 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuStatusInPlan}
                        stage={props.stage}
                        popupProps={{
                            criteriaStatus:
                                props.filter.filter_settings.criteria?.status,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaStatusSort:
                                props.onChangeFilterSettingCriteriaStatusSort,
                            onChangeFilterSettingCriteriaStatus:
                                props.onChangeFilterSettingCriteriaStatus,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Статус в плане закупки
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria?.status
                                ?.values ||
                            props.filter?.filter_settings.criteria?.status
                                ?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Статус в плане закупки
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col w-24 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuTypeObjectPurchase}
                        stage={props.stage}
                        popupProps={{
                            criteriaTypeObjectPurchasePosition:
                                props.filter.filter_settings.criteria
                                    ?.type_object_purchase_position,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaTypeObjectPurchasePositionSort:
                                props.onChangeFilterSettingCriteriaTypeObjectPurchasePositionSort,
                            onChangeFilterSettingCriteriaTypeObjectPurchasePosition:
                                props.onChangeFilterSettingCriteriaTypeObjectPurchasePosition,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Тип объекта закупки
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.type_object_purchase_position?.values ||
                            props.filter?.filter_settings.criteria
                                ?.type_object_purchase_position?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Тип объекта закупки
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-col w-24 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuStruct}
                        stage={props.stage}
                        popupProps={{
                            criteriaStructGuid:
                                props.filter.filter_settings.criteria
                                    ?.struct_guid,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaStructGuidSort:
                                props.onChangeFilterSettingCriteriaStructGuidSort,
                            onChangeFilterSettingCriteriaStructGuid:
                                props.onChangeFilterSettingCriteriaStructGuid,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Подразделение
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria?.struct_guid
                                ?.values ||
                            props.filter?.filter_settings.criteria?.struct_guid
                                ?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Подразделение
                        </div>
                    </div>
                )}
            </div>
            <div className="flex w-20 items-center justify-center text-center h-full rounded-[10px] p-1">
                Редакция
            </div>
            <div className="flex flex-col w-20 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuDateCreated}
                        stage={props.stage}
                        popupProps={{
                            criteriaCreatedDate:
                                props.filter.filter_settings.criteria
                                    ?.sys_updated_at,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaCreatedDateSort:
                                props.onChangeFilterSettingCriteriaCreatedDateSort,
                            onChangeFilterSettingCriteriaCreatedDate:
                                props.onChangeFilterSettingCriteriaCreatedDate,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                Дата изменения
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria
                                ?.sys_updated_at?.from ||
                            props.filter?.filter_settings.criteria
                                ?.sys_updated_at?.to ||
                            props.filter?.filter_settings.criteria
                                ?.sys_updated_at?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            Дата изменения
                        </div>
                    </div>
                )}
            </div>

            <div className="flex flex-col w-14 h-full items-center justify-center text-center">
                {props.filter?.filter_settings.filter_enabled ? (
                    <TableCellWithPopup
                        PopupComponent={PopupMenuUser}
                        stage={props.stage}
                        popupProps={{
                            criteriaUserGuid:
                                props.filter.filter_settings.criteria
                                    ?.user_guid,
                            onAcceptFilter: props.onAcceptFilter,
                            onChangeFilterSettingCriteriaUserGuidSort:
                                props.onChangeFilterSettingCriteriaUserGuidSort,
                            onChangeFilterSettingCriteriaUserGuid:
                                props.onChangeFilterSettingCriteriaUserGuid,
                        }}
                    >
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <div className="flex w-fit h-full items-center justify-center text-center ">
                                <svg
                                    className="w-5 h-5"
                                    xmlns="http://www.w3.org/2000/svg"
                                    data-name="Layer 1"
                                    viewBox="0 0 24 24"
                                    id="user-circle"
                                >
                                    <path
                                        fill="#8A8A8A"
                                        d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div>
                            {props.filter?.filter_settings.criteria?.user_guid
                                ?.values ||
                            props.filter?.filter_settings.criteria?.user_guid
                                ?.sort_order ? (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="filter"
                                >
                                    <path
                                        fill="#4e9aff"
                                        d="M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"
                                    ></path>
                                </svg>
                            ) : (
                                <svg
                                    className="w-3 h-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    id="angle-down"
                                >
                                    <g transform="rotate(90 12 12)">
                                        <path
                                            fill="#8A8A8A"
                                            d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"
                                        ></path>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </TableCellWithPopup>
                ) : (
                    <div className="flex w-fit h-full items-center justify-center text-center ">
                        <div className="flex w-fit h-full items-center justify-center text-center ">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                id="user-circle"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                )}
            </div>
            {props.hasScroll ? (
                <div className="flex w-3 items-center justify-center text-center h-full rounded-[10px] p-1"></div>
            ) : (
                ''
            )}
        </div>
    )
}

export default TableHeaderDraft
