import React, { useState, useEffect } from 'react'

const COOKIE_CONSENT_KEY = 'cookieConsent'

const CookieConsent: React.FC = () => {
    const [isVisible, setIsVisible] = useState<boolean>(false)

    useEffect(() => {
        const consent = sessionStorage.getItem(COOKIE_CONSENT_KEY)
        if (!consent) {
            setIsVisible(true)
        }
    }, [])

    const handleAccept = () => {
        sessionStorage.setItem(COOKIE_CONSENT_KEY, 'accepted')
        setIsVisible(false)
    }

    const handleDecline = () => {
        sessionStorage.setItem(COOKIE_CONSENT_KEY, 'declined')
        setIsVisible(false)
    }

    if (!isVisible) return null

    return (
        <div className="absolute right-0 bottom-0 flex items-end justify-end z-50 p-36">
            <div className="flex flex-row bg-white p-4 rounded-lg shadow-lg text-start w-fit transition-all duration-300 scale-100 opacity-100 gap-4">
                <div className="flex flex-col w-fit gap-2 items-start justify-start text-left">
                    <h2 className="flex text-xs font-bold">
                        Использование Cookies
                    </h2>
                    <p className="text-gray-600 text-xs">
                        Продолжая использовать наш сайт, вы соглашаетесь на
                        обработку файлов
                        <br /> Cookie в соответствии с нашей{' '}
                        <a
                            className="text-sky-600 hover:text-sky-400"
                            href="#/info/agreement"
                            target="_blank"
                        >
                            Политикой конфиденциальности
                        </a>
                    </p>
                    <button
                        onClick={handleAccept}
                        className="flex w-[120px] px-4 py-1 mt-1 rounded-[10px] shadow-md items-center justify-center text-xs bg-green-500 text-white hover:bg-green-600"
                    >
                        Хорошо
                    </button>
                </div>

                <svg
                    className="flex items-center justify-center"
                    xmlns="http://www.w3.org/2000/svg"
                    width="90px"
                    height="90px"
                    viewBox="0 0 100 100"
                >
                    <g id="Cookie">
                        <g id="cookie_2940727418992">
                            <path
                                fill="#F1BE7D"
                                d="M54.52 5.07c9.11,0 24.36,4.04 32,14.21 14.11,18.77 13.04,32.54 9.71,46.04 -1.82,7.38 -8.44,17.46 -17.77,22.9 -8.83,5.15 -15.71,5.27 -24.7,5.27 -8.2,0 -22.97,-4.61 -33.15,-10.43 -13.43,-7.67 -19.69,-15.85 -19.18,-28.05 0.99,-23.5 10.71,-32.66 24.77,-41.86 8.61,-5.64 20.4,-8.08 28.32,-8.08z"
                            />
                            <path
                                fill="#DD934A"
                                d="M89.02 22.56c0.85,4.24 2.46,9.13 2.89,11.69 1.66,9.84 -1.38,11.96 -1.88,17.89 -0.61,7.13 -1.11,13.75 -5.62,21.37 -4.76,8.03 -16.89,13.4 -28.45,14.23 -13.49,0.98 -12.51,-2.5 -20.41,-4.24 -9.45,-2.08 -20.38,-6.76 -29.56,-12.49 3.64,4.64 8.63,8.84 15.1,12.54 10.19,5.82 24.96,10.43 33.16,10.43 8.98,0 15.87,-0.13 24.7,-5.27 9.33,-5.44 15.95,-15.53 17.77,-22.91 3.16,-12.81 4.28,-25.88 -7.7,-43.24z"
                            />
                            <path
                                fill="#AA6931"
                                d="M97.18 39.12c-0.04,2.1 -0.18,4.39 -0.57,6.84 -0.68,4.26 -1.89,9.95 -3.9,14.35 -2.38,5.25 -5.74,9.18 -8.61,13.7 -3.91,6.17 -11.02,15.01 -19.38,17.71 -2.76,0.9 -5.64,1.63 -8.53,2.25 8.06,-0.04 14.57,-0.49 22.76,-5.26 9.33,-5.44 15.95,-15.53 17.77,-22.91 2.07,-8.4 3.26,-16.91 0.46,-26.68z"
                            />
                            <path
                                fill="#5E382D"
                                d="M62.33 14.2c0.65,-0.16 1.6,0.01 1.84,0.95 0.23,0.94 -0.81,1.52 -1.61,1.69 -0.8,0.17 -2.72,0.18 -2.72,-0.49 0,-0.68 1.83,-1.99 2.49,-2.15z"
                            />
                            <path
                                fill="#5E382D"
                                d="M24.52 24.38c-1.6,-0.53 -2.34,-1.34 -2.14,-2.04 0.21,-0.7 1.04,-1.56 2.14,-1.51 1.1,0.04 2.12,0.7 2.46,1.63 0.38,1.05 -0.74,2.49 -2.46,1.92z"
                            />
                            <path
                                fill="#5E382D"
                                d="M31.9 18.15c-0.37,0.24 -1,0.35 -1.36,-0.19 -0.35,-0.53 0.17,-1.12 0.63,-1.4 0.46,-0.29 1.83,-0.97 1.98,-0.55 0.15,0.42 -0.88,1.9 -1.25,2.14z"
                            />
                            <path
                                fill="#5E382D"
                                d="M20.32 27.93c-0.41,1.64 -1.06,2.65 -1.77,2.5 -0.72,-0.15 -0.69,-0.25 -0.73,-1.35 -0.04,-1.1 0.09,-2.4 0.99,-2.82 1.03,-0.46 1.95,-0.08 1.51,1.67z"
                            />
                            <path
                                fill="#5E382D"
                                d="M26.49 32.38c-0.05,0.53 -1.5,2.7 -2.26,2.75 -0.75,0.04 -1.45,-0.73 -1.36,-1.11 0.08,-0.38 1.45,-2.81 2.31,-3.12 0.97,-0.33 1.35,0.87 1.31,1.48z"
                            />
                            <path
                                fill="#5E382D"
                                d="M10.95 39.07c-0.02,0.38 -0.95,1.64 -1.73,1.82 -0.79,0.18 -1.05,-0.26 -0.97,-0.64 0.09,-0.38 0.76,-1.76 1.65,-1.99 0.34,-0.09 1.06,0.2 1.05,0.81z"
                            />
                            <path
                                fill="#5E382D"
                                d="M12.27 48.07c-0.06,0.61 -2.06,7.12 -2.78,7.49 -0.72,0.37 -2.46,-2.03 -2.58,-3.16 -0.13,-1.12 2.59,-5.64 3.62,-5.69 1.04,-0.05 1.79,0.76 1.74,1.36z"
                            />
                            <path
                                fill="#42292C"
                                d="M12.27 48.07c-0.06,0.6 -1.99,6.89 -2.74,7.46 0.37,-2.75 1.45,-6.19 1.46,-7.16 0.02,-1.08 -0.37,-1.37 -0.59,-1.63 0.05,-0.02 0.09,-0.03 0.13,-0.03 1.04,-0.05 1.79,0.76 1.74,1.36z"
                            />
                            <path
                                fill="#5E382D"
                                d="M21.89 49.92c0.3,-0.65 0.95,-0.92 1.53,-0.66 0.59,0.25 0.95,0.68 0.7,1.38 -0.25,0.69 -1.41,2.17 -1.77,1.9 -0.35,-0.27 -0.77,-1.97 -0.46,-2.62z"
                            />
                            <path
                                fill="#5E382D"
                                d="M11.59 61.43c-0.01,-0.86 0.44,-1.49 1.08,-1.53 0.64,-0.04 1.56,0.92 1.65,1.8 0.16,1.48 -0.87,3.46 -1.36,2.94 -0.31,-0.34 -1.35,-2.36 -1.37,-3.21z"
                            />
                            <path
                                fill="#5E382D"
                                d="M17.43 73.81c-0.44,-0.34 -0.94,-0.96 -0.56,-1.47 0.39,-0.51 1.48,-0.5 2.2,0 1.22,0.85 1.57,2.04 1.06,2.23 -0.42,0.16 -2.26,-0.42 -2.7,-0.76z"
                            />
                            <path
                                fill="#5E382D"
                                d="M26.92 73.94c-0.25,-0.54 0.1,-1.43 0.31,-1.7 0.2,-0.27 0.63,-0.7 1.24,-0.86 0.61,-0.16 1.19,-0.14 1.75,0.25 0.55,0.38 1.78,1.76 1.91,2.58 0.12,0.81 -0.05,1.26 -0.67,1.47 -0.89,0.3 -4.1,-0.74 -4.54,-1.74z"
                            />
                            <path
                                fill="#5E382D"
                                d="M45.11 41.04c-0.61,-0.58 -0.9,-0.8 -0.56,-1.47 0.16,-0.3 0.59,-0.47 1.02,-0.45 0.42,0.02 0.82,0.23 1.18,0.44 1.28,0.77 2.13,1.44 2.16,1.98 0.03,0.38 -0.58,0.52 -1.52,0.38 -0.77,-0.11 -1.82,-0.45 -2.28,-0.88z"
                            />
                            <path
                                fill="#5E382D"
                                d="M62.15 66.31c-0.02,0.34 -1.6,1.99 -2.1,2.02 -0.49,0.03 -1.11,-0.54 -1.07,-1.05 0.04,-0.5 1.13,-1.51 1.69,-1.71 0.63,-0.22 1.51,0.34 1.48,0.74z"
                            />
                            <path
                                fill="#5E382D"
                                d="M69.42 78.56c-0.56,0.03 -2.92,-1.41 -2.72,-2.13 0.19,-0.71 1.6,-1.99 2.26,-1.99 0.67,-0.01 1.54,1.11 1.65,1.72 0.16,0.86 -0.46,2.37 -1.19,2.4z"
                            />
                            <path
                                fill="#5E382D"
                                d="M76.83 68.74c1.17,0.07 2.18,1.94 1.32,2.58 -0.13,0.1 -6,1.14 -6.49,0.47 -0.54,-0.76 0.97,-1.64 1.78,-2.13 0.67,-0.4 2.76,-0.96 3.39,-0.92z"
                            />
                            <path
                                fill="#5E382D"
                                d="M81.3 54.01c0.06,-0.57 1.53,-2.21 2.23,-2.16 0.71,0.04 1.58,1.11 1.45,2.1 -0.13,0.99 -1.67,1.9 -2.28,1.91 -0.88,0.02 -1.48,-1.13 -1.4,-1.85z"
                            />
                            <path
                                fill="#5E382D"
                                d="M89.65 57.18c0.44,-0.12 1.14,-0.11 1.37,0.19 0.22,0.28 0.21,0.72 0.03,1.17 -0.02,0.05 -0.53,0.82 -1.25,1.55 -1.12,1.12 -2.8,2.3 -3.23,2.1 -0.38,-0.18 -0.64,-0.6 -0.32,-1.17 0.36,-0.64 2.85,-3.67 3.4,-3.84z"
                            />
                            <path
                                fill="#44281A"
                                d="M91.23 63.86c0.26,-0.37 1.05,-0.28 1.31,0 0.25,0.25 0.31,0.82 0.18,1.28 -0.33,1.14 -3.2,5.28 -3.86,5.05 -0.39,-0.14 -0.85,-0.49 -0.83,-1 0,-0.2 0.78,-1.21 1.49,-2.37 0.76,-1.23 1.46,-2.61 1.71,-2.96z"
                            />
                            <path
                                fill="#44281A"
                                d="M91.2 71.67c-0.46,1.32 -3.7,2.82 -3.49,1.24 0.07,-0.5 2.36,-2.24 2.92,-2.43 0.65,-0.23 0.67,0.9 0.57,1.19z"
                            />
                            <path
                                fill="#44281A"
                                d="M78.04 82.28c0.31,-0.32 1.08,-0.12 1.3,0.2 0.2,0.29 0.19,0.86 -0.03,1.29 -0.28,0.58 -1.5,2.12 -3,3.47 -1.6,1.43 -3.47,2.63 -3.74,2.41 -0.33,-0.27 -0.75,-0.67 -0.56,-1.15 0.14,-0.35 3,-2.53 3.89,-3.56 0.94,-1.1 1.85,-2.35 2.14,-2.66z"
                            />
                            <path
                                fill="#5E382D"
                                d="M54.45 89.42c-0.78,-0.51 -1.84,-2.15 -0.66,-2.33 1.21,-0.18 4.43,0.45 5.42,0.59 0.82,0.1 0.89,0.83 0.59,1.42 -0.56,1.08 -4.57,0.83 -5.35,0.32z"
                            />
                            <path
                                fill="#44281A"
                                d="M36.72 89.53c-0.64,-0.68 0.07,-1.81 1.22,-1.47 1.14,0.34 2.24,1.12 3.17,1.5 0.77,0.31 0.77,1.01 0.24,1.4 -0.88,0.66 -4.05,-0.82 -4.63,-1.43z"
                            />
                            <path
                                fill="#5E382D"
                                d="M53.81 22.15c1.4,-0.01 6.63,2.8 6.84,5.1 0.19,2.08 -2.93,4.88 -4.17,7.4 -1.2,2.46 -2.29,2.87 -4.19,2.9 -0.69,0.01 -4.96,-2.27 -5.82,-4.42 -0.62,-1.55 -0.25,-2.31 0.1,-3.25 0.87,-2.4 5.84,-7.72 7.24,-7.73z"
                            />
                            <path
                                fill="#42292C"
                                d="M60.64 27.74c-0.27,2.02 -3.02,4.58 -4.16,6.91 -1.2,2.46 -2.29,2.87 -4.19,2.9 -0.69,0.01 -4.96,-2.27 -5.82,-4.42 -0.62,-1.55 -0.25,-2.31 0.1,-3.25 0.01,-0.03 0.02,-0.06 0.03,-0.09 1.9,2.38 5.61,5.64 6.98,5.57 1.4,-0.06 3.01,-2.69 4.41,-4.22 0.92,-1 1.83,-2.22 2.65,-3.4z"
                            />
                            <path
                                fill="#5E382D"
                                d="M57.51 53.03c-2.2,-1.07 -2.72,-4.94 -1.84,-7.04 0.88,-2.1 2.97,-2.77 5.68,-1.94 2.7,0.83 4.31,3.45 4.56,4.92 0.42,2.52 -5.48,5.5 -8.4,4.06z"
                            />
                            <path
                                fill="#42292C"
                                d="M57.51 53.03c-1.6,-0.78 -2.31,-3.04 -2.24,-5.02 1.18,0.94 2.34,2.47 3.5,2.8 1.88,0.52 3.86,-0.62 5.1,-1.43 0.71,-0.46 0.9,-1.6 1.17,-2.44 0.48,0.74 0.77,1.47 0.87,2.03 0.42,2.52 -5.48,5.5 -8.4,4.06z"
                            />
                            <path
                                fill="#5E382D"
                                d="M79.93 46.94c-0.68,-0.29 -1.4,-1.05 -1.85,-1.67 -1.01,-1.39 -1.53,-2.87 -1.55,-3.42 -0.04,-0.68 0.79,-3.05 3.11,-2.63 2.32,0.41 3.52,2.16 4.21,3.24 0.97,1.52 -1.28,5.59 -3.92,4.48z"
                            />
                            <path
                                fill="#42292C"
                                d="M79.93 46.94c-0.09,-0.04 -0.19,-0.09 -0.28,-0.14 1.06,-0.35 2.07,-0.82 2.74,-1.58 0.7,-0.81 0.68,-2.36 0.87,-3.62 0.23,0.31 0.43,0.61 0.59,0.86 0.97,1.52 -1.28,5.59 -3.92,4.48z"
                            />
                            <path
                                fill="#5E382D"
                                d="M76.79 23.2c1.93,0.74 3.67,1.71 5.09,4.1 1.08,1.8 0.94,4.5 0.67,5.45 -0.42,1.44 -2.79,1.36 -6.81,2.69 -2.6,0.87 -3.22,1.68 -5.17,1.62 -0.69,-0.03 -5.73,-2.15 -5.91,-4.46 -0.13,-1.66 0.79,-3.48 1.48,-4.2 4.39,-4.65 8.38,-6.07 10.65,-5.2z"
                            />
                            <path
                                fill="#42292C"
                                d="M64.65 32.46c1.87,0.39 4.36,2.57 5.9,2.77 0.57,0.07 4.75,-2.66 8.46,-3.77 1.68,-0.51 2.7,-1.68 3.46,-2.72 0.41,1.57 0.29,3.29 0.08,4.01 -0.42,1.44 -2.79,1.36 -6.81,2.69 -2.6,0.87 -3.22,1.68 -5.17,1.62 -0.69,-0.03 -5.73,-2.15 -5.91,-4.46 0,-0.05 0,-0.09 -0.01,-0.14z"
                            />
                            <path
                                fill="#5E382D"
                                d="M45.96 20.32c-1.35,1.02 -4.73,2.61 -5.72,2.08 -0.35,-0.19 -0.95,-0.74 -1.17,-1.66 -0.23,-0.94 -0.08,-2.28 0.02,-3.28 0.09,-0.85 0.24,-2.02 0.76,-2.17 1.74,-0.49 6.73,0.62 7.51,1.18 0.91,0.65 0.65,2.3 -1.4,3.85z"
                            />
                            <path
                                fill="#42292C"
                                d="M39.05 20.65c1.83,-0.27 6.94,-2.29 8.74,-3.6 0.27,0.81 -0.24,2.07 -1.83,3.27 -1.35,1.02 -4.73,2.61 -5.72,2.08 -0.35,-0.19 -0.95,-0.74 -1.17,-1.66 -0.01,-0.03 -0.01,-0.06 -0.02,-0.09z"
                            />
                            <path
                                fill="#5E382D"
                                d="M41.89 51.4c1.68,0.35 6.4,2.62 5.21,7.71 -0.73,3.15 -3.83,4.92 -6.81,5.65 -2.36,0.57 -5.89,1.21 -7.05,0.75 -1.15,-0.46 -4.32,-2.87 -3.5,-4.56 0.82,-1.7 10.47,-9.9 12.15,-9.55z"
                            />
                            <path
                                fill="#42292C"
                                d="M44.05 52.29c0.01,1.78 1,5.18 -1.63,7.86 -1.65,1.69 -5.14,3.81 -7.5,3.11 -1.27,-0.37 -3.15,-2.07 -4.86,-2.79 -0.15,0.19 -0.26,0.35 -0.32,0.48 -0.82,1.69 2.35,4.1 3.5,4.56 1.16,0.46 4.69,-0.18 7.05,-0.75 2.98,-0.73 6.08,-2.5 6.81,-5.65 0.83,-3.52 -1.18,-5.7 -3.05,-6.82z"
                            />
                            <path
                                fill="#5E382D"
                                d="M69.9 64.27c-1.32,-0.22 -3.77,-1.22 -3.57,-2.83 0.1,-0.81 1.18,-2.56 2.27,-4.15 1.11,-1.62 2.23,-3.08 3.58,-3.12 2.69,-0.09 4.72,5.32 4,6.87 -0.51,1.11 -3.92,3.63 -6.28,3.23z"
                            />
                            <path
                                fill="#42292C"
                                d="M67 59.82c1.7,0.52 3.75,1.52 4.68,0.96 0.74,-0.45 2.81,-3.11 2.37,-5.79 1.67,1.56 2.67,4.89 2.13,6.05 -0.51,1.11 -3.92,3.63 -6.28,3.23 -1.32,-0.22 -3.77,-1.22 -3.57,-2.83 0.04,-0.38 0.3,-0.95 0.67,-1.62z"
                            />
                            <path
                                fill="#5E382D"
                                d="M51 67.08c1.94,-0.04 4.94,1.91 4.96,3.74 0.02,1.54 -1.86,2.97 -3.68,4.71 -1.76,1.67 -3.47,3.42 -4.01,3.59 -1.11,0.35 -3.35,-1.32 -3.37,-3.34 -0.01,-2.02 4.16,-8.65 6.1,-8.7z"
                            />
                            <path
                                fill="#42292C"
                                d="M53.72 67.93c0.58,0.98 1.04,2.12 0.71,3.15 -0.57,1.76 -4.37,5.24 -5.85,7.87 0.74,-0.51 2.2,-1.99 3.7,-3.42 1.82,-1.74 3.7,-3.17 3.68,-4.71 -0.01,-1.06 -1.02,-2.16 -2.24,-2.89z"
                            />
                            <path
                                fill="#5E382D"
                                d="M33.86 30.76c1.39,0.19 4.93,1.16 5.12,5.8 0.12,3.09 -2.5,5.28 -4.45,7.3 -1.55,1.59 -3.96,3.56 -5.85,3.32 -0.68,-0.09 -3.25,-0.74 -3.8,-2.99 -0.39,-1.63 1.02,-5.16 1.14,-6.16 0.25,-1.92 6.45,-7.47 7.84,-7.27z"
                            />
                            <path
                                fill="#42292C"
                                d="M38.81 38.09c-3.93,4.96 -8.79,9.3 -12.4,6.27 -0.58,-0.5 -1.11,-0.91 -1.58,-1.26 -0.03,0.41 -0.02,0.78 0.05,1.09 0.55,2.25 3.12,2.9 3.8,2.99 1.89,0.24 4.3,-1.73 5.85,-3.32 1.61,-1.67 3.68,-3.45 4.28,-5.77z"
                            />
                            <path
                                fill="#5E382D"
                                d="M77.82 20.37c-0.61,-0.58 -0.9,-0.8 -0.56,-1.47 0.16,-0.3 0.6,-0.47 1.02,-0.45 0.42,0.02 0.82,0.23 1.18,0.44 1.28,0.77 2.13,1.44 2.16,1.98 0.03,0.37 -0.58,0.52 -1.51,0.38 -0.78,-0.11 -1.83,-0.45 -2.29,-0.88z"
                            />
                            <path
                                fill="#5E382D"
                                d="M75.17 15.04c-0.02,0.35 -1.6,1.99 -2.1,2.02 -0.49,0.03 -1.11,-0.53 -1.07,-1.04 0.04,-0.51 1.13,-1.52 1.69,-1.71 0.63,-0.22 1.51,0.34 1.48,0.73z"
                            />
                            <path
                                fill="#5E382D"
                                d="M53.61 9.41c0.19,0.34 0.01,1.9 -0.58,2.45 -0.59,0.55 -1.04,0.3 -1.15,-0.07 -0.12,-0.37 -0.23,-1.9 0.42,-2.55 0.26,-0.25 1.02,-0.36 1.31,0.17z"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default CookieConsent
