import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { PurchaseMethod } from '../types'
import { loadPurchaseMethods } from '..'

interface Option {
    value: string
    optionStyle: string
}

const TYPAL_KIND = new Map<string, string>([
    ['TSK', 'Конкурс'],
    ['TSA', 'Аукцион'],
    ['TSZK', 'Запрос котировок'],
    ['TSZP', 'Запрос предложений'],
    ['TSEP', 'Закупка у единственного поставщика (подрядчика, исполнителя)'],
])

const TYPAL_KIND_NAME = new Map<string, string>([
    ['Конкурс', 'TSK'],
    ['Аукцион', 'TSA'],
    ['Запрос котировок', 'TSZK'],
    ['Запрос предложений', 'TSZP'],
    ['Закупка у единственного поставщика (подрядчика, исполнителя)', 'TSEP'],
])

export const loadPurchaseMethod = async ({
    params,
}: any): Promise<PurchaseMethod> => {
    if (params.guid === 'new') {
        // Возвращаем пустого пользователя для нового
        return {
            guid: '',
            purchase_method_code: 0,
            purchase_method_name: '',
            purchase_method_short_name: '',
            typal: true,
            typal_kind: '',
            is_electronic: true,
            closed_purchase: false,
            competitive: true,
            has_phases: false,
            purchase_smb_type: false,
            minimum_deadline_days: 0,
            days_type: true,
            maximum_contract_price: 0,
            maximum_amount_request_security: 0,
            maximum_amount_contract_security: 0,
            required_document_tz: false,
            required_document_nmcd: false,
            required_document_en: false,
            required_document_contract_project: false,
            modification_description: '',
            modification_date: '',
            is_active: true,
        }
    }
    try {
        // Подставляем guid пользователя в URL
        const response = await fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/purchase_method/${params.guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response.ok) {
            throw new Error(`Error loading user: ${response.status}`)
        }

        // Парсим JSON-ответ
        const user: PurchaseMethod = await response.json()
        return user
    } catch (error) {
        console.error('Failed to load user:', error)
        throw error // Или обработайте ошибку соответствующим образом
    }
}

const OrgPurchaseMethodViewOrEdit: React.FC = () => {
    const purchaseMethodData = useLoaderData() as PurchaseMethod
    const [purchaseMethodDataUpdate, setPurchaseMethodDataUpdate] =
        useState(purchaseMethodData)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки

    const [selectedMethodType, setSelectedMethodType] = useState('Выбрать')
    const [selectedMethodKind, setSelectedMethodKind] = useState('')
    const [selectedTypicalKind, setSelectedTypicalKind] = useState('')
    const [selectedCompetitiveType, setSelectedCompetitiveType] = useState('')
    const [selectedMethodForm, setSelectedMethodForm] = useState('')

    const [touchedInput, setTouchedInput] = useState<{
        [key: string]: boolean
    }>({})
    const [errorsInput, setErrorsInput] = useState<{ [key: string]: string }>(
        {}
    )

    const getNameTypalKind = (typal_kind: string): string => {
        return TYPAL_KIND.get(typal_kind) || ''
    }

    const getTypalKind = (name_typal_kind: string): string => {
        return TYPAL_KIND_NAME.get(name_typal_kind) || ''
    }

    useEffect(() => {
        if (purchaseMethodData.guid) {
            setSelectedMethodType(purchaseMethodData.typal ? 'Типовой' : 'Иной')
            setSelectedMethodKind(
                purchaseMethodData.closed_purchase ? 'Закрытый' : 'Открытый'
            )
            setSelectedTypicalKind(
                getNameTypalKind(purchaseMethodData.typal_kind)
            )
            setSelectedCompetitiveType(
                purchaseMethodData.competitive
                    ? 'Конкурентный'
                    : 'Неконкурентный'
            )
            setSelectedMethodForm(
                purchaseMethodData.is_electronic
                    ? 'В электронной форме'
                    : 'В бумажной форме'
            )
        } else {
            setSelectedMethodType('Выбрать')
            setSelectedMethodKind('')
            setSelectedTypicalKind('')
            setSelectedCompetitiveType('')
            setSelectedMethodForm('')
        }
    }, [purchaseMethodData])

    const methodTypeOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Типовой', optionStyle: 'text-[#FFF]' },
        { value: 'Иной', optionStyle: 'text-[#FFF]' },
    ]

    const methodKindOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Открытый', optionStyle: 'text-[#FFF]' },
        { value: 'Закрытый', optionStyle: 'text-[#FFF]' },
    ]

    const typicalKindOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Конкурс', optionStyle: 'text-[#FFF]' },
        { value: 'Аукцион', optionStyle: 'text-[#FFF]' },
        { value: 'Запрос предложений', optionStyle: 'text-[#FFF]' },
        { value: 'Запрос котировок', optionStyle: 'text-[#FFF]' },
        {
            value: 'Закупка у единственного поставщика (подрядчика, исполнителя)',
            optionStyle: 'text-[#FFF]',
        },
    ]

    const competitiveTypeOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'Конкурентный', optionStyle: 'text-[#FFF]' },
        { value: 'Неконкурентный', optionStyle: 'text-[#FFF]' },
    ]

    const methodFormOptions: Option[] = [
        { value: 'Выбрать', optionStyle: 'text-[#8A8A8A]' },
        { value: 'В электронной форме', optionStyle: 'text-[#FFF]' },
        { value: 'В бумажной форме', optionStyle: 'text-[#FFF]' },
    ]

    const [isActivePurchaseMethod, setIsActivePurchaseMethod] = useState(
        purchaseMethodData.is_active
    )
    const [isSMBPurchaseMethod, setIsSMBPurchaseMethod] = useState(
        purchaseMethodData.purchase_smb_type
    )

    const navigate = useNavigate()
    const isEdit = window.location.hash.endsWith('/edit')

    const handleMethodTypeChange = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedMethodType(event.target.value)
        switch (event.target.value) {
            case 'Выбрать':
                setSelectedMethodKind('')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Типовой':
                setSelectedMethodKind('Выбрать')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Иной':
                setSelectedMethodKind('Выбрать')
                setSelectedTypicalKind('')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
        }
        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    ['typal']: event.target.value == 'Типовой' ? true : false,
                }) as unknown as PurchaseMethod
        )
    }

    const handleMethodKindChange = (value: string, methodType: string) => {
        setSelectedMethodKind(value)
        switch (value) {
            case 'Выбрать':
                setSelectedTypicalKind('Выбрать')
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Открытый':
                if (methodType === 'Иной') {
                    setSelectedTypicalKind('')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['typal_kind']: '',
                            }) as unknown as PurchaseMethod
                    )
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                } else {
                    setSelectedTypicalKind('Выбрать')
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                }
                break
            case 'Закрытый':
                if (methodType === 'Иной') {
                    setSelectedTypicalKind('')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['typal_kind']: '',
                            }) as unknown as PurchaseMethod
                    )
                    setSelectedCompetitiveType('Конкурентный')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['competitive']: true,
                            }) as unknown as PurchaseMethod
                    )
                    setSelectedMethodForm('В электронной форме')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['is_electronic']: true,
                            }) as unknown as PurchaseMethod
                    )
                } else {
                    setSelectedTypicalKind('Выбрать')
                    setSelectedCompetitiveType('Выбрать')
                    setSelectedMethodForm('')
                }
                break
            default:
                setSelectedTypicalKind('Выбрать')
                setSelectedCompetitiveType('Выбрать')
                setSelectedMethodForm('')
                break
        }
        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    ['closed_purchase']: value == 'Закрытый' ? true : false,
                }) as unknown as PurchaseMethod
        )
    }

    const handleTypicalKindChange = (value: string, methodKind: string) => {
        setSelectedTypicalKind(value)
        switch (value) {
            case 'Выбрать':
                setSelectedCompetitiveType('')
                setSelectedMethodForm('')
                break
            case 'Закупка у единственного поставщика (подрядчика, исполнителя)':
                setSelectedCompetitiveType('Неконкурентный')
                setPurchaseMethodDataUpdate(
                    (prev) =>
                        ({
                            ...prev,
                            ['competitive']: false,
                        }) as unknown as PurchaseMethod
                )
                setSelectedMethodForm('Выбрать')
                break
            case 'Запрос предложений':
                setSelectedCompetitiveType('Конкурентный')
                setPurchaseMethodDataUpdate(
                    (prev) =>
                        ({
                            ...prev,
                            ['competitive']: true,
                        }) as unknown as PurchaseMethod
                )
                setSelectedMethodForm('В электронной форме')
                setPurchaseMethodDataUpdate(
                    (prev) =>
                        ({
                            ...prev,
                            ['is_electronic']: true,
                        }) as unknown as PurchaseMethod
                )
                break
            case 'Запрос котировок':
                setSelectedCompetitiveType('Конкурентный')
                setPurchaseMethodDataUpdate(
                    (prev) =>
                        ({
                            ...prev,
                            ['competitive']: true,
                        }) as unknown as PurchaseMethod
                )
                setSelectedMethodForm('В электронной форме')
                setPurchaseMethodDataUpdate(
                    (prev) =>
                        ({
                            ...prev,
                            ['is_electronic']: true,
                        }) as unknown as PurchaseMethod
                )
                break
            default:
                if (methodKind === 'Закрытый') {
                    setSelectedCompetitiveType('Конкурентный')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['competitive']: true,
                            }) as unknown as PurchaseMethod
                    )
                    setSelectedMethodForm('В электронной форме')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['is_electronic']: true,
                            }) as unknown as PurchaseMethod
                    )
                } else {
                    setSelectedCompetitiveType('Конкурентный')
                    setPurchaseMethodDataUpdate(
                        (prev) =>
                            ({
                                ...prev,
                                ['competitive']: true,
                            }) as unknown as PurchaseMethod
                    )
                    setSelectedMethodForm('Выбрать')
                }

                break
        }
        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    ['typal_kind']:
                        value != 'Выбрать' ? getTypalKind(value) : '',
                }) as unknown as PurchaseMethod
        )
    }

    const handleCompetitiveType = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedCompetitiveType(event.target.value)
        setSelectedMethodForm('Выбрать')
        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    ['competitive']:
                        event.target.value == 'Конкурентный' ? true : false,
                }) as unknown as PurchaseMethod
        )
    }

    const handleMethodForm = (event: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setSelectedMethodForm(event.target.value)
        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    ['is_electronic']:
                        event.target.value == 'В электронной форме'
                            ? true
                            : false,
                }) as unknown as PurchaseMethod
        )
    }

    const handleToggleIsActivePurchaseMethod = () => {
        if (isEdit) {
            setIsActivePurchaseMethod((prevState) => !prevState)
            setPurchaseMethodDataUpdate((prev) => ({
                ...prev,
                ['is_active']: !isActivePurchaseMethod,
            }))
        }
    }

    const handleToggleIsSMBPurchaseMethod = () => {
        if (isEdit) {
            setIsSMBPurchaseMethod((prevState) => !prevState)
            setPurchaseMethodDataUpdate((prev) => ({
                ...prev,
                ['purchase_smb_type']: !isSMBPurchaseMethod,
            }))
        }
    }

    const validateField = (fieldName: keyof PurchaseMethod, value: string) => {
        if (
            [
                'purchase_method_name',
                'purchase_method_short_name',
                'purchase_method_code',
                'typal',
                'typal_kind',
                'is_electronic',
                'closed_purchase',
                'competitive',
            ].includes(fieldName)
        ) {
            let error = ''

            if (!value) {
                error = 'Поле обязательно к заполнению'
            }

            setErrorsInput((prev) => ({ ...prev, [fieldName]: error }))
        }
    }

    const handleInputChange = (
        fieldName: keyof PurchaseMethod,
        value: string | null
    ) => {
        if (fieldName == 'maximum_contract_price') {
            if (value == '') value = null
        }

        if (fieldName == 'purchase_method_code') {
            if (value == '') value = null
        }

        if (typeof value === 'string') {
            if (['purchase_method_code'].includes(fieldName)) {
                value = value.replace(/\D/g, '') // Удаляем все нецифровые символы
                validateField(fieldName, value)
            }
        }

        setPurchaseMethodDataUpdate(
            (prev) =>
                ({
                    ...prev,
                    [fieldName]: value,
                }) as PurchaseMethod
        )
    }

    const handleBlur = (fieldName: keyof PurchaseMethod) => {
        setTouchedInput((prev) => ({ ...prev, [fieldName]: true }))
        validateField(
            fieldName,
            purchaseMethodDataUpdate[fieldName]?.toString() || ''
        )
    }

    const getBorderStyle = (fieldName: keyof PurchaseMethod) => {
        const isTouched = touchedInput[fieldName]
        const hasError = errorsInput[fieldName]
        return isTouched && hasError ? '1px solid #C15C5C' : ''
    }

    // Проверяем, есть ли ошибки
    const hasErrors = () => {
        return Object.values(errorsInput).some((error) => error !== '')
    }

    const handleAddPurchaseMethod = async () => {
        setIsAdding(true) // Включаем анимацию загрузки
        if (purchaseMethodDataUpdate.guid !== '') {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/purchase_method/${purchaseMethodDataUpdate.guid}`,
                    {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(purchaseMethodDataUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при изменении способа закупки')
                }

                console.log('Способ закупки успешно изменен')
            } catch (error) {
                console.error('Ошибка при изменении способа закупки:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
                navigate('/hub/org/methods')
            }
        } else {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/purchase_method`,
                    {
                        method: 'POST',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(purchaseMethodDataUpdate), // Добавлено тело запроса
                    }
                )
                if (!response.ok) {
                    throw new Error('Ошибка при добавлении способа закупки')
                }

                console.log('Способ закупки успешно добавлен')
            } catch (error) {
                console.error('Ошибка при добавлении способа закупки:', error)
            } finally {
                setIsAdding(false) // Отключаем анимацию загрузки
            }
            loadPurchaseMethods() as unknown as PurchaseMethod[]
            navigate('/hub/org/methods')
        }
    }

    return (
        <>
            {isEdit ? (
                <>
                    <div className="flex flex-col text-white h-full">
                        <div className="bg-[#111111]">
                            <div className="w-auto h-20 mx-6 flex flex-row gap-x-5 ">
                                <div className="w-60 h-20 my-auto content-center ">
                                    <p className="text-sm text-white">
                                        Реестр способов закупки
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-1 justify-center min-h-[calc(100vh_-_120px)] overflow-y-auto py-5">
                            <div className="flex flex-col h-max w-[800px] space-y-10">
                                {/* Способы закупки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>
                                            {purchaseMethodData.guid
                                                ? 'Изменение способа'
                                                : 'Добавление способа'}{' '}
                                            закупки
                                        </p>
                                    </div>

                                    <div className="flex flex-row space-x-5">
                                        <div className="flex flex-col w-full space-y-3">
                                            {/* Активный способ закупки */}
                                            <div className="flex flex-row w-full space-x-5 pt-2">
                                                <div className="flex flex-col w-full space-y-1">
                                                    <div>
                                                        <label className="flex flex-row items-center">
                                                            <div
                                                                onClick={
                                                                    handleToggleIsActivePurchaseMethod
                                                                }
                                                                className={`switch ${isActivePurchaseMethod ? 'switch-on' : 'switch-off'} ${
                                                                    !isEdit
                                                                        ? 'switch-disabled'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <div
                                                                    className={`switch-toggle ${
                                                                        isActivePurchaseMethod
                                                                            ? `toggle-on ${
                                                                                  !isEdit
                                                                                      ? 'toggle-on-disabled'
                                                                                      : ''
                                                                              }`
                                                                            : `toggle-off ${
                                                                                  !isEdit
                                                                                      ? 'toggle-off-disabled'
                                                                                      : ''
                                                                              }`
                                                                    }`}
                                                                >
                                                                    {isActivePurchaseMethod ? (
                                                                        <svg
                                                                            className="w-[15px] h-[15px]"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="check"
                                                                        >
                                                                            <path
                                                                                fill={`${
                                                                                    !isEdit
                                                                                        ? '#262427'
                                                                                        : '#187A33'
                                                                                }`}
                                                                                d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                            ></path>
                                                                        </svg>
                                                                    ) : (
                                                                        <svg
                                                                            className="w-[15px] h-[15px]"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            viewBox="0 0 24 24"
                                                                            id="times"
                                                                        >
                                                                            <path
                                                                                fill={`${
                                                                                    !isEdit
                                                                                        ? '#262427'
                                                                                        : '#656365'
                                                                                }`}
                                                                                d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                            ></path>
                                                                        </svg>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <span
                                                                className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                                onClick={
                                                                    handleToggleIsActivePurchaseMethod
                                                                }
                                                            >
                                                                Активный способ
                                                                закупки
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Наименование способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Наименование способа
                                                        закупки{' '}
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="user-input"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'purchase_method_name'
                                                            ),
                                                        }}
                                                        value={
                                                            purchaseMethodDataUpdate.purchase_method_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'purchase_method_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'purchase_method_name'
                                                            )
                                                        }
                                                    />
                                                    {touchedInput.purchase_method_name &&
                                                        errorsInput.purchase_method_name && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.purchase_method_name
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>

                                            {/* Сокращенное наименование способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Сокращенное наименование
                                                        способа закупки{' '}
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="user-input"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'purchase_method_short_name'
                                                            ),
                                                        }}
                                                        value={
                                                            purchaseMethodDataUpdate.purchase_method_short_name
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'purchase_method_short_name',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'purchase_method_short_name'
                                                            )
                                                        }
                                                    />
                                                    {touchedInput.purchase_method_short_name &&
                                                        errorsInput.purchase_method_short_name && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.purchase_method_short_name
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>

                                            {/* Код способа закупки */}
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Код способа закупки{' '}
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <input
                                                        className="user-input"
                                                        style={{
                                                            colorScheme: 'dark',
                                                            border: getBorderStyle(
                                                                'purchase_method_code'
                                                            ),
                                                        }}
                                                        value={
                                                            purchaseMethodDataUpdate.purchase_method_code ==
                                                            0
                                                                ? ''
                                                                : purchaseMethodDataUpdate.purchase_method_code
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'purchase_method_code',
                                                                e.target.value
                                                            )
                                                        }
                                                        onBlur={() =>
                                                            handleBlur(
                                                                'purchase_method_code'
                                                            )
                                                        }
                                                    />
                                                    {touchedInput.purchase_method_code &&
                                                        errorsInput.purchase_method_code && (
                                                            <span
                                                                style={{
                                                                    color: '#C15C5C',
                                                                    fontSize:
                                                                        '12.8px',
                                                                }}
                                                            >
                                                                {
                                                                    errorsInput.purchase_method_code
                                                                }
                                                            </span>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Свойства способа закупки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Свойства способа закупки</p>
                                    </div>
                                    {/* {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodType}
                                        </p>
                                    }+
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodKind}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedTypicalKind}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedCompetitiveType}
                                        </p>
                                    }
                                    {
                                        <p className="mt-2 text-sm text-gray-600">
                                            Selected: {selectedMethodForm}
                                        </p>
                                    } */}

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Тип способа закупки */}
                                        <div className="flex flex-col space-y-1 w-[200px]">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Тип способа закупки{' '}
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={selectedMethodType}
                                                    onChange={
                                                        handleMethodTypeChange
                                                    }
                                                    className={
                                                        selectedMethodType ===
                                                        'Выбрать'
                                                            ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                            : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                    }
                                                >
                                                    {methodTypeOptions.map(
                                                        (option) =>
                                                            option.value ===
                                                            'Выбрать' ? (
                                                                <option
                                                                    disabled
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Вид способа закупки */}
                                        <div
                                            className={
                                                selectedMethodKind === ''
                                                    ? 'flex-col space-y-1 w-[200px] hidden'
                                                    : 'flex flex-col space-y-1 w-[200px]'
                                            }
                                        >
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Вид способа закупки{' '}
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <select
                                                    value={selectedMethodKind}
                                                    onChange={(
                                                        e: React.ChangeEvent<HTMLSelectElement>
                                                    ) =>
                                                        handleMethodKindChange(
                                                            e.target.value,
                                                            selectedMethodType
                                                        )
                                                    }
                                                    className={
                                                        selectedMethodKind ===
                                                        'Выбрать'
                                                            ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                            : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                    }
                                                >
                                                    {methodKindOptions.map(
                                                        (option) =>
                                                            option.value ===
                                                            'Выбрать' ? (
                                                                <option
                                                                    disabled
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            ) : (
                                                                <option
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    value={
                                                                        option.value
                                                                    }
                                                                    className={
                                                                        option.optionStyle
                                                                    }
                                                                >
                                                                    {
                                                                        option.value
                                                                    }
                                                                </option>
                                                            )
                                                    )}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Вид типового способа */}
                                        <div className="flex flex-row w-full gap-5 ">
                                            <div
                                                className={
                                                    selectedTypicalKind ===
                                                        '' ||
                                                    selectedMethodType ===
                                                        'Иной'
                                                        ? 'flex-col space-y-1 w-[320px] hidden'
                                                        : 'flex flex-col space-y-1 w-[320px]'
                                                }
                                            >
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Вид типового способа{' '}
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <select
                                                        value={
                                                            selectedTypicalKind
                                                        }
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLSelectElement>
                                                        ) =>
                                                            handleTypicalKindChange(
                                                                e.target.value,
                                                                selectedMethodKind
                                                            )
                                                        }
                                                        className={
                                                            selectedTypicalKind ===
                                                            'Выбрать'
                                                                ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                        }
                                                    >
                                                        {selectedMethodKind ===
                                                        'Закрытый'
                                                            ? typicalKindOptions
                                                                  .slice(0, 5)
                                                                  .map(
                                                                      (
                                                                          option
                                                                      ) =>
                                                                          option.value ===
                                                                          'Выбрать' ? (
                                                                              <option
                                                                                  disabled
                                                                                  key={
                                                                                      option.value
                                                                                  }
                                                                                  value={
                                                                                      option.value
                                                                                  }
                                                                                  className={
                                                                                      option.optionStyle
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      option.value
                                                                                  }
                                                                              </option>
                                                                          ) : (
                                                                              <option
                                                                                  key={
                                                                                      option.value
                                                                                  }
                                                                                  value={
                                                                                      option.value
                                                                                  }
                                                                                  className={
                                                                                      option.optionStyle
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      option.value
                                                                                  }
                                                                              </option>
                                                                          )
                                                                  )
                                                            : typicalKindOptions.map(
                                                                  (option) =>
                                                                      option.value ===
                                                                      'Выбрать' ? (
                                                                          <option
                                                                              disabled
                                                                              key={
                                                                                  option.value
                                                                              }
                                                                              value={
                                                                                  option.value
                                                                              }
                                                                              className={
                                                                                  option.optionStyle
                                                                              }
                                                                          >
                                                                              {
                                                                                  option.value
                                                                              }
                                                                          </option>
                                                                      ) : (
                                                                          <option
                                                                              key={
                                                                                  option.value
                                                                              }
                                                                              value={
                                                                                  option.value
                                                                              }
                                                                              className={
                                                                                  option.optionStyle
                                                                              }
                                                                          >
                                                                              {
                                                                                  option.value
                                                                              }
                                                                          </option>
                                                                      )
                                                              )}
                                                    </select>
                                                </div>
                                            </div>

                                            <div
                                                className={
                                                    selectedCompetitiveType ===
                                                    ''
                                                        ? 'flex-col space-y-1 w-[200px] hidden'
                                                        : 'flex flex-col space-y-1 w-[200px]'
                                                }
                                            >
                                                <div>
                                                    <label className="text-[12.8px] text-[#8A8A8A]">
                                                        Тип конкурентности{' '}
                                                        <span className="text-[#FF0000]">
                                                            *
                                                        </span>
                                                    </label>
                                                </div>
                                                <div>
                                                    {selectedMethodType ===
                                                        'Иной' &&
                                                    selectedMethodKind ===
                                                        'Закрытый' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedCompetitiveType
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : selectedMethodType ===
                                                      'Типовой' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedCompetitiveType
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (
                                                        <select
                                                            value={
                                                                selectedCompetitiveType
                                                            }
                                                            onChange={
                                                                handleCompetitiveType
                                                            }
                                                            className={
                                                                selectedCompetitiveType ===
                                                                'Выбрать'
                                                                    ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                    : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                            }
                                                        >
                                                            {competitiveTypeOptions.map(
                                                                (option) =>
                                                                    option.value ===
                                                                    'Выбрать' ? (
                                                                        <option
                                                                            disabled
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    ) : (
                                                                        <option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Форма проведения */}
                                        <div
                                            className={
                                                selectedMethodForm === ''
                                                    ? 'flex-col space-y-1 w-[200px] hidden'
                                                    : 'flex flex-col space-y-1 w-[200px]'
                                            }
                                        >
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Форма проведения{' '}
                                                    <span className="text-[#FF0000]">
                                                        *
                                                    </span>
                                                </label>
                                            </div>
                                            <div>
                                                <div>
                                                    {selectedMethodType ===
                                                        'Иной' &&
                                                    selectedMethodKind ===
                                                        'Закрытый' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedMethodForm
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (selectedTypicalKind ===
                                                          'Запрос предложений' ||
                                                          selectedTypicalKind ===
                                                              'Запрос котировок' ||
                                                          selectedMethodKind ===
                                                              'Закрытый') &&
                                                      selectedMethodType ===
                                                          'Типовой' ? (
                                                        <input
                                                            disabled
                                                            value={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? ''
                                                                    : selectedMethodForm
                                                            }
                                                            className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#1F1E1F] text-[#8A8A8A] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                        />
                                                    ) : (
                                                        <select
                                                            value={
                                                                selectedMethodForm
                                                            }
                                                            onChange={
                                                                handleMethodForm
                                                            }
                                                            className={
                                                                selectedMethodForm ===
                                                                'Выбрать'
                                                                    ? 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#8A8A8A]'
                                                                    : 'w-full h-7 px-2 text-sm rounded-md border-0 bg-[#181818] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] text-[#FFF]'
                                                            }
                                                        >
                                                            {methodFormOptions.map(
                                                                (option) =>
                                                                    option.value ===
                                                                    'Выбрать' ? (
                                                                        <option
                                                                            disabled
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    ) : (
                                                                        <option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                            className={
                                                                                option.optionStyle
                                                                            }
                                                                        >
                                                                            {
                                                                                option.value
                                                                            }
                                                                        </option>
                                                                    )
                                                            )}
                                                        </select>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Общие настройки */}
                                <div className="flex-col w-full bg-[#262427] rounded-lg p-5 space-y-5">
                                    <div className="text-lg text-white">
                                        <p>Общие настройки</p>
                                    </div>

                                    <div className="flex flex-col w-full space-y-3">
                                        {/* Закупка, участниками которой являются только субъекты малого и среднего предпринимательства */}
                                        <div className="flex flex-row w-full space-x-5 pt-2">
                                            <div className="flex flex-col w-full space-y-1">
                                                <div>
                                                    <label className="flex flex-row items-center cursor-pointer">
                                                        <div
                                                            onClick={
                                                                handleToggleIsSMBPurchaseMethod
                                                            }
                                                            className={`switch ${isSMBPurchaseMethod ? 'switch-on' : 'switch-off'} ${
                                                                !isEdit
                                                                    ? 'switch-disabled'
                                                                    : ''
                                                            }`}
                                                        >
                                                            <div
                                                                className={`switch-toggle ${
                                                                    isSMBPurchaseMethod
                                                                        ? `toggle-on ${
                                                                              !isEdit
                                                                                  ? 'toggle-on-disabled'
                                                                                  : ''
                                                                          }`
                                                                        : `toggle-off ${
                                                                              !isEdit
                                                                                  ? 'toggle-off-disabled'
                                                                                  : ''
                                                                          }`
                                                                }`}
                                                            >
                                                                {isSMBPurchaseMethod ? (
                                                                    <svg
                                                                        className="w-[15px] h-[15px]"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        id="check"
                                                                    >
                                                                        <path
                                                                            fill={`${
                                                                                !isEdit
                                                                                    ? '#262427'
                                                                                    : '#187A33'
                                                                            }`}
                                                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                                        ></path>
                                                                    </svg>
                                                                ) : (
                                                                    <svg
                                                                        className="w-[15px] h-[15px]"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 24 24"
                                                                        id="times"
                                                                    >
                                                                        <path
                                                                            fill={`${
                                                                                !isEdit
                                                                                    ? '#262427'
                                                                                    : '#656365'
                                                                            }`}
                                                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                                        ></path>
                                                                    </svg>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <span
                                                            className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                                            onClick={
                                                                handleToggleIsSMBPurchaseMethod
                                                            }
                                                        >
                                                            Закупка, участниками
                                                            которой являются
                                                            только субъекты
                                                            малого и среднего
                                                            предпринимательства
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Максимальная сумма НМЦД по способу закупки (в руб.) */}
                                        <div className="flex flex-col w-full space-y-1">
                                            <div>
                                                <label className="text-[12.8px] text-[#8A8A8A]">
                                                    Максимальная сумма НМЦД по
                                                    способу закупки (в руб.)
                                                </label>
                                            </div>
                                            <div>
                                                <input
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                                                    style={{
                                                        colorScheme: 'dark',
                                                    }}
                                                    value={
                                                        purchaseMethodDataUpdate.maximum_contract_price ==
                                                        0
                                                            ? ''
                                                            : purchaseMethodDataUpdate.maximum_contract_price
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'maximum_contract_price',
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* buttons */}
                        <div className="flex flex-row text-white px-7 py-1.5 bg-[#333033] h-11 gap-3 justify-end items-center ">
                            <button
                                className="flex flex-row min-w-[120px] h-6 rounded-[10px]  px-4 text-xs text-white  justify-center items-center gap-1 bg-[#494849] hover:bg-[#656365]"
                                onClick={() => navigate('/hub/org/methods')}
                            >
                                Закрыть
                            </button>
                            <button
                                className="flex flex-row min-w-[120px] h-6 rounded-[10px] px-4 text-xs text-white justify-center items-center gap-1 bg-[#187A33] hover:bg-green-600"
                                onClick={handleAddPurchaseMethod}
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : purchaseMethodData.guid ? (
                                    'Изменить'
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </>
            ) : (
                'Viewing'
            )}
        </>
    )
}

export default OrgPurchaseMethodViewOrEdit
