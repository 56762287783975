import React from 'react'

type Props = {
    user_guid: string | null
    pos_user_guid: string
    role: string
    stage: string
    status: string
    approve: boolean
    actions: Actions
    isSendPlan: boolean
    isHaveError: boolean
    // status: string
    // multiSelect: boolean
}

type Actions = {
    return_to_edit: () => void
    return_to_ready: () => void
    ready: () => void
    edit: () => void
    edit_ready: () => void
    ready_to_sent: () => void
    ready_to_eis: () => void
    validation: () => void
}

type ButtonConfig = {
    id: string
    label: string
    onClick: () => void
    visibleCondition: boolean // Функция, проверяющая видимость
    className?: string // Стили для кнопки
    iconPathD: string
    iconFill: string
    disabled: boolean
    isLoading: boolean
}

const TableFooterLeftToolbarButtons: React.FC<Props> = ({
    user_guid,
    pos_user_guid,
    role,
    stage,
    status,
    approve,
    actions,
    isSendPlan,
    isHaveError,
    // status,
    // multiSelect,
}) => {
    const isCreator = user_guid === pos_user_guid
    const isAdmin = role === 'admin'
    const isSpecialist = role === 'specialist'
    const isCurator = role === 'curator'
    const isInitiator = role === 'initiator'

    const isEditStage = stage === 'Редактирование'
    const isRaedyStage = stage === 'Подготовка к размещению'
    const isSentToEisStage = stage === 'Отправка в ЕИС'
    const isPurchasePlanStage = stage === 'План закупки в ЕИС'

    const isEditStatus = status === 'Черновик'
    const isReadyStatus = status === 'Готово'
    const isStandStatus = status === 'Подготовка'
    const isReadyToEisStatus = status === 'Готово к отправке'
    const isSentToEisStatus = status === 'Отправлено в ЕИС'
    const isPublishedStatus = status === 'Размещена'

    const buttons: ButtonConfig[] = [
        {
            id: 'return_to_edit',
            label: 'Вернуть',
            onClick: actions.return_to_edit,
            visibleCondition:
                !approve &&
                isRaedyStage &&
                isStandStatus &&
                (isSpecialist || isAdmin),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,11H9.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H21a1,1,0,0,0,0-2ZM3,3A1,1,0,0,0,2,4V20a1,1,0,0,0,2,0V4A1,1,0,0,0,3,3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'return_to_ready',
            label: 'Вернуть',
            onClick: actions.return_to_ready,
            visibleCondition:
                !approve &&
                isSentToEisStage &&
                isReadyToEisStatus &&
                (isSpecialist || isAdmin),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M21,11H9.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H21a1,1,0,0,0,0-2ZM3,3A1,1,0,0,0,2,4V20a1,1,0,0,0,2,0V4A1,1,0,0,0,3,3Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'validation',
            label: 'Проверить',
            onClick: actions.validation,
            visibleCondition:
                (!approve &&
                    isEditStage &&
                    isEditStatus &&
                    (isAdmin || isSpecialist || isCreator)) ||
                (!approve &&
                    isRaedyStage &&
                    isStandStatus &&
                    (isAdmin || isSpecialist)),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isReadyStatus ? 'bg-[#494849] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-[#1D9A40] text-white'}`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z',
            disabled: isReadyStatus,
            isLoading: false,
        },
        {
            id: 'ready',
            label: 'Готово',
            onClick: actions.ready,
            visibleCondition:
                (!approve &&
                    isEditStage &&
                    isEditStatus &&
                    (isAdmin || isSpecialist || isCreator)) ||
                (!approve &&
                    isRaedyStage &&
                    isStandStatus &&
                    (isAdmin || isSpecialist)),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isReadyStatus || isHaveError ? 'bg-[#494849] text-[#8A8A8A]' : 'bg-[#187A33] hover:bg-[#1D9A40] text-white'}`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z',
            disabled: isReadyStatus || isHaveError,
            isLoading: false,
        },
        {
            id: 'edit',
            label: 'Редактировать',
            onClick: actions.edit,
            visibleCondition:
                !approve &&
                isEditStage &&
                isReadyStatus &&
                (isAdmin || isSpecialist || isCreator),
            className:
                'flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white',
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'edit_ready',
            label: 'Редактировать',
            onClick: actions.edit_ready,
            visibleCondition:
                !approve &&
                isRaedyStage &&
                isReadyStatus &&
                (isAdmin || isSpecialist),
            className:
                'flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs bg-[#494849] hover:bg-[#656365] text-white',
            iconFill: `#FFFFFF`,
            iconPathD:
                'M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z',
            disabled: false,
            isLoading: false,
        },
        {
            id: 'ready_to_sent',
            label: 'Направить на подготовку к размещению',
            onClick: actions.ready_to_sent,
            visibleCondition:
                !approve &&
                isEditStage &&
                isReadyStatus &&
                (isAdmin || isSpecialist || isCurator),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isEditStage && isReadyStatus ? 'bg-[#187A33] hover:bg-[#1D9A40] text-white' : 'bg-[#494849] text-[#8A8A8A]'}`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z',
            disabled: !(isEditStage && isReadyStatus),
            isLoading: false,
        },
        {
            id: 'ready_to_eis',
            label: 'Подготовить к отправке в ЕИС',
            onClick: actions.ready_to_eis,
            visibleCondition:
                !approve && isRaedyStage && (isAdmin || isSpecialist),
            className: `flex flex-row gap-2 px-4 py-1 min-w-[120px] items-center justify-center rounded-[10px] shadow-md text-xs ${isRaedyStage && isReadyStatus && !isSendPlan ? 'bg-[#187A33] hover:bg-[#1D9A40] text-white' : 'bg-[#494849] text-[#8A8A8A]'}`,
            iconFill: `#FFFFFF`,
            iconPathD:
                'M17.71,11.29l-4-4a1,1,0,1,0-1.42,1.42L14.59,11H3a1,1,0,0,0,0,2H14.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76A1,1,0,0,0,17.71,11.29ZM21,4a1,1,0,0,0-1,1V19a1,1,0,0,0,2,0V5A1,1,0,0,0,21,4Z',
            disabled: !(isRaedyStage && isReadyStatus && !isSendPlan),
            isLoading: false,
        },
    ]

    return (
        <>
            {buttons
                .filter((button) => button.visibleCondition) // Оставляем только видимые кнопки
                .map((button, index) => (
                    <button
                        key={index}
                        className={button.className}
                        onClick={button.onClick}
                        title={button.label}
                        disabled={button.disabled}
                    >
                        {button.isLoading ? (
                            <svg
                                className="animate-spin h-4 w-4 text-white"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                ></circle>
                                <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                ></path>
                            </svg>
                        ) : (
                            ''
                            // <svg
                            //     className="w-3 h-3"
                            //     xmlns="http://www.w3.org/2000/svg"
                            //     viewBox="0 0 22 22"
                            //     id={button.id}
                            // >
                            //     <path
                            //         fill={button.iconFill}
                            //         d={button.iconPathD}
                            //     ></path>
                            // </svg>
                        )}
                        {button.label}
                    </button>
                ))}
        </>
    )
}

export default TableFooterLeftToolbarButtons
