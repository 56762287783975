import React, { useState, useEffect } from 'react'

import { UserFilter, PositionStatusFilter, SortOrder } from '../FilterTypes'

type Props = {
    stage: string
    criteriaPositionStatus?: PositionStatusFilter

    onClosePopup: (value: boolean) => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "Статус позиции"
    onChangeFilterSettingCriteriaPositionStatusSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaPositionStatus: (
        values: string[] | null
    ) => void
}

const PopupMenuPositionStatus: React.FC<Props> = (props) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(
        props.criteriaPositionStatus?.values || []
    )

    // При обновлении критериев родителя синхронизируем локальное состояние
    useEffect(() => {
        setSelectedValues(props.criteriaPositionStatus?.values || [])
    }, [props.criteriaPositionStatus])

    // Функция для переключения выбранного значения
    const toggleValue = (value: string) => {
        let newSelected = [...selectedValues]

        if (props.stage == 'Редактирование') {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((v) => v !== value)
            } else {
                newSelected.push(value)
            }
        } else if (props.stage == 'Подготовка к размещению') {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((v) => v !== value)
            } else {
                newSelected.push(value)
            }
        } else if (props.stage == 'Отправка в ЕИС') {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((v) => v !== value)
            } else {
                newSelected.push(value)
            }
        } else {
            if (newSelected.includes(value)) {
                newSelected = newSelected.filter((v) => v !== value)
            } else {
                newSelected.push(value)
            }
        }

        // Если массив пустой, result будет равен null
        const result = newSelected.length === 0 ? null : newSelected

        // Локальное состояние оставляем как массив, чтобы UI корректно обновлялся
        setSelectedValues(newSelected)
        console.log(newSelected)
        props.onChangeFilterSettingCriteriaPositionStatus(result)
    }

    const selectAllValues = () => {
        if (props.stage == 'Редактирование') {
            const allValues = ['Готово', 'Черновик']
            setSelectedValues(allValues)
            console.log(allValues)
            props.onChangeFilterSettingCriteriaPositionStatus(allValues)
        } else if (props.stage == 'Подготовка к размещению') {
            const allValues = ['Готово', 'Подготовка']
            setSelectedValues(allValues)
            console.log(allValues)
            props.onChangeFilterSettingCriteriaPositionStatus(allValues)
        } else if (props.stage == 'Отправка в ЕИС') {
            const allValues = ['Готово к отправке', 'Отправлено в ЕИС']
            setSelectedValues(allValues)
            console.log(allValues)
            props.onChangeFilterSettingCriteriaPositionStatus(allValues)
        } else {
            const allValues = ['Размещена', 'Аннулирована']
            setSelectedValues(allValues)
            console.log(allValues)
            props.onChangeFilterSettingCriteriaPositionStatus(allValues)
        }
    }
    return (
        <div className="absolute left-0 my-2 w-56 origin-top-right bg-[#333033] border border-[#696769] rounded-[10px] shadow-lg z-10">
            <div className="p-1">
                {/* Блок с чекбоксами */}
                {props.stage == 'Редактирование' ? (
                    <div className="flex flex-col gap-1">
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Готово')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes('Готово')}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Готово
                            </span>
                        </label>
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Черновик')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes('Черновик')}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Черновик
                            </span>
                        </label>
                    </div>
                ) : props.stage == 'Подготовка к размещению' ? (
                    <div className="flex flex-col gap-1">
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Готово')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes('Готово')}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Готово
                            </span>
                        </label>
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Подготовка')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes('Подготовка')}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Подготовка
                            </span>
                        </label>
                    </div>
                ) : props.stage == 'Отправка в ЕИС' ? (
                    <div className="flex flex-col gap-1">
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Готово к отправке')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes(
                                    'Готово к отправке'
                                )}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Готово к отправке
                            </span>
                        </label>
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Отправлено в ЕИС')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes(
                                    'Отправлено в ЕИС'
                                )}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Отправлено в ЕИС
                            </span>
                        </label>
                    </div>
                ) : props.stage == 'План закупки в ЕИС' ? (
                    <div className="flex flex-col gap-1">
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Размещена')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes('Размещена')}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Размещена
                            </span>
                        </label>
                        <label
                            className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                            onClick={(e) => {
                                e.stopPropagation()
                                toggleValue('Аннулирована')
                            }}
                        >
                            <input
                                type="checkbox"
                                className="form-checkbox h-4 w-4"
                                checked={selectedValues.includes(
                                    'Аннулирована'
                                )}
                                readOnly
                                onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                            />
                            <span className="ml-2 text-[12.8px] text-white">
                                Аннулирована
                            </span>
                        </label>
                    </div>
                ) : (
                    ''
                )}

                <div className="flex flex-row w-full px-4 text-[11px] text-left items-center justify-end mt-1">
                    <label
                        className="w-full text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            selectAllValues()
                        }}
                    >
                        Выделить все
                    </label>
                    <label
                        className="w-full text-[11px] text-right text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaPositionStatus(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                {/* Разделитель */}
                <hr className="mt-3 mx-4 border-[#464447]" />

                <div className="flex flex-col w-full gap-1 mt-3">
                    {/* Заголовок сортировки и кнопки сортировки */}
                    <div className="px-4 text-[11px] text-left text-[#8A8A8A]">
                        Сортировка
                    </div>
                    {/* Разделитель */}
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaPositionStatusSort(
                                'asc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="rotate(90 12 12)">
                                <path
                                    fill={`${props.criteriaPositionStatus?.sort_order == 'asc' ? '#0284c7' : '#8A8A8A'}`}
                                    d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                ></path>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По возрастанию
                        </span>
                    </button>
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaPositionStatusSort(
                                'desc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="translate(24,0) scale(-1,1)">
                                <g transform="rotate(-90 12 12)">
                                    <path
                                        fill={`${props.criteriaPositionStatus?.sort_order == 'desc' ? '#0284c7' : '#8A8A8A'}`}
                                        d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По убыванию
                        </span>
                    </button>
                </div>

                <div className="flex w-full px-4 text-[11px] text-left items-center justify-end">
                    <label
                        className="w-fit text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaPositionStatusSort(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <div className="flex flex-col w-full mt-3 items-start justify-center">
                    <button
                        className="flex h-6 flex-col w-full gap-1 text-left text-[12.8px] text-white rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            props.onAcceptFilter()
                            props.onClosePopup(false)
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupMenuPositionStatus
