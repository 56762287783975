import React, { useState, useEffect, useRef } from 'react'

import { UserFilter, ContractSubjectFilter, SortOrder } from '../FilterTypes'

type Props = {
    stage: string
    criteriaContractSubject?: ContractSubjectFilter

    onClosePopup: (value: boolean) => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "Статус позиции"
    onChangeFilterSettingCriteriaContractSubjectSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaContractSubject: (
        values: string | null
    ) => void
}

const PopupMenuContractSubject: React.FC<Props> = (props) => {
    // Локальное состояние для строки поиска
    const [searchTerm, setSearchTerm] = useState(
        props.criteriaContractSubject?.query || ''
    )
    // Флаг, отслеживающий, что пользователь изменил значение
    const [isModified, setIsModified] = useState(false)

    // Флаг, чтобы пропустить вызов эффекта на первом рендере
    const isFirstRender = useRef(true)

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }
        // Если пользователь ещё не редактировал input, ничего не делаем
        if (!isModified) return

        const timer = setTimeout(() => {
            props.onChangeFilterSettingCriteriaContractSubject(
                searchTerm.trim() === '' ? null : searchTerm
            )
            // После обновления можно сбросить флаг
            setIsModified(false)
        }, 1000)

        return () => clearTimeout(timer)
    }, [searchTerm, isModified])

    return (
        <div className="absolute left-0 my-2 w-full origin-top-right bg-[#333033] border border-[#696769] rounded-[10px] shadow-lg z-10">
            <div className="p-1">
                <div className="flex flex-col gap-1 w-full">
                    <label className="flex flex-row items-center rounded-lg gap-1 w-full">
                        <input
                            type="text"
                            className="form-input focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] w-full"
                            placeholder="Поиск"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value)
                                setIsModified(true)
                            }}
                        />
                    </label>
                </div>

                <div className="flex flex-row w-full px-4 text-[11px] text-left items-center justify-end mt-1">
                    <label
                        className="w-full text-[11px] text-right text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            setSearchTerm('')
                            props.onChangeFilterSettingCriteriaContractSubject(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                {/* Разделитель */}
                <hr className="mt-3 mx-4 border-[#464447]" />

                <div className="flex flex-col w-full gap-1 mt-3">
                    {/* Заголовок сортировки и кнопки сортировки */}
                    <div className="px-4 text-[11px] text-left text-[#8A8A8A]">
                        Сортировка
                    </div>
                    {/* Разделитель */}
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaContractSubjectSort(
                                'asc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="rotate(90 12 12)">
                                <path
                                    fill={`${props.criteriaContractSubject?.sort_order == 'asc' ? '#0284c7' : '#8A8A8A'}`}
                                    d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                ></path>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По возрастанию
                        </span>
                    </button>
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaContractSubjectSort(
                                'desc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="translate(24,0) scale(-1,1)">
                                <g transform="rotate(-90 12 12)">
                                    <path
                                        fill={`${props.criteriaContractSubject?.sort_order == 'desc' ? '#0284c7' : '#8A8A8A'}`}
                                        d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По убыванию
                        </span>
                    </button>
                </div>

                <div className="flex w-full px-4 text-[11px] text-left items-center justify-end">
                    <label
                        className="w-fit text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaContractSubjectSort(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <div className="flex flex-col w-full mt-3 items-start justify-center">
                    <button
                        className="flex h-6 flex-col w-full gap-1 text-left text-[12.8px] text-white rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            props.onAcceptFilter()
                            props.onClosePopup(false)
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupMenuContractSubject
