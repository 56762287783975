import Section from '../../../../../../components/Section'
import { PlanPositionRow } from '../types'
import PurchaseItemsTable from './PurchaseItemsTable'
import React, { useState, useEffect, useRef } from 'react'
import { PurchaseItemsSectionErrors } from '../types'
import { OrganizationInfo } from '../../../../Org/Home/types'

type Props = {
    planGuid: string
    posGuid: string
    posMSPStatus: boolean | null
    purchaseMethodCode: number | null
    purchaseMethodName: string | null
    isDraft: boolean
    isGeneralAddress: boolean
    onIsGeneralAddressChange: (value: boolean) => void
    okatoCode: string | null
    onOkatoCodeChange: (value: string | null) => void
    region: string | null
    onRegionChange: (value: string | null) => void
    onAreParticipantsSMEChange: (value: boolean) => void
    onOkpdHave: (value: boolean) => void
    positionStatus: string
    positionStage: string
    disabledFieldset: boolean
    onRowsChange: (rows: PlanPositionRow[]) => void
    onHaveError: (value: boolean) => void
    errors?: PurchaseItemsSectionErrors
    posTypeObjectPurchase: string
    onTypeObjectPurchasePositionChange: (name: string) => void
    orgData: OrganizationInfo | undefined
}

const CHECKBOX_CLASS = `shrink-0 
    relative w-11 h-6 bg-gray-200 dark:border-gray-600 dark:bg-gray-700 
    rounded-full peer peer-focus:ring-1 peer-focus:ring-blue-300 
    dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full 
    peer-checked:after:border-white peer-checked:bg-blue-600 after:content-[''] 
    after:absolute after:top-0.5 after:start-[2px] 
    after:bg-white after:border-gray-300 after:border after:rounded-full 
    after:h-5 after:w-5 after:transition-all`

const PurchaseItemsSection: React.FC<Props> = (props) => {
    const [searchQuery, setSearchQuery] = useState(props.okatoCode || '')
    const [options, setOptions] = useState<{ code: string; name: string }[]>([])
    const [isDropdownVisible, setIsDropdownVisible] = useState(false)
    const [shouldFetch, setShouldFetch] = useState(false)
    const dropdownRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        // Если окатоCode задан, выполняем запрос для получения названия
        const fetchOkatoName = async () => {
            if (!props.okatoCode) return

            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/okato/fast_search?text_query=${encodeURIComponent(
                            props.okatoCode
                        )}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                if (!response.ok) {
                    console.error('Failed to fetch OKATO name')
                    return
                }

                const data = await response.json()

                // Предполагаем, что сервер вернёт массив с одним элементом
                if (data.length > 0) {
                    setSearchQuery(data[0].name) // Устанавливаем название в поле
                }
            } catch (error) {
                console.error('Error fetching OKATO name:', error)
            }
        }

        fetchOkatoName()
    }, [props.okatoCode])

    useEffect(() => {
        if (!shouldFetch || searchQuery.trim() === '') {
            setOptions([])
            setIsDropdownVisible(false)
            return
        }

        const fetchOkato = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_API_URL +
                        `/api/backend/v1/okato/fast_search?text_query=${encodeURIComponent(
                            searchQuery
                        )}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                if (!response.ok) {
                    console.error('Failed to fetch OKATO options')
                    return
                }

                const data = await response.json()
                setOptions(data)
                setIsDropdownVisible(true)
            } catch (error) {
                console.error('Error fetching OKATO options:', error)
            }
        }

        const timeoutId = setTimeout(fetchOkato, 300)
        return () => clearTimeout(timeoutId)
    }, [searchQuery, shouldFetch])

    const handleInputChange = (value: string) => {
        setSearchQuery(value)
        setShouldFetch(true)
    }

    const handleOptionSelect = (code: string | null, name: string | '') => {
        props.onOkatoCodeChange(code)
        setSearchQuery(name)
        setIsDropdownVisible(false)
        setShouldFetch(false)
    }

    const handleOutsideClick = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsDropdownVisible(false)
        }
    }

    useEffect(() => {
        if (isDropdownVisible) {
            document.addEventListener('click', handleOutsideClick)
        } else {
            document.removeEventListener('click', handleOutsideClick)
        }

        return () => {
            document.removeEventListener('click', handleOutsideClick)
        }
    }, [isDropdownVisible])

    return (
        <Section title="Информация о закупаемых товарах, работах, услугах">
            <label className="block">
                <span className="block mt-2 text-sm text-secondary text-zinc-500">
                    Способ указания региона поставки
                </span>
            </label>

            <div className="flex flex-col mt-1 gap-3">
                <div className="inline-flex gap-5">
                    <div>
                        <label className="flex flex-row items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="peer appearance-none"
                                checked={props.isGeneralAddress}
                                onChange={(e) => {
                                    props.onIsGeneralAddressChange(true)
                                }}
                            />
                            <div className={CHECKBOX_CLASS}></div>
                            <span className="form-label ml-2">
                                Единое для всех товаров, работ, услуг
                            </span>
                        </label>
                    </div>

                    <div>
                        <label className="flex flex-row items-center cursor-pointer">
                            <input
                                type="checkbox"
                                className="peer appearance-none"
                                checked={!props.isGeneralAddress}
                                onChange={(e) => {
                                    props.onIsGeneralAddressChange(false)
                                    props.onRegionChange(null)
                                    props.onOkatoCodeChange(null)
                                    handleOptionSelect(null, '')
                                }}
                            />
                            <div className={CHECKBOX_CLASS}></div>
                            <span className="form-label ml-2">
                                Отдельное для каждого товара, работы, услуги
                            </span>
                        </label>
                    </div>
                </div>

                {props.isGeneralAddress && (
                    <div className="relative w-full" ref={dropdownRef}>
                        {props.isGeneralAddress && (
                            <div className="flex flex-row w-full gap-5">
                                <div className="flex w-[500px]">
                                    <div className="flex flex-col w-full">
                                        <label className="w-full mb-1 text-sm text-zinc-500">
                                            Регион
                                        </label>
                                        <input
                                            type="text"
                                            className={`form-input user-input-placeholder focus:outline-none focus:ring-1 focus:ring-[#FFFFFF] w-full ${
                                                props.errors?.okato
                                                    ? 'border border-[#C15C5C]'
                                                    : ''
                                            }`}
                                            placeholder="Введите название или код ОКАТО"
                                            value={searchQuery}
                                            onChange={(e) => {
                                                handleInputChange(
                                                    e.target.value
                                                )
                                                props.onRegionChange(null)
                                            }}
                                            onFocus={() =>
                                                setIsDropdownVisible(
                                                    !!options.length
                                                )
                                            }
                                            title={
                                                props.errors?.okato
                                                    ? props.errors?.okato
                                                    : ''
                                            }
                                        />
                                    </div>
                                    {isDropdownVisible && (
                                        <div
                                            className="flex w-[500px] absolute bg-[#181818] border border-[#75747A] max-h-40 overflow-y-auto shadow-lg z-50 text-sm rounded-md p-1 text-white"
                                            style={{
                                                top: '100%',
                                                left: 0,
                                            }}
                                        >
                                            <ul className="flex flex-col w-[500px]">
                                                {options.map((option) => (
                                                    <li
                                                        key={option.code}
                                                        className="flex flex-1 p-1 cursor-pointer hover:bg-[#52525E] rounded-md"
                                                        onClick={() =>
                                                            handleOptionSelect(
                                                                option.code,
                                                                option.name
                                                            )
                                                        }
                                                    >
                                                        {option.name} (
                                                        {option.code})
                                                    </li>
                                                ))}
                                                {options.length === 0 && (
                                                    <li className="flex p-1 rounded-md">
                                                        Ничего не найдено
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                <PurchaseItemsTable
                    planGuid={props.planGuid}
                    posGuid={props.posGuid}
                    posMSPStatus={props.posMSPStatus}
                    purchaseMethodCode={props.purchaseMethodCode}
                    purchaseMethodName={props.purchaseMethodName}
                    isDraft={props.isDraft}
                    showRegion={!props.isGeneralAddress}
                    onAreParticipantsSMEChange={
                        props.onAreParticipantsSMEChange
                    }
                    onOkpdHave={props.onOkpdHave}
                    disabledFieldset={props.disabledFieldset}
                    onRowsChange={props.onRowsChange}
                    onHaveError={props.onHaveError}
                    errors={props.errors}
                    posTypeObjectPurchase={props.posTypeObjectPurchase}
                    onTypeObjectPurchasePositionChange={
                        props.onTypeObjectPurchasePositionChange
                    }
                    orgData={props.orgData}
                />
            </div>
        </Section>
    )
}

export default PurchaseItemsSection
