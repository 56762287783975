import React, { useEffect, useState } from 'react'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { Plan } from './types'

import Add from '../../../components/Toolbar/Add'
import '../../../components/Toolbar/Icon.css'
import { Role } from '../../Hub/Org/Roles/types'
import { useAuth } from '../../../hooks/auth'

type ApiResponse = {
    message: Plan[]
}

interface StartEndDate {
    start_date: string
    end_date: string
}

type ApiResponseRoleGuid = {
    result: QueryItem[]
}

type QueryItem = {
    guid: string
    name: string
    organization_guid: string
    created_at: string
    updated_at: string
}

function getFirstDayOfNextYear() {
    const currentYear = new Date().getFullYear() // Текущий год
    const nextYear = currentYear + 1 // Следующий год
    const firstDay = new Date(nextYear, 0, 1) // 1 января следующего года

    // Форматируем дату в YYYY-MM-DD
    const year = firstDay.getFullYear()
    const month = String(firstDay.getMonth() + 1).padStart(2, '0') // Январь = 0, поэтому добавляем 1
    const day = String(firstDay.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

function getLastDayOfNextYear() {
    const currentYear = new Date().getFullYear() // Текущий год
    const nextYear = currentYear + 1 // Следующий год
    const lastDay = new Date(nextYear, 11, 31) // 31 декабря следующего года

    // Форматируем дату в YYYY-MM-DD
    const year = lastDay.getFullYear()
    const month = String(lastDay.getMonth() + 1).padStart(2, '0') // Декабрь = 11, добавляем 1
    const day = String(lastDay.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const loadPlanList = async (): Promise<Plan[]> => {
    return fetch(process.env.REACT_APP_API_URL + '/api/backend/v1/plans', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Failed to fetch plans')
            }
        })
        .then((response: ApiResponse) => response.message)
        .catch((e) => {
            console.error(e)
            return []
        })
}

const getRoleList = async (): Promise<Role[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organizations/roles/',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then(async (response) => {
            if (response.ok) {
                const data = await response.json()
                return data.result // Достаём массив из result
            } else {
                throw new Error('Ошибка запроса на роли')
            }
        })
        .catch((e) => {
            console.error('Ошибка:', e)
            return [] // Возвращаем пустой массив в случае ошибки
        })
}

const getRole = async (guid: string): Promise<QueryItem> => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api/backend/v1/organizations/roles_by_guid/${guid}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )

        if (!response.ok) {
            throw new Error('Ошибка запроса на роль')
        }

        const data: ApiResponseRoleGuid = await response.json()
        // Извлекаем первый элемент массива, т.к. он всегда содержит единственный объект
        return data.result[0]
    } catch (error) {
        console.error('Ошибка:', error)
        // Возвращаем объект по умолчанию, соответствующий типу QueryItem
        return {
            guid: '',
            name: '',
            organization_guid: '',
            created_at: '',
            updated_at: '',
        }
    }
}

const PlanListPage: React.FC = () => {
    const plans2 = useLoaderData() as Plan[]
    const [plans, setPlans] = useState<Plan[]>(plans2) // Состояние для списка планов
    const [planCounts, setPlanCounts] = useState<
        Record<
            string,
            { poses_count: number; poses_draft_count: number } | null
        >
    >({})

    const [deletePlanPopup, setDeletePlanPopup] = useState(false)
    const [addPlanPopup, setAddPlanPopup] = useState(false)
    const [isAdding, setIsAdding] = useState(false) // Состояние для анимации загрузки
    const [isDeleting, setIsDeleting] = useState(false) // Состояние для анимации загрузки
    const [selectedPlanGuid, setSelectedPlanGuid] = useState<string | null>(
        null
    ) // Добавлено состояние для plan_guid

    const [isPublishedPlan, setPublishedPlan] = useState(true) // Состояние для анимации загрузки
    const [purchasePlanNumber, setPurchasePlanNumber] = useState('')
    const [startEndDate, setStartEndDate] = useState<StartEndDate>({
        start_date: getFirstDayOfNextYear(),
        end_date: getLastDayOfNextYear(),
    })

    const [isErrorMessage, setIsErrorMessage] = useState<string | null>(null) // Состояние для анимации загрузки

    const { user } = useAuth()

    const [isUserRoleGuid, setUserRoleGuid] = useState('')
    const [isUserRoleName, setUserRoleName] = useState('')

    const navigate = useNavigate()

    useEffect(() => {
        // Синхронизируйте локальное состояние с маршрутом

        const fetchData = async () => {
            const role = await getRole(user?.object.role_id)
            setUserRoleName(role.name)
            setUserRoleGuid(role.guid)
        }
        fetchData()
    }, [])

    const planPositionsCount = async (plan_guid: string) => {
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plan_positions_count/?plan_guid=${plan_guid}`,
                {
                    method: 'GET',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            if (!response.ok) throw new Error('Ошибка')

            const data = await response.json()
            setPlanCounts((prev) => ({
                ...prev,
                [plan_guid]: {
                    poses_count: data.poses_count,
                    poses_draft_count: data.poses_draft_count,
                },
            }))
        } catch (error) {
            console.error(
                'Ошибка при получении количества позиций плана:',
                error
            )
            setPlanCounts((prev) => ({
                ...prev,
                [plan_guid]: null,
            }))
        }
    }

    const renderPlanData = (
        plan_guid: string,
        key: 'poses_count' | 'poses_draft_count'
    ) => {
        const planData = planCounts[plan_guid]
        if (planData === undefined) return 'Загрузка...'
        if (planData === null) return 'Ошибка'
        return planData[key]
    }

    useEffect(() => {
        plans.forEach((plan) => {
            if (!(plan.guid in planCounts)) {
                planPositionsCount(plan.guid)
            }
        })
    }, [plans])

    const handleDeletePlanPopup = (plan_guid: string | null = null) => {
        setSelectedPlanGuid(plan_guid) // Установить plan_guid в состояние
        setDeletePlanPopup(!deletePlanPopup)
    }

    const handleDeletePlan = async () => {
        setIsDeleting(true) // Включаем анимацию загрузки
        if (!selectedPlanGuid) return

        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL +
                    `/api/backend/v1/plans/${selectedPlanGuid}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при удалении плана')
            }
            setDeletePlanPopup(false)
            setPlans((prevPlans) =>
                prevPlans.filter((plan) => plan.guid !== selectedPlanGuid)
            )
            setSelectedPlanGuid(null)
            console.log('План успешно удален')
        } catch (error) {
            console.error('Ошибка удаления плана:', error)
        } finally {
            setIsDeleting(false) // Отключаем анимацию загрузки
        }
    }

    // Обработчик изменения поля ввода
    const handleInputChange = (event: any) => {
        setIsErrorMessage('')
        const value = event.target.value.replace(/\D/g, '') // Удаляет все, кроме цифр
        if (value.length <= 10) {
            setPurchasePlanNumber(value) // Устанавливает значение только если <= 10 символов
        }
    }

    const loadPlans = async () => {
        const newPlans = await loadPlanList()
        setPlans(newPlans)
    }

    const handleInputStartEndDateChange = (
        fieldName: keyof StartEndDate,
        value: string
    ) => {
        setStartEndDate((prev) => ({
            ...prev,
            [fieldName]: value,
        }))
    }

    const handlePablishedPlan = () => {
        setPurchasePlanNumber('')
        handleInputStartEndDateChange('start_date', getFirstDayOfNextYear())
        handleInputStartEndDateChange('end_date', getLastDayOfNextYear())
        setPublishedPlan((prevState) => !prevState)
    }

    const handleAddPlanPopup = () => {
        setPurchasePlanNumber('')
        setIsErrorMessage('')
        handleInputStartEndDateChange('start_date', getFirstDayOfNextYear())
        handleInputStartEndDateChange('end_date', getLastDayOfNextYear())
        setPublishedPlan(true)
        setAddPlanPopup(!addPlanPopup)
    }

    const handleAddPublishedPlan = async (reg_number: string) => {
        setIsAdding(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/backend/v1/plan_from_eis`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reg_number }), // Добавлено тело запроса
                }
            )

            if (response.ok) {
                const responseData = await response.json()
                setIsErrorMessage(responseData.details)
                if (!responseData.details) {
                    setAddPlanPopup(false)
                }
            }

            if (!response.ok) {
                throw new Error('Ошибка при добавлении плана')
            }
            loadPlans()
        } catch (error) {
            setIsErrorMessage(
                'Ошибка при добавлении плана. Проверьте номер плана.'
            )
            console.error('Ошибка при добавлении плана:', error)
        } finally {
            setIsAdding(false) // Отключаем анимацию загрузки
        }
    }

    const handleAddEmptyPlan = async (start_end_date: StartEndDate) => {
        setIsAdding(true) // Включаем анимацию загрузки
        try {
            const response = await fetch(
                process.env.REACT_APP_API_URL + `/api/backend/v1/plans`,
                {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(start_end_date), // Добавлено тело запроса
                }
            )
            if (!response.ok) {
                throw new Error('Ошибка при добавлении плана')
            }
            loadPlans()
            console.log('План успешно добавлен')
            setAddPlanPopup(false)
        } catch (error) {
            console.error('Ошибка при добавлении плана:', error)
        } finally {
            setIsAdding(false) // Отключаем анимацию загрузки
        }
    }

    return (
        <div className="h-full flex flex-col">
            <div className="bg-[#111111]">
                <div className="w-auto h-24 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-24 my-auto content-center ">
                        <p className="text-xs text-zinc-500">Подразделение</p>
                        <p className="text-xs text-white">Управление закупок</p>
                    </div>
                </div>
                <div className="flex flex-row cursor-pointer ">
                    <div className="w-5"></div>
                    <span className="w-60 h-7 content-center text-center text-sm text-white rounded-t-[10px] bg-[#181818]">
                        Реестр планов закупки
                    </span>
                </div>
            </div>

            {/* body */}
            <div className="flex bg-[#181818] dark:bg-[#181818] p-5 duration-200 ease-in-out h-dvh justify-center">
                {/* table */}
                <div className="flex flex-col w-full min-w-[1260px]">
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t-lg ">
                        {/* <Add color={'#FFFFFF'} /> */}
                    </div>
                    {/* name */}
                    <div className="flex flex-row gap-1 h-10 bg-[#262427] text-[#8A8A8A] text-[10px] p-1 ">
                        <div className="flex w-12 items-center justify-center text-center">
                            №
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Период планирования
                        </div>
                        <div className="flex w-28 items-center justify-center text-center">
                            Статус
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Версия плана
                        </div>
                        <div className="flex w-28 items-center justify-center text-center">
                            Номер плана в ЕИС
                        </div>
                        <div className="flex-1 flex items-center justify-center text-center min-w-56">
                            Вид плана закупки
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Размещено позиций
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            На размещении
                        </div>
                        <div className="flex w-36 items-center justify-center text-center">
                            Период действия плана
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Дата размещения
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Дата утверждения
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Дата обновления
                        </div>
                        <div className="flex w-10 items-center justify-center text-center"></div>
                    </div>
                    {/* date */}
                    <div className="flex flex-col overflow-y-auto gap-1 py-1 grow bg-[#1D1C1E]">
                        {plans.map((plan, index) => (
                            <div
                                className="flex flex-row mx-1 rounded cursor-pointer bg-[#2A282B] hover:bg-[#373538] "
                                key={plan.guid}
                            >
                                <div
                                    onDoubleClick={() =>
                                        navigate(
                                            '/hub/plans/' +
                                                plan.guid +
                                                '/positions?stage=Редактирование'
                                        )
                                    }
                                    className="flex flex-row flex-1 gap-1 text-white text-[11px] "
                                >
                                    <div className="flex h-12 w-12 items-center justify-center text-center text-[#8A8A8A]">
                                        {index + 1}
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {plan.start_date.split('-')[0]}
                                    </div>
                                    <div className="flex flex-row gap-2 h-12 w-28 items-center justify-center text-center rounded">
                                        {plan.registration_number &&
                                        plan.approve_date ? (
                                            <>
                                                <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#187A33]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="check"
                                                        className="h-4 w-4"
                                                    >
                                                        <path
                                                            fill="#FFFFFF"
                                                            d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <div>Размещен</div>
                                            </>
                                        ) : (
                                            <>
                                                <div className="flex w-5 h-5 rounded-full items-center justify-center text-white bg-[#dc742f]">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 24 24"
                                                        id="check"
                                                        className="h-4 w-4"
                                                    >
                                                        <path
                                                            fill="#FFFFFF"
                                                            d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <div>Не размещен</div>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex h-12 w-20 items-center justify-center text-center">
                                        {plan.version ? plan.version : '-'}
                                    </div>
                                    <div className="flex h-12 w-28 items-center justify-center text-center">
                                        {plan.registration_number
                                            ? plan.registration_number
                                            : '-'}
                                    </div>
                                    <div className="flex h-12 flex-1 items-center justify-center text-left min-w-56 px-1">
                                        <p>
                                            <a
                                                onClick={() =>
                                                    navigate(
                                                        '/hub/plans/' +
                                                            plan.guid +
                                                            '/positions'
                                                    )
                                                }
                                                className=""
                                            >
                                                {plan.plan_type === 'COMMODITY'
                                                    ? 'План закупки товаров, работ, услуг'
                                                    : 'План закупки инновационной продукции, высокотехнологичной продукции и лекарственных средств'}
                                            </a>
                                        </p>
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {renderPlanData(
                                            plan.guid,
                                            'poses_count'
                                        )}
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {renderPlanData(
                                            plan.guid,
                                            'poses_draft_count'
                                        )}
                                    </div>

                                    <div className="flex h-12 w-36 items-center justify-center text-center">
                                        {plan.start_date
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}{' '}
                                        -{' '}
                                        {plan.end_date
                                            .split('-')
                                            .reverse()
                                            .join('-')
                                            .replace(/[\-]/g, '.')}
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {plan.publication_date_time ? (
                                            <>
                                                {plan.publication_date_time
                                                    ?.slice(0, 10)
                                                    .split('-')
                                                    .reverse()
                                                    .join('.')}
                                                <br />
                                                {plan.publication_date_time?.slice(
                                                    11,
                                                    16
                                                )}
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                        <br />
                                        {}
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {plan.approve_date
                                            ? plan.approve_date
                                                  .split('-')
                                                  .reverse()
                                                  .join('-')
                                                  .replace(/[\-]/g, '.')
                                            : '-'}
                                    </div>
                                    <div className="flex h-12 w-24 items-center justify-center text-center">
                                        {plan.sys_updated_at ? (
                                            <>
                                                {plan.sys_updated_at
                                                    ?.slice(0, 10)
                                                    .split('-')
                                                    .reverse()
                                                    .join('.')}
                                                <br />
                                                {plan.sys_updated_at?.slice(
                                                    11,
                                                    16
                                                )}
                                            </>
                                        ) : (
                                            '-'
                                        )}
                                    </div>
                                </div>
                                <div className="flex w-10 items-center justify-center">
                                    {/* button Delete*/}
                                    {isUserRoleName == 'admin' ? (
                                        <button
                                            className="flex w-6 h-6 rounded-full items-center justify-center hover:bg-[#701F1F]"
                                            onClick={() =>
                                                handleDeletePlanPopup(plan.guid)
                                            } // Передать plan_guid
                                        >
                                            <svg
                                                className="w-3 h-3"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="trash-alt"
                                            >
                                                <path
                                                    fill="#FFFFFF"
                                                    d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"
                                                ></path>
                                            </svg>
                                        </button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* buttons */}
                    <div className="flex flex-row gap-1 text-white p-2 px-[22px] bg-[#333033] rounded-b-lg h-10">
                        {isUserRoleName == 'admin' ||
                        isUserRoleName == 'specialist' ? (
                            <button
                                className="flex flex-row min-w-[120px] h-6 px-4 text-white text-xs rounded-[10px] justify-center items-center gap-2 bg-[#494849] hover:bg-[#656365]"
                                onClick={handleAddPlanPopup}
                            >
                                <svg
                                    className="h-3 w-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 22 22"
                                    id="plus"
                                >
                                    <path
                                        fill="#FFF"
                                        d="M19,11H13V5a1,1,0,0,0-2,0v6H5a1,1,0,0,0,0,2h6v6a1,1,0,0,0,2,0V13h6a1,1,0,0,0,0-2Z"
                                    ></path>
                                </svg>{' '}
                                Добавить план
                            </button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>

            {deletePlanPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={() => handleDeletePlanPopup(null)}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[500px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-row w-full items-center justify-stsrt gap-2">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="times-circle"
                            >
                                <path
                                    fill="#C15C5C"
                                    d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"
                                ></path>
                            </svg>
                            <h2 className="text-white text-[18px]">
                                Удаление плана
                            </h2>
                        </div>
                        <div className="flex flex-col mt-2">
                            <label className="text-[14px] text-white">
                                Вы точно хотите удалить план.
                            </label>
                            <label className="text-[14px] text-red-600">
                                Действие необратимо!
                            </label>
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className="flex flex-row min-w-[120px] h-6 justify-center items-center rounded-[10px] gap-1 text-[11px] text-white bg-green-700 hover:bg-green-600"
                                onClick={() => handleDeletePlanPopup(null)}
                            >
                                Отмена
                            </button>
                            <button
                                className="flex flex-row min-w-[120px] h-6 justify-center items-center rounded-[10px] gap-1 text-[11px] text-white bg-[#494849] hover:bg-[#701F1F]"
                                onClick={handleDeletePlan} // Вызвать handleDelete
                            >
                                {isDeleting ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Удалить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {addPlanPopup && (
                <div
                    className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleAddPlanPopup}
                >
                    <div
                        className="p-5 rounded-lg bg-[#262427] w-[500px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-white">Добавление плана закупки</h2>
                        <div className="flex flex-col mt-2 space-y-1">
                            <label className="flex flex-row items-center">
                                <div
                                    onClick={handlePablishedPlan}
                                    className={`switch ${isPublishedPlan ? 'switch-on' : 'switch-off'}`}
                                >
                                    <div
                                        className={`switch-toggle ${
                                            isPublishedPlan
                                                ? `toggle-on`
                                                : `toggle-off`
                                        }`}
                                    >
                                        {isPublishedPlan ? (
                                            <svg
                                                className="w-[15px] h-[15px]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="check"
                                            >
                                                <path
                                                    fill={`#187A33`}
                                                    d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"
                                                ></path>
                                            </svg>
                                        ) : (
                                            <svg
                                                className="w-[15px] h-[15px]"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                id="times"
                                            >
                                                <path
                                                    fill={`#656365`}
                                                    d="M13.41,12l4.3-4.29a1,1,0,1,0-1.42-1.42L12,10.59,7.71,6.29A1,1,0,0,0,6.29,7.71L10.59,12l-4.3,4.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l4.29,4.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"
                                                ></path>
                                            </svg>
                                        )}
                                    </div>
                                </div>
                                <span
                                    className="w-full ml-5 text-[12.8px] text-[#8A8A8A] cursor-pointer"
                                    onClick={handlePablishedPlan}
                                >
                                    Размещенный план
                                </span>
                            </label>

                            {isPublishedPlan ? (
                                <div className="flex flex-col w-full space-y-1">
                                    <div>
                                        <label className="text-[12.8px] text-[#8A8A8A]">
                                            Реестровый номер{' '}
                                        </label>
                                    </div>
                                    <div className="flex w-full space-x-5 ">
                                        <div className="flex flex-1 flex-col w-full ">
                                            <input
                                                type="text"
                                                className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                                value={purchasePlanNumber} // Привязка значения
                                                onChange={handleInputChange} // Обновление состояния
                                                maxLength={10} // Ограничение на уровне HTML
                                            />
                                            {isErrorMessage ? (
                                                <span
                                                    style={{
                                                        color: '#C15C5C',
                                                        fontSize: '12.8px',
                                                    }}
                                                >
                                                    {isErrorMessage}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex flex-row w-full gap-5">
                                    <div className="flex flex-col flex-1 w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Дата начала периода{' '}
                                            </label>
                                        </div>
                                        <div className="flex w-full space-x-5 ">
                                            <div className="flex flex-1 w-full ">
                                                <input
                                                    type="date"
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                                    value={
                                                        startEndDate.start_date
                                                    } // Привязка значения
                                                    onChange={(e) =>
                                                        handleInputStartEndDateChange(
                                                            'start_date',
                                                            e.target.value
                                                        )
                                                    } // Обновление состояния
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col flex-1 w-full space-y-1">
                                        <div>
                                            <label className="text-[12.8px] text-[#8A8A8A]">
                                                Дата окончания периода{' '}
                                            </label>
                                        </div>
                                        <div className="flex w-full space-x-5 ">
                                            <div className="flex flex-1 w-full ">
                                                <input
                                                    type="date"
                                                    className="w-full h-7 px-2 rounded-md border-0 text-sm bg-[#181818] text-white focus:outline-none focus:ring-1 focus:ring-[#ffffff8f]"
                                                    value={
                                                        startEndDate.end_date
                                                    } // Привязка значения
                                                    onChange={(e) =>
                                                        handleInputStartEndDateChange(
                                                            'end_date',
                                                            e.target.value
                                                        )
                                                    } // Обновление состояния
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-row mt-5 text-right justify-end gap-2">
                            <button
                                className="flex flex-row min-w-[120px] h-6 justify-center items-center rounded-[10px] gap-1 text-[11px] text-white bg-[#494849] hover:bg-[#656365]"
                                onClick={handleAddPlanPopup}
                            >
                                Отмена
                            </button>
                            <button
                                className={`flex flex-row min-w-[120px] h-6 justify-center items-center rounded-[10px] text-[11px] gap-1 ${
                                    isPublishedPlan
                                        ? purchasePlanNumber.length === 10
                                            ? 'text-white bg-green-700 hover:bg-green-600'
                                            : 'text-[#8A8A8A] bg-[#494849] cursor-not-allowed'
                                        : startEndDate.start_date != '' &&
                                            startEndDate.end_date != ''
                                          ? 'text-white bg-green-700 hover:bg-green-600'
                                          : 'text-[#8A8A8A] bg-[#494849] cursor-not-allowed'
                                }`}
                                onClick={() =>
                                    isPublishedPlan
                                        ? handleAddPublishedPlan(
                                              purchasePlanNumber
                                          )
                                        : handleAddEmptyPlan(startEndDate)
                                } // Вызвать handleAdd
                                disabled={
                                    isPublishedPlan
                                        ? purchasePlanNumber.length === 10 ||
                                          isAdding
                                            ? false
                                            : true
                                        : (startEndDate.start_date != '' &&
                                                startEndDate.end_date != '') ||
                                            isAdding
                                          ? false
                                          : true
                                }
                            >
                                {isAdding ? (
                                    <svg
                                        className="animate-spin h-4 w-4 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                                        ></path>
                                    </svg>
                                ) : (
                                    'Добавить'
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanListPage
