import React from 'react'
import Section from '../../../../../../components/Section'
import {
    PurchasePaymentBreakdownErrors,
    PurchaseDatesInfoErrors,
} from '../types'

type Props = {
    start: Date
    onStartDateChange: (value: Date, isFullDate: boolean) => void
    end: Date
    onEndDateChange: (value: Date) => void
    isStartFullDate: boolean
    planYear: string
    onLongTerm: (value: boolean) => void
    errors?: PurchasePaymentBreakdownErrors
    errorsDatesInfo?: PurchaseDatesInfoErrors
}

const _MONTHS = [
    <option key="-1" value="-1" disabled>
        Месяц
    </option>,
    <option key="0" value="0">
        Январь
    </option>,
    <option key="1" value="1">
        Февраль
    </option>,
    <option key="2" value="2">
        Март
    </option>,
    <option key="3" value="3">
        Апрель
    </option>,
    <option key="4" value="4">
        Май
    </option>,
    <option key="5" value="5">
        Июнь
    </option>,
    <option key="6" value="6">
        Июль
    </option>,
    <option key="7" value="7">
        Август
    </option>,
    <option key="8" value="8">
        Сентябрь
    </option>,
    <option key="9" value="9">
        Октябрь
    </option>,
    <option key="10" value="10">
        Ноябрь
    </option>,
    <option key="11" value="11">
        Декабрь
    </option>,
]

const _formatDate = (date: Date) => {
    let month = String(date.getMonth() + 1)
    if (month.length < 2) month = '0' + month

    let day = String(date.getDate() + 1)
    if (day.length < 2) day = '0' + day

    return `${date.getFullYear()}-${month}-${day}`
}

function _formatDateOther(date: Date): string {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // Месяцы от 0 до 11
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

const PurchaseDatesInfo: React.FC<Props> = (props) => {
    const previousYear = props.planYear
        ? Number(props.planYear)
        : new Date().getFullYear()

    // Теперь можно создать список годов, используя computed previousYear
    const years = [
        <option key="-1" value="-1" disabled>
            Год
        </option>,
        ...Array.from({ length: 10 }, (_, index) => {
            const year = previousYear + index
            return (
                <option key={year} value={year}>
                    {year}
                </option>
            )
        }),
    ]
    return (
        <Section title="Информация о планах проведения закупки">
            <div className="form-label">
                Планируемая дата или период размещения извещения о закупке
            </div>
            <div
                className={`flex flex-row mb-2 items-center w-fit rounded-md ${
                    props.errorsDatesInfo?.purchase_planned_date
                        ? 'border border-[#C15C5C]'
                        : ''
                }`}
                title={
                    props.errorsDatesInfo?.purchase_planned_date
                        ? props.errorsDatesInfo?.purchase_planned_date
                        : ''
                }
            >
                <div className="md:mr-5 min-w-[200px]">
                    <select
                        className="form-select min-w-[200px]"
                        value={
                            props.isStartFullDate
                                ? -1
                                : props.start.getFullYear()
                        }
                        onChange={(e) => {
                            const newYear = e.target.value
                            props.onStartDateChange(
                                new Date(
                                    Number(newYear),
                                    props.start.getMonth(),
                                    1
                                ),
                                false
                            )
                        }}
                    >
                        {years}
                    </select>
                </div>
                <div className="md:mr-5 min-w-[200px]">
                    <select
                        className="form-select min-w-[200px]"
                        value={
                            props.isStartFullDate ? -1 : props.start.getMonth()
                        }
                        onChange={(e) => {
                            const newMonth = e.target.value
                            props.onStartDateChange(
                                new Date(
                                    props.start.getFullYear(),
                                    Number(newMonth),
                                    1
                                ),
                                false
                            )
                        }}
                    >
                        {_MONTHS}
                    </select>
                </div>
                <div className="md:mr-5">
                    <span className="form-label">/</span>
                </div>
                <div>
                    <input
                        type="date"
                        className="form-input min-w-[200px] focus:outline-none focus:ring-1 focus:ring-[#FFFFFF]"
                        value={
                            props.isStartFullDate
                                ? _formatDateOther(props.start)
                                : ''
                        }
                        onChange={(e) => {
                            const date = e.target.value
                                ? new Date(e.target.value)
                                : new Date()
                            props.onStartDateChange(date, true)
                        }}
                        style={{ colorScheme: 'dark' }}
                    />
                </div>
            </div>
            <div className="form-label">Срок исполнения договора</div>
            <div
                className={`flex flex-row w-fit rounded-md ${
                    props.errorsDatesInfo?.contract_end_date
                        ? 'border border-[#C15C5C]'
                        : ''
                } ${
                    props.errorsDatesInfo?.purchase_planned_date
                        ? 'border border-[#C15C5C]'
                        : ''
                }`}
                title={
                    props.errorsDatesInfo
                        ? props.errorsDatesInfo?.purchase_planned_date
                            ? props.errorsDatesInfo?.purchase_planned_date
                            : props.errorsDatesInfo?.contract_end_date
                        : ''
                }
            >
                <div className="md:mr-5 min-w-[200px]">
                    <select
                        className={`form-select min-w-[200px] ${
                            props.errors?.longTermVolue
                                ? 'border border-[#C15C5C]'
                                : ''
                        }`}
                        title={
                            props.errors?.longTermVolue
                                ? 'Информация об объемах оплаты долгосрочного договора обязательна. Выберите текущий год, потом выберите год, который хотите.'
                                : ''
                        }
                        value={props.end.getFullYear()}
                        onChange={(e) => {
                            const newYear = e.target.value
                            props.onEndDateChange(
                                new Date(
                                    Number(newYear),
                                    props.end.getMonth(),
                                    1
                                )
                            )
                            const long =
                                Number(props.planYear) - Number(e.target.value)
                            if (long != 0) {
                                props.onLongTerm(true)
                            } else {
                                props.onLongTerm(false)
                            }
                        }}
                    >
                        {years}
                    </select>
                </div>
                <div>
                    <select
                        className="form-select min-w-[200px]"
                        value={props.end.getMonth()}
                        onChange={(e) => {
                            const newMonth = e.target.value
                            props.onEndDateChange(
                                new Date(
                                    props.end.getFullYear(),
                                    Number(newMonth),
                                    1
                                )
                            )
                        }}
                    >
                        {_MONTHS}
                    </select>
                </div>
            </div>
        </Section>
    )
}

export default PurchaseDatesInfo
