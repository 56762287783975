import React, { useState, useEffect, useRef } from 'react'

import { UserFilter, StructGuidFilter, SortOrder } from '../FilterTypes'

import { Structure } from '../../../../../Org/Structure/types'

type Props = {
    stage: string
    criteriaStructGuid?: StructGuidFilter

    onClosePopup: (value: boolean) => void
    onAcceptFilter: () => void

    // Фильтрация по столбцу "Статус позиции"
    onChangeFilterSettingCriteriaStructGuidSort: (value: SortOrder) => void
    onChangeFilterSettingCriteriaStructGuid: (
        values: (string | null)[] | null
    ) => void
}

const PopupMenuStruct: React.FC<Props> = (props) => {
    const [selectedValues, setSelectedValues] = useState<(string | null)[]>(
        props.criteriaStructGuid?.values || []
    )

    // Используем useRef для хранения кэшированного значения
    const structsListRef = useRef<Structure[]>([])

    const [isLoading, setLoading] = useState<boolean>(true) // Начинаем с загрузки

    const getStructureList = async (): Promise<Structure[]> => {
        setLoading(true)

        return fetch(
            process.env.REACT_APP_API_URL +
                '/api/backend/v1/organization_struct',
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса на способы закупок')
                }
            })
            .catch((e) => {
                console.error(e)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        // Проверяем наличие данных в localStorage
        const cachedData = localStorage.getItem('structsList')

        if (cachedData) {
            // Если данные уже есть в localStorage, используем их
            structsListRef.current = JSON.parse(cachedData)
            setLoading(false) // Загружены данные из localStorage, завершаем загрузку
        } else {
            // Если данных нет в localStorage, загружаем их с сервера
            const fetchData = async () => {
                const structs = await getStructureList()
                if (structs) {
                    structsListRef.current = structs
                    localStorage.setItem('structsList', JSON.stringify(structs)) // Кешируем данные в localStorage
                    setLoading(false) // Загружены данные, завершаем загрузку
                }
            }
            fetchData()
        }
    }, []) // Загружаем данные только при первом рендере

    useEffect(() => {
        setSelectedValues(props.criteriaStructGuid?.values || [])
    }, [props.criteriaStructGuid])

    const toggleValue = (value: string | null) => {
        let newSelected = [...selectedValues]

        if (newSelected.includes(value)) {
            newSelected = newSelected.filter((v) => v !== value)
        } else {
            newSelected.push(value)
        }

        const result = newSelected.length === 0 ? null : newSelected
        setSelectedValues(newSelected)
        props.onChangeFilterSettingCriteriaStructGuid(result)
    }

    const selectAllValues = () => {
        const allValues: (string | null)[] = structsListRef.current.map(
            (item) => item.guid
        )
        allValues.push(null) // Добавляем null в конец массива
        setSelectedValues(allValues)
        props.onChangeFilterSettingCriteriaStructGuid(allValues)
    }
    return (
        <div className="absolute right-0 my-2 w-64 origin-top-right bg-[#333033] border border-[#696769] rounded-[10px] shadow-lg z-10">
            <div className="p-1">
                {/* Блок с чекбоксами */}

                <div className="flex flex-col gap-1 overflow-y-auto h-52">
                    {isLoading
                        ? 'Загрузка...'
                        : structsListRef.current.map((struct, index) => (
                              <label
                                  key={index}
                                  className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                                  onClick={(e) => {
                                      e.stopPropagation()
                                      toggleValue(struct.guid)
                                  }}
                              >
                                  <input
                                      type="checkbox"
                                      className="form-checkbox h-4 w-4"
                                      checked={selectedValues.includes(
                                          struct.guid
                                      )}
                                      readOnly
                                      onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                                  />
                                  <span
                                      className="ml-2 text-[12.8px] text-white text-left"
                                      title={struct.struct_name}
                                  >
                                      <p className="tworow">
                                          {struct.struct_short_name}
                                      </p>
                                  </span>
                              </label>
                          ))}
                </div>

                <div className="mt-2 flex flex-col gap-1 h-fit">
                    <label
                        className="flex items-center p-1 hover:bg-[#656365] cursor-pointer rounded-lg"
                        onClick={(e) => {
                            e.stopPropagation()
                            toggleValue(null)
                        }}
                    >
                        <input
                            type="checkbox"
                            className="form-checkbox h-4 w-4"
                            checked={selectedValues.includes(null)}
                            readOnly
                            onClick={(e) => e.stopPropagation()} // предотвращаем повторное срабатывание
                        />
                        <span
                            className="ml-2 text-[12.8px] text-white text-left"
                            title="Отсутствует"
                        >
                            <p className="tworow">Отсутствует</p>
                        </span>
                    </label>
                </div>

                <div className="flex flex-row w-full px-4 text-[11px] text-left items-center justify-end mt-1">
                    <label
                        className="w-full text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            selectAllValues()
                        }}
                    >
                        Выделить все
                    </label>
                    <label
                        className="w-full text-[11px] text-right text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaStructGuid(null)
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                {/* Разделитель */}
                <hr className="mt-3 mx-4 border-[#464447]" />

                <div className="flex flex-col w-full gap-1 mt-3">
                    {/* Заголовок сортировки и кнопки сортировки */}
                    <div className="px-4 text-[11px] text-left text-[#8A8A8A]">
                        Сортировка
                    </div>
                    {/* Разделитель */}
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaStructGuidSort(
                                'asc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="rotate(90 12 12)">
                                <path
                                    fill={`${props.criteriaStructGuid?.sort_order == 'asc' ? '#0284c7' : '#8A8A8A'}`}
                                    d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                ></path>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По возрастанию
                        </span>
                    </button>
                    <button
                        className="flex flex-row w-full text-left p-1 hover:bg-[#656365] text-[12.8px] text-white rounded-lg items-center"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaStructGuidSort(
                                'desc'
                            )
                        }}
                    >
                        <svg
                            className="w-3 h-3"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            id="signal-alt"
                        >
                            <g transform="translate(24,0) scale(-1,1)">
                                <g transform="rotate(-90 12 12)">
                                    <path
                                        fill={`${props.criteriaStructGuid?.sort_order == 'desc' ? '#0284c7' : '#8A8A8A'}`}
                                        d="M10,14a1,1,0,0,0-1,1v6a1,1,0,0,0,2,0V15A1,1,0,0,0,10,14ZM5,18a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V19A1,1,0,0,0,5,18ZM20,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,20,2ZM15,9a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V10A1,1,0,0,0,15,9Z"
                                    ></path>
                                </g>
                            </g>
                        </svg>
                        <span className="ml-2 text-[12.8px] text-white">
                            По убыванию
                        </span>
                    </button>
                </div>

                <div className="flex w-full px-4 text-[11px] text-left items-center justify-end">
                    <label
                        className="w-fit text-[11px] text-left text-sky-600 hover:text-sky-400 hover:underline cursor-pointer"
                        onClick={() => {
                            props.onChangeFilterSettingCriteriaStructGuidSort(
                                null
                            )
                        }}
                    >
                        Сбросить
                    </label>
                </div>

                <div className="flex flex-col w-full mt-3 items-start justify-center">
                    <button
                        className="flex h-6 flex-col w-full gap-1 text-left text-[12.8px] text-white rounded-[10px] items-center justify-center bg-[#494849] hover:bg-[#656365]"
                        onClick={() => {
                            props.onAcceptFilter()
                            props.onClosePopup(false)
                        }}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PopupMenuStruct
