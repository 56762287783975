import React, { useState, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'
import { PlanPosition } from './types'
import { useAuth } from '../../../../hooks/auth'
import { User } from '../../Org/Users/types'
import { Structure } from '../../Org/Structure/types'

import './style.css'

import ApprovalsTabs from './components/Tabs/index'
import ApprovalsTableRow from './components/TableRow/index'
import LoadingIcon from '../../../../components/LoadingIcon'

import TableHeaderDraft from './components/TableHeader/TableHeaderDraft'

import {
    UserFilter,
    FilterSetting,
    Criteria,
} from '../Positions/components/TableHeader/FilterTypes'

const getStructureList = async (): Promise<Structure[]> => {
    return fetch(
        process.env.REACT_APP_API_URL + '/api/backend/v1/organization_struct',
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
        .then((response) => {
            if (response.ok) {
                return response.json()
            } else {
                throw new Error('Ошибка запроса на способы закупок')
            }
        })
        .catch((e) => {
            console.error(e)
        })
}

// Отдельная функция для проверки, есть ли скролл
const checkScroll = (element: HTMLElement | null): boolean => {
    return element ? element.scrollHeight > element.clientHeight : false
}

const ApprovalsPage: React.FC = () => {
    const navigate = useNavigate()

    const { user } = useAuth()
    const [structureList, setStructureList] = useState<Structure[]>([])

    const stage = 'Реестр согласований'

    const [isLoading, setLoading] = useState<boolean>(true)
    const [items, setItems] = useState<PlanPosition[]>([])
    const [page, setPage] = useState(1) // -1 - no more data

    //  проверка скрол есть или нет
    const scrollRef = useRef<HTMLDivElement>(null)
    const [hasScroll, setHasScroll] = useState(false)

    const [filter, setFilter] = useState<UserFilter | null>(null)
    // Дополнительное состояние
    const [filterLoaded, setFilterLoaded] = useState(false)

    // Функция для получения фильтров пользователя
    const getUserFilterType = async (
        user_guid: string,
        filter_type: string
    ): Promise<UserFilter> => {
        return fetch(
            process.env.REACT_APP_API_URL +
                `/api/backend/v1/user_and_filtertype?user_guid=${user_guid}&filter_type=${filter_type}`,
            {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка запроса на фильтр пользователя')
                }
            })
            .then((data) => {
                // Если данные представляют собой массив, возвращаем первую позицию (либо null, если массив пуст)
                if (Array.isArray(data) && data.length > 0) {
                    return data[0]
                } else {
                    return null
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    // Функция для обновления фильтра пользователя по PUT-запросу
    const updateUserFilter = async (
        filterData: { filter_type: string; filter_settings: FilterSetting },
        guid: string
    ): Promise<UserFilter> => {
        return fetch(
            `${process.env.REACT_APP_API_URL}/api/backend/v1/user_filter/${guid}`,
            {
                method: 'PUT',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify(filterData),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(
                        'Ошибка запроса на обновление фильтра пользователя'
                    )
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    const createUserFilter = async (filterData: {
        filter_type: string
        filter_settings: { criteria: Criteria | null; filter_enabled: boolean }
    }): Promise<UserFilter> => {
        return fetch(
            process.env.REACT_APP_API_URL + '/api/backend/v1/user_filter',
            {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                },
                body: JSON.stringify(filterData),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error('Ошибка создания фильтра пользователя')
                }
            })
            .catch((error) => {
                console.error(error)
                throw error
            })
    }

    const loadPlanPositionsOnApproval = (
        page_num = 1,
        userFilter: UserFilter | null = null
    ) => {
        setLoading(true)

        const pageSize = 100

        let url = `${process.env.REACT_APP_API_URL}/api/backend/v1/plan_positions_with_approvals_and_user`

        if (userFilter?.filter_settings?.filter_enabled) {
            url += `?filter_settings=${encodeURIComponent(
                JSON.stringify(userFilter.filter_settings)
            )}`
        }

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    throw new Error(
                        'Failed to fetch plan positions (page: ' +
                            page_num +
                            ')'
                    )
                }
            })
            .catch((e) => {
                console.error(e)
                return []
            })
            .then((data) => {
                if (page_num == 1) {
                    setItems(data)
                } else {
                    setItems((prev) => [...prev, ...data])
                }
                setPage(data.length < pageSize ? -1 : page_num + 1)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    // const loadPlanPositionsOnApproval = () => {
    //     setLoading(true)

    //     fetch(
    //         process.env.REACT_APP_API_URL +
    //             '/api/backend/v1/plan_positions_with_approvals_and_user',
    //         {
    //             method: 'GET',
    //             credentials: 'include',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         }
    //     )
    //         .then(async (response) => {
    //             if (response.ok) {
    //                 const data = await response.json()
    //                 setItems(data)
    //                 return data.result // Достаём массив из result
    //             } else {
    //                 throw new Error('Ошибка запроса на роли')
    //             }
    //         })
    //         .catch((e) => {
    //             console.error('Ошибка:', e)
    //             return [] // Возвращаем пустой массив в случае ошибки
    //         })
    //         .finally(() => {
    //             setLoading(false)
    //         })
    // }

    useEffect(() => {
        const fetchData = async () => {
            const structs = await getStructureList()
            if (structs) {
                setStructureList(structs) // Обновляем список структур
            }
        }
        fetchData()
    }, [])

    // Проверка скролла
    useEffect(() => {
        const updateScrollState = () => {
            setHasScroll(checkScroll(scrollRef.current))
        }

        // Первоначальная проверка
        updateScrollState()

        // Перепроверяем при изменении размеров окна
        window.addEventListener('resize', updateScrollState)
        return () => window.removeEventListener('resize', updateScrollState)
    }, [items])

    // Загрузка фильтра при изменении stage или planGuidNav
    useEffect(() => {
        setLoading(true)
        setFilterLoaded(false)

        getUserFilterType(user?.object.user_id, stage)
            .then((data) => {
                setFilter(data)
                setFilterLoaded(true) // фильтр загружен успешно
            })
            .catch((error) => {
                console.error(error)
                setFilter(null) // Если ошибка – отключаем фильтр
                setFilterLoaded(true) // даже при ошибке ставим true, иначе зависнем
            })
    }, [stage])

    // Загрузка позиций при загрузке фильтра
    useEffect(() => {
        if (!filterLoaded) return // Ждём завершение загрузки фильтра

        loadPlanPositionsOnApproval(1, filter)
    }, [filterLoaded, filter])

    // Отдельный эффект на изменение filter:
    useEffect(() => {
        if (filter && filter?.filter_settings?.filter_enabled) {
            loadPlanPositionsOnApproval(1, filter)
        } else if (filter) {
            loadPlanPositionsOnApproval() // Если фильтр есть, но он отключен
        }
    }, [filter]) // <-- Запускается только после обновления filter

    const handleFilterChange = async (currentFilter?: UserFilter) => {
        const filterToUse = currentFilter || filter
        if (filterToUse) {
            updateUserFilter(
                {
                    filter_type: stage,
                    filter_settings: filterToUse.filter_settings,
                },
                filterToUse.guid
            ).catch(console.error)
        }
    }

    const handleFilterErase = async () => {
        if (filter) {
            try {
                const updatedFilter = await updateUserFilter(
                    {
                        filter_type: stage,
                        filter_settings: {
                            criteria: null,
                            filter_enabled: true,
                        },
                    },
                    filter.guid
                )
                setFilter(updatedFilter)
                loadPlanPositionsOnApproval(1, filter)
            } catch (error) {
                console.error('Ошибка обновления фильтра:', error)
            }
        }
    }

    // Функция, вызываемая по нажатию на кнопку
    const handleFilter = async () => {
        if (!filter) {
            try {
                const newFilter = await createUserFilter({
                    filter_type: stage,
                    filter_settings: {
                        criteria: null,
                        filter_enabled: true,
                    },
                })
                setFilter(newFilter)
                await loadPlanPositionsOnApproval(1, newFilter) // Используем новый фильтр явно
            } catch (error) {
                console.error('Ошибка создания фильтра:', error)
            }
        } else {
            const currentSettings = filter.filter_settings || {
                filter_enabled: false,
                criteria: {} as Criteria,
            }

            const newFilter: UserFilter = {
                ...filter,
                filter_settings: {
                    ...currentSettings,
                    filter_enabled: !currentSettings.filter_enabled,
                },
            }

            try {
                const updatedFilter = await updateUserFilter(
                    {
                        filter_type: newFilter.filter_type,
                        filter_settings: newFilter.filter_settings,
                    },
                    newFilter.guid
                )
                setFilter(updatedFilter)
                await loadPlanPositionsOnApproval(1, updatedFilter) // <-- тут изменено
            } catch (error) {
                console.error('Ошибка обновления фильтра:', error)
            }
        }
    }

    return (
        <div className="h-full flex flex-col bg-[#181818]">
            <div className="bg-[#111111]">
                <div className="w-auto h-24 mx-6 flex flex-row gap-x-5 ">
                    <div className="w-60 h-24 my-auto content-center ">
                        <p className="text-xs text-zinc-500">Подразделение</p>
                        <p className="text-xs text-white">Управление закупок</p>
                    </div>
                </div>
                <div className="bg-[#111111]">
                    <ApprovalsTabs count={items.length} />
                </div>
            </div>

            {/* // body */}
            <div className="plan-positions flex p-5 h-[calc(100vh_-_96px_-_28px)]">
                {/* table */}
                <div
                    id="printable-content"
                    className="flex flex-col w-full bg-[#1D1C1E] rounded"
                >
                    {/* toolbar */}
                    <div className="flex flex-row gap-1 text-white p-2 h-10 bg-[#333033] rounded-t">
                        <button
                            onClick={handleFilter}
                            className="w-6 h-6 grid rounded-full place-items-center bg-[#333033] hover:bg-[#656365]"
                            title="Добавить строку"
                        >
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="plus"
                            >
                                <path
                                    fill="#FFFFFF"
                                    d={`${
                                        filter?.filter_settings?.filter_enabled
                                            ? 'M19.22,6H12.66a1,1,0,0,0,0,2h6.56a.78.78,0,0,1,.78.78v.78H16.22a1,1,0,1,0,0,2h2.37l-.7.69a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l1.88-1.88a2.51,2.51,0,0,0,.54-.8h0l0-.1A2.59,2.59,0,0,0,22,9.82v-1A2.79,2.79,0,0,0,19.22,6ZM3.71,2.29A1,1,0,0,0,2.29,3.71L5.14,6.55A2.73,2.73,0,0,0,4,8.78v1a2.65,2.65,0,0,0,.24,1.1l0,.06a2.61,2.61,0,0,0,.54.81l5.41,5.4V21a1,1,0,0,0,.47.85,1,1,0,0,0,.53.15,1,1,0,0,0,.45-.11l3.56-1.78a1,1,0,0,0,.55-.89v-2h0l4.51,4.52a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM6,8.78a.76.76,0,0,1,.5-.72L6.59,8,8.15,9.56H6Zm8.07,7.29a1,1,0,0,0-.29.71V18.6l-1.56.78v-2.6a1,1,0,0,0-.29-.71L7.41,11.56h2.74l4.22,4.22Z'
                                            : 'M19,2H5A3,3,0,0,0,2,5V6.17a3,3,0,0,0,.25,1.2l0,.06a2.81,2.81,0,0,0,.59.86L9,14.41V21a1,1,0,0,0,.47.85A1,1,0,0,0,10,22a1,1,0,0,0,.45-.11l4-2A1,1,0,0,0,15,19V14.41l6.12-6.12a2.81,2.81,0,0,0,.59-.86l0-.06A3,3,0,0,0,22,6.17V5A3,3,0,0,0,19,2ZM13.29,13.29A1,1,0,0,0,13,14v4.38l-2,1V14a1,1,0,0,0-.29-.71L5.41,8H18.59ZM20,6H4V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z'
                                    }`}
                                ></path>
                            </svg>
                        </button>
                    </div>

                    {/* name */}
                    {/* <div className="flex flex-row gap-1 h-10 bg-[#262427] text-[#8A8A8A] text-[10px] p-1 py-2">
                        <div className="grid w-12 items-center justify-center text-center">
                            №
                        </div>
                        <div
                            className="flex w-10 items-center justify-center text-center"
                            title="Статус согласования"
                        >
                            <svg
                                className="w-4 h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                id="user-circle"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M20,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,13.05,2H7A3,3,0,0,0,4,5V19a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V9S20,9,20,8.94ZM14,5.41,16.59,8H15a1,1,0,0,1-1-1ZM18,19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V5A1,1,0,0,1,7,4h5V7a3,3,0,0,0,3,3h3Zm-3.71-6.71L11,15.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42Z"
                                ></path>
                            </svg>
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Начало согласования
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Дата <br /> окончания
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Стадия
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Статус
                        </div>
                        <div className="flex flex-1 items-center justify-center text-center min-w-44">
                            Предмет договора
                        </div>
                        <div
                            className="grid w-28 place-items-center text-center"
                            title="Начальная (максимальная) цена договора"
                        >
                            НМЦД
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Дата (период) размещения
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Срок исполнения договора
                        </div>
                        <div className="flex w-44 items-center justify-center text-center">
                            Способ закупки
                        </div>
                        <div className="flex w-24 items-center justify-center text-center">
                            Статус в плане закупки
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Тип объекта закупки
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Подразделение
                        </div>
                        <div className="flex w-20 items-center justify-center text-center">
                            Редакция
                        </div>
                        <div className="flex w-20 items-center justify-center text-center pr-5">
                            Дата изменения
                        </div>
                        <div className="flex w-14 items-center justify-center text-center">
                            <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                data-name="Layer 1"
                                viewBox="0 0 24 24"
                                id="user-circle"
                            >
                                <path
                                    fill="#8A8A8A"
                                    d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"
                                ></path>
                            </svg>
                        </div>
                    </div> */}
                    <TableHeaderDraft
                        stage={stage}
                        hasScroll={hasScroll}
                        filter={filter}
                        onFilterErase={() => {
                            handleFilterErase()
                        }}
                        onAcceptFilter={() => {
                            handleFilterChange()
                        }}
                        // Фильтрация для "Согласования"
                        onChangeFilterSettingCriteriaApprovalStatus={(
                            values
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        approval_status: {
                                            ...filter.filter_settings.criteria
                                                ?.approval_status,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaApprovalStatusSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        approval_status: {
                                            ...filter.filter_settings.criteria
                                                ?.approval_status,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Сротировака по Дате начала согласования
                        onChangeFilterSettingCriteriaApprovalStartSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        approval_start: {
                                            ...filter.filter_settings.criteria
                                                ?.approval_start,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Сротировака по Дате окончания согласования
                        onChangeFilterSettingCriteriaApprovalEndSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        approval_end: {
                                            ...filter.filter_settings.criteria
                                                ?.approval_end,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Статуса позиции"
                        onChangeFilterSettingCriteriaPositionStatus={(
                            values
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        position_status: {
                                            ...filter.filter_settings.criteria
                                                ?.position_status,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaPositionStatusSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        position_status: {
                                            ...filter.filter_settings.criteria
                                                ?.position_status,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Предмета договора"
                        onChangeFilterSettingCriteriaContractSubject={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        contract_subject: {
                                            ...filter.filter_settings.criteria
                                                ?.contract_subject,
                                            query: value,
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaContractSubjectSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        contract_subject: {
                                            ...filter.filter_settings.criteria
                                                ?.contract_subject,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "НМЦД"
                        onChangeFilterSettingCriteriaMaximumContractPrice={(
                            valueMin,
                            valueMax
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        maximum_contract_price: {
                                            ...filter.filter_settings.criteria
                                                ?.maximum_contract_price,
                                            min: valueMin,
                                            max: valueMax,
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaMaximumContractPriceSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        maximum_contract_price: {
                                            ...filter.filter_settings.criteria
                                                ?.maximum_contract_price,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Даты размщения"
                        onChangeFilterSettingCriteriaPurchasePlannedDate={(
                            valueFrom,
                            valueTo
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        purchase_planned_date: {
                                            ...filter.filter_settings.criteria
                                                ?.purchase_planned_date,
                                            from: valueFrom,
                                            to: valueTo,
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaPurchasePlannedDateSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        purchase_planned_date: {
                                            ...filter.filter_settings.criteria
                                                ?.purchase_planned_date,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Срок исполнения"
                        onChangeFilterSettingCriteriaContractEndDate={(
                            valueFrom,
                            valueTo
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        contract_end_date: {
                                            ...filter.filter_settings.criteria
                                                ?.contract_end_date,
                                            from: valueFrom,
                                            to: valueTo,
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaContractEndDateSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        contract_end_date: {
                                            ...filter.filter_settings.criteria
                                                ?.contract_end_date,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Способ закупки "
                        onChangeFilterSettingCriteriaPurchaseMethodCode={(
                            values
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        purchase_method_code: {
                                            ...filter.filter_settings.criteria
                                                ?.purchase_method_code,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaPurchaseMethodCodeSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        purchase_method_code: {
                                            ...filter.filter_settings.criteria
                                                ?.purchase_method_code,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Статус в плане"
                        onChangeFilterSettingCriteriaStatus={(values) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        status: {
                                            ...filter.filter_settings.criteria
                                                ?.status,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaStatusSort={(value) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        status: {
                                            ...filter.filter_settings.criteria
                                                ?.status,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Тип объекта закупки"
                        onChangeFilterSettingCriteriaTypeObjectPurchasePosition={(
                            values
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        type_object_purchase_position: {
                                            ...filter.filter_settings.criteria
                                                ?.type_object_purchase_position,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaTypeObjectPurchasePositionSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        type_object_purchase_position: {
                                            ...filter.filter_settings.criteria
                                                ?.type_object_purchase_position,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Подразделение"
                        onChangeFilterSettingCriteriaStructGuid={(values) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        struct_guid: {
                                            ...filter.filter_settings.criteria
                                                ?.struct_guid,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaStructGuidSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        struct_guid: {
                                            ...filter.filter_settings.criteria
                                                ?.struct_guid,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Дата создания"
                        onChangeFilterSettingCriteriaCreatedDate={(
                            valueFrom,
                            valueTo
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        sys_updated_at: {
                                            ...filter.filter_settings.criteria
                                                ?.sys_updated_at,
                                            from: valueFrom,
                                            to: valueTo,
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaCreatedDateSort={(
                            value
                        ) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        sys_updated_at: {
                                            ...filter.filter_settings.criteria
                                                ?.sys_updated_at,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        // Фильтрация для "Подразделение"
                        onChangeFilterSettingCriteriaUserGuid={(values) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        user_guid: {
                                            ...filter.filter_settings.criteria
                                                ?.user_guid,
                                            values:
                                                values === null ? null : values, // если values равно null, передаем undefined
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                        onChangeFilterSettingCriteriaUserGuidSort={(value) => {
                            if (!filter) return // Если фильтра ещё нет, ничего не делаем
                            const newFilter: UserFilter = {
                                ...filter,
                                filter_settings: {
                                    ...filter.filter_settings,
                                    criteria: {
                                        ...filter.filter_settings.criteria,
                                        user_guid: {
                                            ...filter.filter_settings.criteria
                                                ?.user_guid,
                                            sort_order: value, // Обновляем только сортировку
                                        },
                                    },
                                },
                            }
                            setFilter(newFilter)
                        }}
                    />

                    {/* data */}
                    <div
                        className="flex flex-col overflow-y-auto gap-1 my-1 h-dvh"
                        ref={scrollRef}
                        onScroll={(e) => {
                            const { scrollTop, scrollHeight, clientHeight } =
                                e.currentTarget

                            if (
                                page !== -1 && // ещё есть данные
                                !isLoading &&
                                scrollHeight - scrollTop - clientHeight < 100
                            ) {
                                if (filter?.filter_settings?.filter_enabled) {
                                    loadPlanPositionsOnApproval(page, filter)
                                } else {
                                    loadPlanPositionsOnApproval(page)
                                }
                            }
                        }}
                    >
                        {/* Вывод позиций */}
                        <>
                            {isLoading ? (
                                <div className="flex w-full h-full items-center justify-center">
                                    <LoadingIcon />
                                </div>
                            ) : !items.length ? (
                                <div className="flex w-full h-12 items-center justify-center text-[#8A8A8A] text-[11px]">
                                    Нет данных
                                </div>
                            ) : (
                                items.map((item, index) => (
                                    <ApprovalsTableRow
                                        key={index}
                                        keyindex={index + 1}
                                        planPosition={item}
                                        structData={structureList}
                                        onDoubleClick={(pos_guid) => {
                                            navigate(
                                                `/hub/plans/${item.plan_guid}/positions/approve/${pos_guid}`
                                            )
                                        }}
                                    />
                                ))
                            )}
                        </>
                    </div>

                    {/* buttons */}
                    <div className="flex flex-row gap-3 text-white p-2 px-[22px] bg-[#333033] rounded-b h-10"></div>
                </div>
            </div>
        </div>
    )
}

export default ApprovalsPage
